/*@ngInject*/
function acpInterstitialDomainModelDecorator($delegate, acpCoreDispatcher) {
  var onInterstitialLoaded = $delegate.onInterstitialLoaded;

  var account = null;

  acpCoreDispatcher.profile.simpleAccount.updated.onValue(function (
    simpleAccount
  ) {
    account = simpleAccount;
  });

  $delegate.onInterstitialLoaded = function (interstitial) {
    if (!interstitial) {
      return;
    }

    if (!interstitial.data || interstitial.data.type !== 'dialog') {
      onInterstitialLoaded.call($delegate, interstitial);
      return;
    }

    interstitial.data.data = { account: account };
    interstitial.data.id = interstitial.id;
    interstitial.data.suppressible = interstitial.suppressible;
    $delegate.showInterstitial.emit(interstitial.data);
  };

  return $delegate;
}

export default acpInterstitialDomainModelDecorator;
