//@ngInject
function acpConfigurationService(
  $q,
  nsConfig,
  acpDeviceService,
  acpPreOnboardingConfigurationService,
  acpPreviousLoginModel,
  acpFrontendConfigModel,
  acpThemeImages
) {
  this.getGoogleMapsApiKey = function () {
    var rawKey = acpFrontendConfigModel.get(
      acpFrontendConfigModel.keys.GOOGLE_MAP_API_KEY
    );
    return rawKey ? rawKey : '';
  };

  this.getGoogleMapsVersion = function () {
    return nsConfig.get('googleMapsVersion');
  };

  // Used for Unauthed Atm/Location Finder
  this.getDistributor = function () {
    return nsConfig.get('distributor');
  };

  this.getLocationSearchMax = function () {
    return nsConfig.get('locationSearchMax');
  };

  this.getStoreNameOverride = function () {
    return nsConfig.get('storeNameOverride');
  };

  this.getDisplayName = function () {
    return nsConfig.get('showAccountDisplayNickname');
  };

  this.isNameOnSubAccountSwitcher = function () {
    return nsConfig.get('showDisplayNameForSubAccount');
  };

  // Used for Unauthed Atm Finder
  this.getAtmNetwork = function () {
    return nsConfig.get('atmNetwork');
  };

  this.shouldShowPreOnboardingTour = function () {
    // Only show preonboarding if in App and the user has not logged in
    //  AND preonboarding is enabled via tokens.js (and possibly an associated CARE rule)
    if (
      !acpDeviceService.isCordova() ||
      acpPreviousLoginModel.hasPreviouslyLoggedIn()
    ) {
      return $q.resolve(false);
    }
    return acpPreOnboardingConfigurationService.isPreOnboardingEnabled();
  };

  this.isUnAuthedNetspendFeeFreeAtmsEnabled = function () {
    return ['transact711', 'heb-debit'].indexOf(this.getBrand()) > -1;
  };

  this.shouldDisplayInfoIcon = function () {
    return ['heb-debit'].indexOf(this.getBrand()) > -1;
  };

  this.getBrand = function () {
    return nsConfig.get('brand');
  };

  this.showLocationsLink = function () {
    return (
      this.getBrand() !== 'skylight' &&
      this.getBrand() !== 'skylight-all-access' &&
      this.getBrand() !== 'paypal' &&
      this.getBrand() !== 'netspendExpense' &&
      this.getBrand() !== 'albertsons_gpr_republic'
    );
  };

  this.showMarketingSiteLink = function () {
    return nsConfig.get('showMarketingSiteLink') === true;
  };

  this.showAcquisitionLink = function () {
    return nsConfig.get('showAcquisitionLink') === true;
  };

  this.showRegisterLink = function () {
    return nsConfig.get('showRegisterLink') === true;
  };

  this.getPlatformType = function () {
    return nsConfig.get('platformType');
  };

  this.getEnvironment = function () {
    return nsConfig.get('environment');
  };

  this.getProgramType = function () {
    return nsConfig.get('programType');
  };

  this.getVersion = function () {
    return nsConfig.get('version');
  };

  this.getTheme = function () {
    return nsConfig.get('theme');
  };

  this.getThemePath = function () {
    throw new Error(
      'Deprecated call to acpConfigurationService.getThemePath(). Please use a direct .imagePathForComponent() or .imagePathForSection() or .imagePathForImage() instead'
    );
  };

  this.getImagesPath = function () {
    throw new Error(
      'Deprecated call to acpConfigurationService.getImagesPath(). Please use a direct .imagePathForComponent() or .imagePathForSection() or .imagePathForImage() instead'
    );
  };

  this.imagePathForComponent = function (component, image) {
    var key = 'components/' + component + '/' + image;
    return this.imagePathForImage(key);
  };

  this.imagePathForSection = function (section, image) {
    var key = 'sections/' + section + '/' + image;
    return this.imagePathForImage(key);
  };

  this.imagePathForImage = function (image) {
    return acpThemeImages.getImageUrl(image);
  };

  this.setOneTimeUseToken = function (token) {
    nsConfig.set('oneTimeUseToken', token);
  };

  this.getOneTimeUseToken = function () {
    return nsConfig.get('oneTimeUseToken');
  };

  this.isEmbeddedPayNearMeEnabled = function () {
    return !!nsConfig.get('embeddedPayNearMe');
  };

  this.getPayNearMeConfig = function () {
    return nsConfig.get('payNearMeConfig');
  };

  this.getAppsFlyerDevKey = function () {
    return nsConfig.get('appsflyerDevKey');
  };

  this.getIOSAppStoreId = function () {
    return nsConfig.get('iOSAppId');
  };

  this.isAppsFlyerEnabled = function () {
    return nsConfig.get('appsflyerEnabled') === true;
  };

  this.getConfigurationSource = function () {
    return nsConfig;
  };

  this.getActivationShowScanCardRegistration = function () {
    return nsConfig.get('activationShowScanCardRegistration');
  };

  this.getMarketingSiteLinkText = function () {
    return nsConfig.get('marketingSiteLinkText');
  };

  this.getMarketingSiteUrl = function () {
    return nsConfig.get('marketingSiteUrl');
  };

  this.getAcquistionInAppBrowserToolbarColor = function () {
    return nsConfig.get('inAppBrowserAcquisitionToolbarBackgroundColor');
  };

  this.getAcquistionInAppBrowserToolbarTextDisabled = function () {
    return nsConfig.get('inAppBrowserAcquisitionDisableToolbarText');
  };

  this.getAcquistionInAppBrowserToolbarTranslucentDisabled = function () {
    return nsConfig.get('inAppBrowserAcquisitionDisableToolbarTranslucent');
  };
  // Used for BenefitCenter Benefits
  this.getBenefits = function () {
    return nsConfig.get('benefits');
  };

  this.getAdaChatbotHandle = function () {
    return nsConfig.get('adaChatbotHandle');
  };
}

export default acpConfigurationService;
