import { AcpReloadPageService } from 'core';
import { AcpApiBrandingDomainClient } from './acp-api-branding-domain-client';

export class AcpApiBrandingDomainModel {
  apiBranding: nsUtils.NsProperty<any>;

  private $log: ng.ILogService;
  private acpApiBrandingDomainClient: AcpApiBrandingDomainClient;
  private nsProperty: nsUtils.NsPropertyService;

  constructor(
    $log: ng.ILogService,
    acpApiBrandingDomainClient: AcpApiBrandingDomainClient,
    nsProperty: nsUtils.NsPropertyService,
    private acpReloadPageService: AcpReloadPageService
  ) {
    'ngInject';
    this.$log = $log;
    this.acpApiBrandingDomainClient = acpApiBrandingDomainClient;
    this.nsProperty = nsProperty;
    this.apiBranding = this.nsProperty.create();
    this.updateBranding();
  }

  updateBranding(forceRefresh = false): void {
    if (!this.apiBranding.getValue() || forceRefresh) {
      this.acpApiBrandingDomainClient
        .getApiBranding()
        .then(this.onBrandingSuccess, this.onBrandingError);
    }
  }

  private onBrandingSuccess = (data): void => {
    this.acpReloadPageService.reloadPageIfVariantMismatch(
      data?.brand.variant_id
    );
    this.apiBranding.set(data.brand);
  };

  private onBrandingError = (): void => {
    this.$log.error('Error getting branding data from WebAPI');
  };
}
