import { isDynatrace } from './dynatrace-api';
import { isHeapAnalytics } from './heap-analytics-api';

import { ProfileResponse } from '../profile-domain';

export class AcpThirdPartyIdentity {
  constructor(private $window: ng.IWindowService) {
    'ngInject';
  }

  public setUser(profileResponse: ProfileResponse): void {
    if (isHeapAnalytics(this.$window.heap) && profileResponse.user) {
      this.$window.heap.identify(profileResponse.user.referral_code);
    }

    if (
      isDynatrace(this.$window.dtrum) &&
      profileResponse.user &&
      profileResponse.user.cash_number
    ) {
      this.$window.dtrum.identifyUser(profileResponse.user.cash_number);
    }
  }

  public clearUser(): void {
    if (isHeapAnalytics(this.$window.heap)) {
      this.$window.heap.resetIdentity();
    }

    if (isDynatrace(this.$window.dtrum)) {
      this.$window.dtrum.endSession();
    }
  }
}
