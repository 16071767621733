import { AcpTermsClient, TermsResponse, TermsTypeRequest } from './index';

export class AcpTermsModel {
  constructor(
    private acpCoreDispatcher: any,
    private acpTermsClient: AcpTermsClient,
    private nsConfig: any
  ) {
    'ngInject';
  }

  public async getTermsUrl(): Promise<string> {
    const { terms_url } = await this.acpTermsClient.getTermsUrl();
    return this.removeHostInDevelopment(terms_url);
  }

  public async getPewUrl(): Promise<string> {
    const { pew_url } = await this.acpTermsClient.getTermsUrl();
    return this.removeHostInDevelopment(pew_url);
  }

  public async getCardBrandTermsUrl(cardBrand: string): Promise<string> {
    const { terms_url } = await this.acpTermsClient.getCardBrandTermsUrl({
      card_brand: cardBrand
    });
    return this.removeHostInDevelopment(terms_url);
  }

  public async getPrivacyPolicyUrl(): Promise<string> {
    const { terms_url } = await this.acpTermsClient.getPrivacyPolicyUrl();
    return this.removeHostInDevelopment(terms_url);
  }

  public async getPrefundedChecksEsignUrl(): Promise<string> {
    const {
      terms_url
    } = await this.acpTermsClient.getPrefundedChecksEsignUrl();
    return this.removeHostInDevelopment(terms_url);
  }

  public async getPrefundedChecksTermsUrl(): Promise<string> {
    const {
      terms_url
    } = await this.acpTermsClient.getPrefundedChecksTermsUrl();
    return this.removeHostInDevelopment(terms_url);
  }

  public async getAnytimeAlertsTermsUrl(): Promise<string> {
    const { terms_url } = await this.acpTermsClient.getAnytimeAlertsTermsUrl();
    return this.removeHostInDevelopment(terms_url);
  }

  public async getAnytimeAlertsTermsUrlV2(): Promise<string> {
    const { terms_url } = await this.acpTermsClient.getAnytimeAlertsTermsUrlV2();
    return this.removeHostInDevelopment(terms_url);
  }

  public async getPrefundedChecksPrivacyPolicyUrl(): Promise<string> {
    const {
      terms_url
    } = await this.acpTermsClient.getPrefundedChecksPrivacyPolicyUrl();
    return this.removeHostInDevelopment(terms_url);
  }

  public async getBenefitCenterTermsUrl(): Promise<string> {
    const { terms_url } = await this.acpTermsClient.getBenefitCenterTermsUrl();
    return this.removeHostInDevelopment(terms_url);
  }

  public async getSavingsTermsUrl(): Promise<string> {
    const { terms_url } = await this.acpTermsClient.getSavingsTermsUrl();
    return this.removeHostInDevelopment(terms_url);
  }

  public async getUpgradeTermsUrl(
    request: TermsTypeRequest
  ): Promise<TermsResponse> {
    try {
      const termsResponse: TermsResponse = await this.acpTermsClient.getUpgradeTermsUrl(
        request
      );
      if (termsResponse.terms_url) {
        termsResponse.terms_url = this.removeHostInDevelopment(
          termsResponse.terms_url
        );
      }
      if (termsResponse.pew_url) {
        termsResponse.pew_url = this.removeHostInDevelopment(
          termsResponse.pew_url
        );
      }
      return termsResponse;
    } catch (err) {
      throw err;
    }
  }

  public async getEsign(): Promise<TermsResponse> {
    const terms: TermsResponse = await this.acpTermsClient.getEsign();
    if (terms.terms_url) {
      terms.terms_url = this.removeHostInDevelopment(terms.terms_url);
    }
    return terms;
  }

  public getDebitCardTransfer(): Promise<TermsResponse> {
    return this.acpTermsClient.getDebitCardTransfer();
  }

  public getChoicepayDisbursement(): Promise<TermsResponse> {
    return this.acpTermsClient.getChoicepayDisbursement();
  }

  public getBankTransfer(): Promise<TermsResponse> {
    return this.acpTermsClient.getBankTransfer();
  }

  public async getLoyaltyPoints(): Promise<string> {
    const { terms_url } = await this.acpTermsClient.getLoyaltyPoints();
    return this.removeHostInDevelopment(terms_url);
  }

  public async getBiometricsEnrollmentTermsUrl(): Promise<string> {
    const {
      terms_url
    } = await this.acpTermsClient.getBiometricsEnrollmentTermsUrl();
    return this.removeHostInDevelopment(terms_url);
  }

  public async getAutoDirectDepositTermsUrl(): Promise<TermsResponse> {
    const terms: TermsResponse = await this.acpTermsClient.getAutoDirectDepositTermsUrl();
    terms.terms_url = this.removeHostInDevelopment(terms.terms_url);
    return terms;
  }

  public async getAutoDirectDepositPrivacyPolicy(): Promise<TermsResponse> {
    const terms: TermsResponse = await this.acpTermsClient.getAutoDirectDepositPrivacyPolicy();
    terms.terms_url = this.removeHostInDevelopment(terms.terms_url);
    return terms;
  }

  public async acceptTerms(type: string): Promise<TermsResponse> {
    const acceptRequest: TermsTypeRequest = { type };

    try {
      const response: TermsResponse = await this.acpTermsClient.acceptTerms(
        acceptRequest
      );
      this.acpCoreDispatcher.terms.accepted.success.emit({
        type,
        response
      });
      return response;
    } catch (err) {
      this.acpCoreDispatcher.terms.accepted.error.emit({
        type,
        response: err
      });
      throw err;
    } finally {
      this.acpCoreDispatcher.terms.updated.emit({
        type
      });
    }
  }

  public async declineTerms(type: string): Promise<TermsResponse> {
    const declineRequest: TermsTypeRequest = { type };
    try {
      const response: TermsResponse = await this.acpTermsClient.declineTerms(
        declineRequest
      );
      this.acpCoreDispatcher.terms.declined.success.emit({
        type,
        response
      });
      return response;
    } catch (err) {
      this.acpCoreDispatcher.terms.declined.error.emit({
        type,
        response: err
      });
      throw err;
    } finally {
      this.acpCoreDispatcher.terms.updated.emit({
        type
      });
    }
  }

  public async getFeeScheduleTermsUrl(): Promise<string> {
    const { terms_url } = await this.acpTermsClient.getFeeScheduleTermsUrl();
    return this.removeHostInDevelopment(terms_url);
  }

  // If images are moved from webnew without updates to serve.gulp.js,
  // this may break images displaying locally (test/prod envs should be fine).
  private removeHostInDevelopment(url: string): string {
    if (this.nsConfig.get('mode') !== 'development') {
      return url;
    }
    if (url) {
      return this.removeHostFromPath(url);
    }
    return url;
  }

  private removeHostFromPath(url: string): string {
    // We use the anchor URL parsing ability instead of URL because of lack of IE support
    const anchor: HTMLAnchorElement = document.createElement('a');
    anchor.href = url;
    return anchor.pathname;
  }

  public async getEulaTermsUrl(): Promise<string> {
    const { terms_url } = await this.acpTermsClient.getEulaTermsUrl();
    return this.removeHostInDevelopment(terms_url);
  }
}
