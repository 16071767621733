import ng from 'angular';
export default function() {
  var getImageUrl = function(imageKey) {
    if (!ng.isString(imageKey)) {
      throw new Error('image key must be a string');
    }
    imageKey = imageKey.toLowerCase();
    return [
      imageKey,
      ...imageKey
        .split('/')
        .map((_, index, array) =>
          array.slice(0, index).concat('images', array.slice(index)).join('/')
        )
    ].reduce((resolved, imageKey) => {
      try {
        return resolved || System.resolve(imageKey);
      } catch (e) {
        return;
      }
    }, undefined);
  };

  /* used for testing purposes only */
  this.setThemeImageAliases = function(newThemeImageAliases) {
    var images = newThemeImageAliases.netspend;
    getImageUrl = function(imageKey) {
      return images[imageKey];
    }
  };

  this.$get = function() {
    return { getImageUrl: getImageUrl };
  };
}
