// Can't inject nsConfig into our delegate because
// that causes a circual dependency so we just use the provider
function acpLogDelegateFactory(nsConfigProvider) {
  //@ngInject
  function acpLogDelegate($delegate) {
    function instanceFactory($log) {
      function wrap(logFn, className) {
        return function() {
          var args = Array.prototype.slice.call(arguments);
          args[0] = className + args[0];
          logFn.apply(null, args);
        };
      }

      return function getInstance(className) {
        className = className !== undefined ? className + '::' : '';

        return {
          log: wrap($log.log, className),
          info: wrap($log.info, className),
          warn: wrap($log.warn, className),
          debug: wrap($log.debug, className),
          error: wrap($log.error, className)
        };
      };
    }

    function now() {
      var now = new Date();
      return now.toLocaleDateString() + ':' + now.toLocaleTimeString();
    }

    function decorateLogFn(logFn) {
      var isDevelopment = nsConfigProvider.get('mode') === 'development';

      var enhancedLogFn = function() {
        var args = Array.prototype.slice.call(arguments);
        args.unshift(now());
        // Write to the original log output if not production
        if (isDevelopment) {
          logFn.apply(null, args);
        }
      };

      // Special... only needed to support angular-mocks expectations
      enhancedLogFn.logs = [];

      return enhancedLogFn;
    }

    $delegate.log = decorateLogFn($delegate.log, 'TRACE');
    $delegate.info = decorateLogFn($delegate.info, 'INFO');
    $delegate.warn = decorateLogFn($delegate.warn, 'WARN');
    $delegate.debug = decorateLogFn($delegate.debug, 'DEBUG');
    $delegate.error = decorateLogFn($delegate.error, 'ERROR');
    $delegate.getInstance = instanceFactory($delegate);
    return $delegate;
  }

  return acpLogDelegate;
}
//@ngInject
function acpLogDecorator($provide, nsConfigProvider) {
  $provide.decorator('$log', acpLogDelegateFactory(nsConfigProvider));
}

export default acpLogDecorator;
