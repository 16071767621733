import { acpDisplayBrandingPropertiesComponent } from './acp-display-branding-properties';

const acpIssuerStatementComponent: ng.IComponentOptions = {
  bindings: {},
  require: {},
  template:
    '<acp-display-branding-properties property="issuer_statement_html"></acp-display-branding-properties>'
};
export const acpProductNameComponent: ng.IComponentOptions = {
  bindings: {},
  require: {},
  template:
    '<acp-display-branding-properties property="product_name_html" fallback-property="product_name""></acp-display-branding-properties>'
};

export const acpBrandNameComponent: ng.IComponentOptions = {
  bindings: {},
  require: {},
  template:
    '<acp-display-branding-properties property="brand_name_html" fallback-property="name"></acp-display-branding-properties>'
};

export default function (core) {
  core.component('acpIssuerStatement', acpIssuerStatementComponent);
  core.component(
    'acpDisplayBrandingProperties',
    acpDisplayBrandingPropertiesComponent
  );
  core.component('acpProductName', acpProductNameComponent);
  core.component('acpBrandName', acpBrandNameComponent);
}
