interface AnalyticsExternalIdResponse {
  external_id: string | number;
}

export class AcpAnalyticsApiDomainClient {
  public getExternalId = this.webapiResource<void, AnalyticsExternalIdResponse>(
    {
      method: 'GET',
      path: '/v2/analytics/id',
      format: 'json'
    }
  );

  constructor(private webapiResource: nsWebclient.WebapiResourceService) {
    'ngInject';
  }
}
