// @ngInject
function acpAccountsModel(
  nsUtil,
  acpCoreDispatcher,
  acpProfileClient,
  acpRestrictionsClient
) {
  var model = {},
    accounts;

  /**
   * I provide methods for interacting with web-api's /me service.
   * Since I only care about the accounts, I transform the response.
   */

  model.get = function() {
    acpProfileClient.accounts().then(function(data) {
      var transformedAccounts = data.map(function(account) {
        account._available_balance = nsUtil.toDollars(
          account.available_balance
        );
        account._posted_balance = nsUtil.toDollars(account.posted_balance);
        return account;
      });

      accounts = transformedAccounts;
      acpCoreDispatcher.profile.accounts.updated.emit(accounts);
    });
  };

  model.getAccounts = function() {
    return accounts;
  };

  model.getDebitAccount = function() {
    if (!nsUtil.isDefined(accounts) || accounts.length <= 0) {
      return;
    }

    return accounts[0];
  };

  model.getRestrictions = function() {
    return acpRestrictionsClient.getRestrictionsList();
  };

  return model;
}

export default acpAccountsModel;
