import ng from 'angular';

//@ngInject
function acpDeviceOrientationService(
  $rootScope,
  $window,
  nsSignal,
  nsUtil,
  acpDeviceService
) {
  var signal = nsSignal.create();

  var ORIENTATION = {
    LANDSCAPE: 'landscape',
    PORTRAIT: 'portrait'
  };

  var currentOrientation = null;

  function DeviceOrientation(orientation) {
    this.type = orientation;

    this.isLandscape = function() {
      return orientation === ORIENTATION.LANDSCAPE;
    };

    this.isPortrait = function() {
      return orientation === ORIENTATION.PORTRAIT;
    };
  }

  function getScreenOrientation() {
    if (supportsOrientation()) {
      if ($window.screen && $window.screen.orientation) {
        var orientation = $window.screen.orientation;
        var type = nsUtil.isString(orientation)
          ? orientation
          : orientation.type;
        return type.indexOf(ORIENTATION.LANDSCAPE) >= 0
          ? ORIENTATION.LANDSCAPE
          : ORIENTATION.PORTRAIT;
      }

      if ($window.orientation) {
        return Math.abs($window.orientation) === 90
          ? ORIENTATION.LANDSCAPE
          : ORIENTATION.PORTRAIT;
      }
    }

    return $window.innerWidth > $window.innerHeight
      ? ORIENTATION.LANDSCAPE
      : ORIENTATION.PORTRAIT;
  }

  function screenOrientationWatcher() {
    var orientation = new DeviceOrientation(getScreenOrientation());

    if (currentOrientation && currentOrientation.type === orientation.type) {
      // Orientation is the same...don't do anything...
      return true;
    }

    currentOrientation = orientation;

    $rootScope.acpScreenOrientation = orientation;
    signal.emit(orientation);
    return true;
  }

  function supportsOrientation() {
    return (
      (($window.screen && $window.screen.orientation) || $window.orientation) &&
      !acpDeviceService.isAndroid()
    );
  }

  function bindToOrientationEvent() {
    var window = ng.element($window);
    var event = supportsOrientation() ? 'orientationchange' : 'resize';
    var throttle = nsUtil.debounce(screenOrientationWatcher, 500, $rootScope);
    window.on(event, throttle);

    return function() {
      window.off(event, throttle);
    };
  }

  return {
    ORIENTATION: ORIENTATION,

    get: function() {
      return $rootScope.acpScreenOrientation;
    },

    subscribe: function(subscriber, $scope) {
      var disposable = signal.onValue(subscriber);

      if ($scope) {
        $scope.$on('$destroy', function() {
          disposable.dispose();
        });
      }

      // Fire immediately...
      subscriber(new DeviceOrientation(getScreenOrientation()));
      return disposable;
    },

    listen: function() {
      var off = bindToOrientationEvent();
      $rootScope.$on('$destroy', off);

      // Fire it initially...
      screenOrientationWatcher();
    }
  };
}

export default acpDeviceOrientationService;
