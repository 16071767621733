// @ngInject
function acpStopTouchEventDirective() {
  return {
    restrict: 'A',
    link: function(scope, element) {
      element.on('touchmove', function(evt) {
        evt.stopPropagation();
      });
    }
  };
}

export default acpStopTouchEventDirective;
