//@ngInject
function acpInterstitialDomainModel(
  nsSignal,
  nsProperty,
  acpWidgetService,
  acpSimpleAccountModel,
  acpInterstitialDomainClient,
  acpCoreDispatcher
) {
  var model = {};

  model.interstitial = nsProperty.create(null);
  model.hasMutex = nsProperty.create(false);
  model.showInterstitial = nsSignal.create();
  model.interstitialLoadError = nsProperty.create(false);
  model.isRtInterstitial = nsProperty.create(false);

  model.onInterstitialLoaded = function (interstitial) {
    if (!interstitial) {
      return;
    }

    if (
      interstitial.data &&
      (interstitial.data.type === 'generic' ||
        interstitial.data.type === 'action' ||
        interstitial.data.type === 'modal')
    ) {
      // New API Driven Interstitial
      model.interstitial.set(interstitial);
    } else if (
      interstitial.data &&
      interstitial.data.type.startsWith('Interstitial:')
    ) {
      // React Interstitial that should be displayed in React Dashboard
      model.isRtInterstitial.set(true);
    } else {
      // Old awful monsters
      var scrubWidgets = acpWidgetService.scrubWidgets([interstitial]);

      const result = scrubWidgets.some(function (widget) {
        if (widget.configData && widget.configData.type === 'interstitial') {
          model.interstitial.set(widget);
          return true;
        }
      });

      if (!result || !scrubWidgets.length) {
        model.interstitialLoadError.set(true);
      }
    }
  };

  model.load = function () {
    acpSimpleAccountModel.get().then(function () {
      acpInterstitialDomainClient
        .get()
        .then(model.onInterstitialLoaded, interstitialLoadFailed);
    });
  };

  function interstitialLoadFailed() {
    model.interstitialLoadError.set(true);
  }

  model.respond = function (widget, response) {
    return acpWidgetService.respond(
      acpInterstitialDomainClient,
      widget,
      response
    );
  };

  model.suppress = function (widget) {
    return acpWidgetService.suppress(acpInterstitialDomainClient, widget);
  };

  model.view = function (widget, trigger) {
    return acpWidgetService.view(acpInterstitialDomainClient, widget, trigger);
  };

  model.listen = function () {
    acpCoreDispatcher.login.loggedIn.onValue(function () {
      model.hasMutex.set(true);
    });
  };

  model.isSuppressible = function () {
    var interstitial = model.interstitial.getValue();
    return interstitial !== null && interstitial.suppressible === true;
  };

  return model;
}

export default acpInterstitialDomainModel;
