import ng from 'angular';

const SUBACCOUNT_ENABLED_PERMISSION = 'subaccountEnabledAccount';

export class AcpContentFilter {
  private permissionListener: nsUtils.Disposable;
  private clearedListener: nsUtils.Disposable;
  private lastPermissionValue?: boolean;

  constructor(
    private nsPermissions: nsUtils.NsPermissionsService,
    private acpCoreDispatcher: any,
    private content: any
  ) {
    'ngInject';
  }

  public listen() {
    this.listenForSubaccountEnabled();
    this.listenForProgramType();
    // TODO migrate all other calls to `content.set<CMSFilterName>()` to this service
  }

  public unlisten() {
    this.permissionListener.dispose();
    this.clearedListener.dispose();
  }

  private listenForSubaccountEnabled() {
    this.permissionListener = this.nsPermissions.permissions.onValue(
      (permissions) => {
        if (
          permissions &&
          ng.isDefined(permissions[SUBACCOUNT_ENABLED_PERMISSION])
        ) {
          const newPermission = permissions[SUBACCOUNT_ENABLED_PERMISSION];
          if (newPermission !== this.lastPermissionValue) {
            this.lastPermissionValue = newPermission;
            this.content.setSubaccountEnabled(newPermission);
          }
        }
      }
    );

    this.clearedListener = this.nsPermissions.cleared.onValue(() => {
      this.lastPermissionValue = undefined;
      this.nsPermissions.requestPermission(SUBACCOUNT_ENABLED_PERMISSION);
    });

    this.nsPermissions.requestPermission(SUBACCOUNT_ENABLED_PERMISSION);
  }

  private listenForProgramType() {
    let lastProgramType: string = '';
    this.acpCoreDispatcher.account.programType.onValue(
      (programType: string) => {
        if (programType !== lastProgramType) {
          lastProgramType = programType;
          this.content.setProgramType(programType);
        }
      }
    );
  }
}
