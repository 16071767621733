import ng from 'angular';

// @ngInject
function acpStepStateManager($state, $q, $injector) {
  function StepStateManager(steps, context) {
    this._steps = steps;
    this._states = steps.map(function(step) {
      return $state.get(step.state, context);
    });
  }

  StepStateManager.prototype = {
    // Get a step, optionally +/- n offset. Useful for going forward (+1) or back (-1)
    getStepState: function(offset) {
      return this._states[this.getCurrentStepIndex() + (offset || 0)] || null;
    },

    // Get the current index of the current step, based on $state.current
    getCurrentStepIndex: function() {
      for (var i = 0; i < this._states.length; i++) {
        /* eslint-disable-next-line es5/no-es6-methods */
        if ($state.includes(this._states[i])) {
          return i;
        }
      }
      return -1;
    },

    getAllSteps: function() {
      return this._steps;
    },

    // methods to add steps at start or end of steps array
    addStepAtBeginning: function(newState) {
      this._steps.unshift(newState);
    },

    removeStepAtBeginning: function() {
      this._steps.shift();
    },

    addStepAtEnd: function(newState) {
      this._steps.push(newState);
    },

    removeStepAtEnd: function() {
      this._steps.pop();
    }
  };

  // For a given step, if it has a `when` function, it invokes it.
  //   If it resolves truthy, returns the step name otherwise null
  //   If it rejects it returns null
  StepStateManager.resolveStep = function(locals, step) {
    return $q
      .when(step.when ? $injector.invoke(step.when, step, locals) : true)
      .then(function(isValidStep) {
        return isValidStep ? step : null;
      });
  };

  // Takes an array of steps and invokes their 'when' function to determine if it is a valid step or not
  StepStateManager.resolveSteps = function(steps, locals) {
    return $q
      .all(steps.map(StepStateManager.resolveStep.bind(null, locals)))
      .then(function(steps) {
        // Filter out falsey values
        return steps.filter(ng.identity);
      });
  };

  /**
   * @param {Array<Step>} steps Array of steps that define the possible steps for this Manager
   * @param {String} context State name of context for step states
   * @param {Object} [locals] The locals to use when injecting the step's `when` methd
   *
   * @return {Promise} Resolves with the initialized StepStateManager instances
   */
  return function(steps, context, locals) {
    return StepStateManager.resolveSteps(steps, locals).then(function(
      filteredStates
    ) {
      return new StepStateManager(filteredStates, context);
    });
  };
}

export default acpStepStateManager;
