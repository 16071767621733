// @ngInject
function acpFrontendConfigClient(webapiResource) {
  var client = {};

  client.getConfiguration = webapiResource({
    method: 'GET',
    path: '/v2/configuration'
  });

  return client;
}

export default acpFrontendConfigClient;
