import ng from 'angular';

// @ngInject
function acpRouterRedirect($rootScope, $injector, $state, $log, $q) {
  $rootScope.$on('$stateChangeStart', function (
    event,
    toState,
    toParams,
    fromState,
    fromParams,
    options
  ) {
    /** to handle back button case when navigating from MFE/external to Angular */
    var isNotFromNgACP =
      $rootScope.prevStateReact && (fromState ? fromState.url === '^' : false);

    $rootScope.prevStateReact = fromState ? fromState.url === '^' : false;
    var redirectTo = toState.redirectTo;
    if (ng.isFunction(redirectTo) || Array.isArray(redirectTo)) {
      redirectTo = $injector.invoke(redirectTo, null, {
        event: event,
        toState: toState,
        toParams: toParams,
        fromState: fromState,
        fromParams: fromParams
      });
    }

    if (redirectTo) {
      event.preventDefault();
      var params = {
        relative: toState.name
      };
      if (isNotFromNgACP) {
        params.location = 'replace';
      }
      $q.when(redirectTo).then(function (redirectTo) {
        $state.go(
          redirectTo.state || redirectTo,
          redirectTo.params || toParams,
          ng.extend({}, options, params)
        );
      });
    } else if (isNotFromNgACP) {
      //pass hash value to params so the hash wont be removed
      if (window.location.hash) {
        toParams['#'] = window.location.hash.substring(1);
      }
      options.location = 'replace';
    }
  });

  $rootScope.$on('$stateChangeError', function (
    event,
    toState,
    toParams,
    fromState,
    fromParams,
    error
  ) {
    $log.error('stateChangeError: ' + toState.name + '.', error);
  });
}

export default acpRouterRedirect;
