export default [
  {
    name: 'Arts & Entertainment',
    value: 1
  },
  {
    name: 'Health & Beauty',
    value: 2
  },
  {
    name: 'Business & Consulting',
    value: 3
  },
  {
    name: 'Construction & Trade',
    value: 4
  },
  {
    name: 'Food & Beverage',
    value: 5
  },
  {
    name: 'Retail',
    value: 6
  },
  {
    name: 'Transportation & Logistics',
    value: 7
  },
  {
    name: 'Other',
    value: 8
  }
];
