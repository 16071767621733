import { AcpApiBrandingDomainModel } from '../api-branding-domain';
import { addSuperscripts } from 'apps/acp/packages/issuer-statement';

export class AcpDisplayBrandingPropertiesComponentController
  implements nsUtils.NsComponentController {
  private brandingProperty: string;
  property: string;
  fallbackProperty: string;
  addSuperscripts = addSuperscripts;

  constructor(
    nsComponentDecorator,
    private acpApiBrandingDomainModel: AcpApiBrandingDomainModel
  ) {
    'ngInject';
    nsComponentDecorator(this, this);
  }

  async $onInit(): Promise<void> {
    this.acpApiBrandingDomainModel.apiBranding.onValue((brandInfo) => {
      return (this.brandingProperty = brandInfo[this.property]
        ? brandInfo[this.property]
        : brandInfo[this.fallbackProperty]);
    });
  }

  getBrandingProperty(): string {
    return this.brandingProperty;
  }
}

export const acpDisplayBrandingPropertiesComponent: ng.IComponentOptions = {
  bindings: {
    property: '@',
    fallbackProperty: '@'
  },
  controller: AcpDisplayBrandingPropertiesComponentController,
  controllerAs: 'vm',
  require: {},
  template:
    '<span ng-bind-html="vm.addSuperscripts(vm.getBrandingProperty())"></span>'
};
