// @ngInject
function acpInterstitialDomainClient(
  $interpolate,
  webapiResource,
  acpCoreDispatcher,
  acpSimpleAccountModel,
  acpProfileClient,
  nsConfig,
  $window,
  content
) {
  var productName;

  function getProductName(contentData) {
    productName = contentData.copy;
  }

  function getAccount() {
    return acpCoreDispatcher.profile.simpleAccount.updated.getValue();
  }

  var client = {},
    clientUri = '/v1/interstitials';

  client._get = webapiResource({
    path: clientUri,
    method: 'GET',
    cache: true,
    paramDefaults: {
      insertion_point: 'oac_dashboard',
      count: 1
    }
  });

  /*
    I get a list of interstitials and return a promise.
  */
  client.get = async function () {
    content.getKey('product-name', 'core/variables').then(getProductName);
    function transformData(data) {
      // if Multiple interstitials, collapse to a single.
      if (data.widgets && data.widgets.length > 0) {
        data.widgets = data.widgets[0];
      } else {
        return;
      }
      function interpolateWidgets() {
        var interstitialString = JSON.stringify(data.widgets);
        var interpolation = $interpolate(interstitialString, false, null, true);
        var interpolationObject = {
          account: getAccount(),
          product: productName
        };

        if (interpolation(interpolationObject)) {
          data.widgets = JSON.parse(interpolation(interpolationObject));
        }
        return data.widgets;
      }

      if (getAccount()) {
        return interpolateWidgets();
      } else {
        acpSimpleAccountModel.get().then(interpolateWidgets);
      }
    }
    const [widgets, profile] = await Promise.all([
      client._get(),
      acpProfileClient.get()
    ]);
    if (profile && widgets && widgets.widgets && widgets.widgets.length > 0) {
      const variantIdPiece = profile.program.brand.variant_id.replace(
        'variant://',
        ''
      );
      if (nsConfig.get('variant') !== variantIdPiece) {
        const url = new URL($window.location.href);
        url.pathname = '/account/interstitials';
        url.searchParams.set('tenant', variantIdPiece);
        $window.location.replace(url.toString());
      }
    }
    return transformData(widgets);
  };

  /*
    I suppress an interstitial. Required param "id".
  */
  client.suppress = webapiResource({
    path: clientUri + '/:id',
    method: 'DELETE'
  });

  /*
    I tell api I viewed an interstitial. Required params "id" and "trigger".
  */
  client.view = webapiResource({
    path: clientUri + '/:id/view',
    method: 'POST'
  });

  /*
    I tell the api I interacted with an interstitial. Required params "id", "response".
   */
  client.respond = webapiResource({
    path: clientUri + '/:id/respond',
    method: 'POST'
  });

  return client;
}

export default acpInterstitialDomainClient;
