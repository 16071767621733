//@ngInject
export default function acpLogException($log, nsConfig, $exceptionHandler) {
  function sendException(exception, cause) {
    var message;

    if (exception && exception.message) {
      message = exception.message;
    } else if (exception) {
      message = exception;
    } else {
      message = 'unknown exception';
    }

    if (cause) {
      message = message + ' :: ' + cause;
    }

    if (exception && exception.filename && exception.lineno) {
      message = message + ':' + exception.filename + ': ' + exception.lineno;
    }

    if (nsConfig.get('mode') === 'development') {
      $log.error(exception, cause);
    } else {
      // This relies on $log being decorated to log to the server.
      $log.error(message);
    }
  }

  return {
    listen: function() {
      $exceptionHandler.onException(sendException);
    }
  };
}
