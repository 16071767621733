//@ngInject
function acpPreviousLoginModel(ACP_STORAGE_KEYS, nsUtil, nsStorage) {
  var def = {};

  def.hasPreviouslyLoggedIn = function() {
    return nsUtil.isDefined(
      nsStorage.local(ACP_STORAGE_KEYS.ACP_HAS_PREVIOUSLY_LOGGED_IN)
    );
  };

  return def;
}

export default acpPreviousLoginModel;
