export class AcpReloadPageService {
  constructor(private $window: ng.IWindowService, private nsConfig: any) {
    'ngInject';
  }

  reloadPage(newVariantId: string) {
    const newVariantIdPiece = (newVariantId || '').replace('variant://', '');
    const url = new URL(this.$window.location.href);

    url.searchParams.set('tenant', newVariantIdPiece);
    this.$window.location.replace(url.toString());
  }

  checkIfVariantMismatch(newVariantId: string | undefined): boolean {
    const oldVariantId = this.nsConfig.get('variant');
    const newVariantIdPiece = (newVariantId || '').replace('variant://', '');
    const oldVariantIdPiece = (this.nsConfig.get('variant') || '').replace(
      'variant://',
      ''
    );

    if (
      !!newVariantId &&
      !!oldVariantId &&
      oldVariantIdPiece !== newVariantIdPiece
    ) {
      return true;
    }

    return false;
  }

  reloadPageIfVariantMismatch(newVariantId: string | undefined) {
    const url = new URL(this.$window.location.href);

    if (
      this.checkIfVariantMismatch(newVariantId) &&
      !url.searchParams.has('tenant')
    ) {
      this.reloadPage(newVariantId);
    }
  }
}
