/*
 * This file exposes a function that is called after every "await" statement.
 * Modeled after https://www.npmjs.com/package/babel-plugin-angularjs-digest-await
 */

var $rootScope;

function safeApply() {
  if (!$rootScope && typeof angular !== 'undefined') {
    var $el = angular.element(document.body);
    var $injector;
    if (typeof $el.injector === 'function') {
      $injector = $el.injector();
    }
    if (
      !$injector &&
      !$rootScope &&
      window.angular.mock &&
      typeof injector == 'function'
    ) {
      $injector = injector();
    }
    if ($injector) {
      $rootScope = $injector.get('$rootScope');
    }
  }

  $rootScope && $rootScope.$$phase == null && $rootScope.$applyAsync();
}

// This function will be called with the an expression that is "awaited" in the source code.
// This functions result will be awaited as well. We must make sure we scope apply
// to ensure changes are propagated
export default function (promise) {
  // We use the value and convert to a promise and use finally on it
  // We don't catch anything, but allow our callers to handle errors (or leave an uncaught error)
  return Promise.resolve(promise).finally(function () {
    // We cannot just update run the apply now, since the outer await has not yet occurred.
    // We need to yield to our caller, allow them to update scope, and then ensure the apply happens.
    // So instead, we schedule after a delay
    setTimeout(safeApply);
  });
}
