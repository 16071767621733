import ng from 'angular';

const CORDOVA = 'cordova';
const WEB = 'web';
const ANDROID = 'android';
const IOS = 'ios';

declare global {
  interface Window {
    Keyboard?: {
      show(): void;
      hide(): void;
    };
  }
}

interface IAcpDeviceServiceProvider {
  isWeb(): boolean;
  isAndroid(): boolean;
  isIos(): boolean;
  isMobile(): boolean;
  showKeyboard(): void;
  hideKeyboard(): void;
  isCordova(): boolean;
  isServedFromHttp(): boolean;
}

export interface AcpDeviceService extends IAcpDeviceServiceProvider {
  decorateScope(scope: ng.IScope): void;
}

export class AcpDeviceServiceProvider implements IAcpDeviceServiceProvider {
  constructor(private nsConfigProvider: any) {
    'ngInject';
  }

  private get platform(): string {
    return this.nsConfigProvider.get('platform');
  }

  private get platformType(): string {
    return this.nsConfigProvider.get('platformType');
  }

  public isCordova(): boolean {
    return this.platformType === CORDOVA;
  }

  public isWeb(): boolean {
    return this.platformType === WEB;
  }

  public isAndroid(): boolean {
    return this.platform === ANDROID;
  }

  public isIos(): boolean {
    return this.platform === IOS;
  }

  public isMobile(): boolean {
    return this.isAndroid() || this.isIos();
  }

  public showKeyboard() {
    if (this.isMobile()) {
      window.Keyboard.show();
    }
  }

  public hideKeyboard() {
    if (this.isMobile()) {
      window.Keyboard.hide();
    }
  }

  public isServedFromHttp(): boolean {
    return window.location.protocol.indexOf('http') === 0; // Match http or https
  }

  // Angular'JS Provider API to create service instance
  public $get = function(nsUtil: nsUtils.NsUtilService): AcpDeviceService {
    'ngInject';

    return ng.extend(this, {
      decorateScope: (scope: ng.IScope) => {
        nsUtil.decorateScope(scope, {
          acpDeviceService: {
            isCordova: () => this.isCordova(),
            isWeb: () => this.isWeb(),
            isAndroid: () => this.isAndroid(),
            isIos: () => this.isIos(),
            isMobile: () => this.isMobile()
          }
        });
      }
    });
  };
}
