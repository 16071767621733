import './vendor';
import directives from './directives';
import services from './services';
import genericAction from './generic-action';
import genericLabelContent from './generic-label-content.yml';
import genericErrorContent from './generic-error-content.yml';
import linksContent from './links-content.yml';
import disclosuresContent from './disclosures-content.yml';
import variablesContent from './variables-content.yml';
import acpRouterRedirect from './acp-router-redirect';
import acpRouterHistoryService from './acp-router-history-service';
import acpStateService from './acp-state-service';
import acpPermStatePermissionMapDecorator from './acp-perm-state-permission-map-decorator';
import acpOcLazyLoadDecorator from './acp-oc-lazy-load-decorator';
import acpConfigurationService from './acp-configuration-service';
import acpStepStateManager from './acp-step-state-manager';
import acpInappBrowserModel from './acp-inapp-browser-model';
import acpPreviousLoginModel from './acp-previous-login-model';
import acpWebapiContentFactory from './acp-webapi-content-factory';
import acpInterstitialDomainModel from './acp-interstitial-domain-model';
import acpInterstitialDomainClient from './acp-interstitial-domain-client';
import acpStopTouchEventDirective from './acp-stop-touch-event-directive';
import acpHtmlAnchorDirective from './acp-html-anchor-directive';
import acpSessionTimer from './acp-session-timer';
import acpWidgetService from './acp-widget-service';
import AcpWidgetDefinitions from './acp-widget-definitions';
import { AcpDeviceDetectionService } from './acp-device-detection-service';
import { AcpDeviceServiceProvider } from './acp-device-service';
import acpDeviceOrientationService from './acp-device-orientation-service';
import acpCordovaEventsClient from './acp-cordova-events-client';
import acpCordovaLinksClient from './acp-cordova-links-client';
import acpRestrictionsClient from './acp-restrictions-client';
import { AcpInPageFlowService } from './acp-in-page-flow-service';
import acpAuthBlocksModel from './acp-auth-blocks-model';
import acpSimpleAccountModel from './acp-simple-account-model';
import acpStatusClient from './acp-status-client';
import acpAuthModel from './acp-auth-model';
import acpAuthClient from './acp-auth-client';
import acpDialogBottomSheet from './acp-dialog-bottom-sheet';
import acpScrollTop from './acp-scroll-top';
import acpInterstitialDomainModelDecorator from './acp-interstitial-domain-model-decorator';
import { AcpFeatureClient, AcpFeatureModel } from './feature-domain';
import {
  AcpBiometricsLoginDomainClient,
  AcpBiometricsLoginDomainService
} from './biometrics-login-domain';
import {
  AcpAnalyticsApiDomainClient,
  AcpAnalyticsApiDomainModel
} from './analytics-api-domain';

import { AcpDisclosuresModel } from './acp-disclosures-model';
import { AcpProfileClient } from './profile-domain';
import acpCoreDispatcher from './acp-core-dispatcher';
import acpAccountsModel from './acp-accounts-model';
import acpUserModel from './acp-user-model';
import acpCvcAuthenticationModel from './acp-cvc-authentication-model';
import exceptionHandler from './exception-handler';
import acpStateRedirectToDecorator from './acp-state-redirect-to-decorator';
import acpCoreRouterPermissions from './acp-core-router-permissions';
import contentDecorator from './content-decorator';
import webapiResourceDecorator from './webapi-resource-decorator';
import qDecorator from './q-decorator';
import acpUrlRouting from './acp-url-routing';
import acpLogDecorator from './acp-log-decorator';
import nsProgramTypes from './constants/ns-program-types';
import nsAccountTypes from './constants/ns-account-types';
import nsSubaccountTypes from './constants/ns-subaccount-types';
import acpAuthBlocks from './acp-auth-blocks';
import acpAuthErrors from './acp-auth-errors';
import acpNsRouterEvents from './acp-ns-router-events';
import acpStorageKeys from './acp-storage-keys';
import acpCorePermissions from './acp-core-permissions';
import acpFrontendConfigClient from './acp-frontend-config-client';
import acpFrontendConfigModel from './acp-frontend-config-model';
import acpUsStates from './constants/ns-us-states';
import acpBusinessTypes from './constants/ns-business-types';
import acpBusinessCategory from './constants/ns-business-category';
import { acpIovationConfig } from './device-fingerprint-domain';
import acpThemeImages from './acp-theme-images';
import { acpUiRouterStateLookupDecorator } from './acp-ui-router-state-lookup-decorator';
import acpLogException from './acp-log-exception';
import { AcpBrandingModel, AcpBrandingClient } from './branding-domain';
import {
  AcpApiBrandingDomainModel,
  AcpApiBrandingDomainClient
} from './api-branding-domain';
import { AcpTermsClient, AcpTermsModel } from './terms-domain';
import { AcpContentFilter } from './acp-content-filter';
import { AcpUriService } from './acp-uri-service';
import { AcpIovationMobileService } from './device-fingerprint-domain';
import { AcpRouteInitializationService } from './initialization/acp-route-initialization-service';
import { AcpAttributionService } from './tracking/acp-attribution-service';
import { AcpReloadPageService } from './acp-reload-page-service';
import { appsflyerPluginFactory } from './plugins/appsflyer';
import ng from 'angular';
import acp from './acp-initial-config';
import './style/core.scss';
import nsBusinessIndustryType from './constants/ns-business-industry-types';
import nsMfeCookies from './constants/ns-mfe-cookies';
import { AcpUpgradeOrderCardClient } from './upgrade-order-card-domain';
import { AcpLogActionClient } from './acp-log-action-client';
import displayBrandingProperties from './display-branding-properties/index';

const config = acp.config;

const core = ng.module('acp.core', [
  'oc.lazyLoad',
  'ngCookies',
  'ngAnimate',
  'ngSanitize',
  'ui.router',
  'permission',
  'permission.ui',
  'angular-bind-html-compile',
  'netspend.ui.utils.core',
  'netspend.ui.utils.content',
  'netspend.webclient',
  'netspend.legos.core',
  'netspend.legos.glyphs',
  'netspend.legos.headings',
  'netspend.legos.in-page-flow',
  'netspend.legos.dialog',
  'netspend.legos.progress_circular',
  'netspend.legos.toolbar',
  'netspend.legos.tooltip',
  'netspend.legos.external-anchor',
  'netspend.legos.in-page-flow',
  'netspend.ui.utils.iovation'
]);

core
  .constant('ACP_CORE_PERMISSIONS', acpCorePermissions)
  .constant('ACP_STORAGE_KEYS', acpStorageKeys)
  .constant('NS_ROUTER_EVENTS', acpNsRouterEvents)
  .constant('ACP_AUTH_ERRORS', acpAuthErrors)
  .constant('ACP_AUTH_BLOCKS', acpAuthBlocks)
  .constant('NS_PROGRAM_TYPES', nsProgramTypes)
  .constant('NS_ACCOUNT_TYPES', nsAccountTypes)
  .constant('NS_SUBACCOUNT_TYPES', nsSubaccountTypes)
  .constant('US_STATES', acpUsStates)
  .constant('NS_BUSINESS_TYPES', acpBusinessTypes)
  .constant('NS_BUSINESS_CATEGORY', acpBusinessCategory)
  .constant('NS_BUSINESS_INDUSTRY_TYPE', nsBusinessIndustryType)
  .constant('NS_MFE_COOKIES', nsMfeCookies)
  .config(acpLogDecorator)
  .config(acpIovationConfig)
  .config(
    /*@ngInject*/ function (
      nsConfigProvider,
      nsStorageProvider,
      $nsExternalAnchorProvider,
      ACP_STORAGE_KEYS,
      $mdGestureProvider
    ) {
      ng.forEach(config, function (val, prop) {
        nsConfigProvider.set(prop, val);
      });

      nsStorageProvider.config.storageKeys = ACP_STORAGE_KEYS;
      nsStorageProvider.config.prefix = 'acp_';

      $nsExternalAnchorProvider.setWhiteListedPatterns(
        nsConfigProvider.get('whiteListedAnchorPatterns')
      );

      // Combining iOS >= 12.3 and Angular Material 1.1.0-rc2 causes
      // Angular Material to hijack clicks on mobile. Turning that off
      $mdGestureProvider.skipClickHijack();
    }
  )
  .config(acpUrlRouting)
  .config(qDecorator)
  .config(webapiResourceDecorator)
  .config(contentDecorator)
  //config(require('./acp-router-auto-block-redirect')).
  .run(acpCoreRouterPermissions)
  .config(acpStateRedirectToDecorator)
  .config(
    /*@ngInject*/ function (nsInPageFlowProvider) {
      nsInPageFlowProvider
        .setDefaultBackButtonText('back')
        .setDefaultContainer('#acp-main-section');
    }
  )
  .factory('$exceptionHandler', exceptionHandler)
  .factory('acpCvcAuthenticationModel', acpCvcAuthenticationModel)
  .factory('acpUserModel', acpUserModel)
  .factory('acpAccountsModel', acpAccountsModel)
  .factory('acpCoreDispatcher', acpCoreDispatcher)
  .service('acpProfileClient', AcpProfileClient)
  .service('acpFeatureClient', AcpFeatureClient)
  .service('acpFeatureModel', AcpFeatureModel)
  .service('acpBiometricsLoginDomainClient', AcpBiometricsLoginDomainClient)
  .service('acpBiometricsLoginDomainService', AcpBiometricsLoginDomainService)
  .service('acpAnalyticsApiDomainClient', AcpAnalyticsApiDomainClient)
  .service('acpAnalyticsApiDomainModel', AcpAnalyticsApiDomainModel)
  .service('acpTermsModel', AcpTermsModel)
  .service('acpDisclosuresModel', AcpDisclosuresModel)
  .service('acpIovationMobileService', AcpIovationMobileService)
  .service('acpScrollTop', acpScrollTop)
  .factory('acpDialogBottomSheet', acpDialogBottomSheet)
  .factory('acpAuthClient', acpAuthClient)
  .factory('acpAuthModel', acpAuthModel)
  .factory('acpStatusClient', acpStatusClient)
  .factory('acpSimpleAccountModel', acpSimpleAccountModel)
  .factory('acpAuthBlocksModel', acpAuthBlocksModel)
  .service('acpBrandingModel', AcpBrandingModel)
  .service('acpBrandingClient', AcpBrandingClient)
  .service('acpApiBrandingDomainClient', AcpApiBrandingDomainClient)
  .service('acpApiBrandingDomainModel', AcpApiBrandingDomainModel)
  .service('acpInPageFlowService', AcpInPageFlowService)
  .factory('acpRestrictionsClient', acpRestrictionsClient)
  .factory('acpCordovaLinksClient', acpCordovaLinksClient)
  .factory('acpCordovaEventsClient', acpCordovaEventsClient)
  .factory('acpDeviceOrientationService', acpDeviceOrientationService)
  .service('acpTermsClient', AcpTermsClient)
  .provider('acpDeviceService', AcpDeviceServiceProvider)
  .service('acpDeviceDetectionService', AcpDeviceDetectionService)
  .service('acpWidgetService', acpWidgetService)
  .value('AcpWidgetDefinitions', AcpWidgetDefinitions)
  .factory('acpSessionTimer', acpSessionTimer)
  .directive('a', acpHtmlAnchorDirective)
  .directive('acpStopTouchEvent', acpStopTouchEventDirective)
  .factory('acpInterstitialDomainClient', acpInterstitialDomainClient)
  .factory('acpInterstitialDomainModel', acpInterstitialDomainModel)
  .factory('acpWebapiContentFactory', acpWebapiContentFactory)
  .factory('acpPreviousLoginModel', acpPreviousLoginModel)
  .factory('acpInAppBrowserModel', acpInappBrowserModel)
  .factory('acpDeviceOrientationService', acpDeviceOrientationService)
  .factory('acpStepStateManager', acpStepStateManager)
  .service('acpConfigurationService', acpConfigurationService)
  .decorator('$ocLazyLoad', acpOcLazyLoadDecorator)
  .decorator('PermStatePermissionMap', acpPermStatePermissionMapDecorator)
  .decorator('$state', acpUiRouterStateLookupDecorator)
  .decorator('acpInterstitialDomainModel', acpInterstitialDomainModelDecorator)
  .factory('acpStateService', acpStateService)
  .factory('acpRouterHistoryService', acpRouterHistoryService)
  .factory('acpFrontendConfigClient', acpFrontendConfigClient)
  .factory('acpFrontendConfigModel', acpFrontendConfigModel)
  .factory('acpLogActionClient', AcpLogActionClient)
  .provider('acpThemeImages', acpThemeImages)
  .factory('acpLogException', acpLogException)
  .service('acpContentFilter', AcpContentFilter)
  .service('acpReloadPageService', AcpReloadPageService)
  .service('acpUriService', AcpUriService)
  .factory('appsflyer', appsflyerPluginFactory)
  .service('acpRouteInitializationService', AcpRouteInitializationService)
  .service('acpAttributionService', AcpAttributionService)
  .service('acpUpgradeOrderCardClient', AcpUpgradeOrderCardClient)
  .run(acpRouterRedirect)
  .run(
    /* @ngInject */ function (
      nsPermissions,
      nsSignal,
      nsConfig,
      nsUtil,
      $rootScope,
      ACP_CORE_PERMISSIONS,
      acpDeviceService,
      acpCordovaEventsClient,
      contentSectionCache,
      acpDeviceOrientationService
    ) {
      nsPermissions.decorateScope($rootScope);
      nsSignal.decorateScope($rootScope);
      nsPermissions.definePermissions(ACP_CORE_PERMISSIONS);
      acpDeviceService.decorateScope($rootScope);
      acpCordovaEventsClient.listen();
      acpDeviceOrientationService.listen();

      nsUtil.decorateScope($rootScope, {
        acpConfig: function (key) {
          return nsConfig.get(key);
        },
        staticTemplate: function (template) {
          return template;
        }
      });

      contentSectionCache.put('core/variables', variablesContent);
      contentSectionCache.put('core/disclosures', disclosuresContent);
      contentSectionCache.put('core/links', linksContent);
      contentSectionCache.put('core/errors', genericErrorContent);
      contentSectionCache.put('core/labels', genericLabelContent);

      acpDeviceOrientationService.listen();
    }
  );

genericAction(core);
directives(core);
services(core);
displayBrandingProperties(core);

export default core;

export * from './acp-device-detection-service';
export * from './acp-device-service';
export { default as ACP_STORAGE_KEYS } from './acp-storage-keys';
export * from './acp-uri-service';
export * from './acp-reload-page-service';
export * from './branding-domain';
export * from './api-branding-domain';
export * from './biometrics-login-domain';
export * from './feature-domain';
export * from './feature-domain/throttle-async';
export * from './plugins/ionic-plugin-deeplinks';
export * from './profile-domain';
export * from './terms-domain';
export * from './services';
export * from './types';
