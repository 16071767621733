// @ngInject
function acpUrlRouting($urlRouterProvider, $windowProvider) {
  // We no longer provide a static redirect. Instead we
  // Coordinate fallbacks between UI-Router and the Gateway router
  // See client-angularjs/src/bootstrap/gateway/ng-fallback-handler.ts
  // For redirection logic
  // $urlRouterProvider.otherwise('/login');

  $urlRouterProvider.deferIntercept();
  // Apply a redirect from /account/<path-to-page> to simply /<path-to-page> when using HTML5 mode.
  // Base tag usually looks like: <base href="/account/" />
  var baseTag = $windowProvider.$get().document.getElementsByTagName('base')[0];
  if (baseTag) {
    $urlRouterProvider.when(
      baseTag.getAttribute('href') + '{path:.*}',
      function($match) {
        return '/' + $match.path;
      }
    );
  }

  // Handles redirecting to forklifted pages
  $urlRouterProvider.when('/{path:.*}.m', function($window, $location) {
    $window.location.assign($location.absUrl());
  });
}
export default acpUrlRouting;
