export default {
  GPR: 'gpr',
  GPR_SUB_ACCOUNT: 'gpr_sub_account',
  BUSINESS: 'business',
  BUSINESS_SUBACCOUNT: 'business_subaccount',
  CONSUMER_DDA: 'consumer_dda',
  BAW_DDA: 'baw_dda',
  HUB_USER: 'hub_user',
  NETSPEND_DISBURSEMENT: 'corp_disbursement'
};
