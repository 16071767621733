// @ngInject
function acpHtmlAnchorDirective(
  $log,
  acpCordovaLinksClient,
  acpDeviceService,
  $state
) {
  return {
    restrict: 'E',
    compile: function() {
      if (acpDeviceService.isCordova()) {
        return function(scope, element) {
          element.on('click', function(event) {
            if (
              // _blank indicates we would want to open this link in a new
              //  tab/window. We can assume this means in app browser when
              // specified.
              element.attr('target') === '_blank' ||
              // Check if it is non-internal URL
              (element.attr('href') &&
                element.attr('href').slice(0, 1) !== '#' &&
                // And ensure there is not other directives that manage its endpoint URL
                !element.attr('ui-sref') &&
                !element.attr('ng-click') &&
                !element.attr('ns-external-anchor') &&
                // Remove after FRED-2191
                element.attr('allow-mfe-link') === undefined)
            ) {
              event.preventDefault();
              $log.info('Caught Link', element.attr('href'));

              // Added this check explitly to play nice with mobile 4.0
              // We fall through above because we no longer have # routing
              // in mobile app 5.0
              var matchedState = $state.stateForHref(element.attr('href'));
              if (matchedState) {
                $log.info('$state.go() to', matchedState);
                $state.go(matchedState.state, matchedState.params);
              } else {
                $log.info('cordova open link', element.attr('href'));
                acpCordovaLinksClient.openExternalLink(element.attr('href'));
              }
            }
          });
        };
      }
    }
  };
}

export default acpHtmlAnchorDirective;
