// @ngInject
function acpCoreDispatcher(nsSignal, nsConfig, nsProperty, nsPermissions) {
  var def = {};

  def.account = {
    branding: nsProperty.create(),
    directDepositInfo: nsProperty.create(),
    upgradeOrdered: nsSignal.create(),
    programType: nsProperty.create()
  };

  def.api = {
    state: nsProperty.create()
  };

  def.activation = {
    accessTokenReady: nsSignal.create(),
    registrationComplete: nsSignal.create(),
    featureEnrollment: {
      esign: {
        complete: nsSignal.create(),
        skipped: nsSignal.create()
      },
      externalRegistration: {
        skipped: nsSignal.create()
      },
      anytimealerts: {
        complete: nsSignal.create(),
        failed: nsSignal.create()
      },
      overdraft: {
        complete: nsSignal.create()
      },
      pickYourPlastic: {
        complete: nsSignal.create()
      }
    },
    complete: nsSignal.create(),
    cardActivateAccountUpgradeFailure: nsSignal.create(),
    persoInfoUpdated: nsSignal.create(),
    upgradingAndAddingCard: nsProperty.create()
  };

  def.authenticate = {
    isCvcUpdated: nsSignal.create(),
    cvcPanAuthFormValidity: nsSignal.create(),
    isQuestionAuthUpdated: nsSignal.create(),
    cvcAuth: {
      failed: nsSignal.create()
    },
    question: {
      get: {
        success: nsSignal.create(),
        failed: nsSignal.create()
      },
      answer: {
        success: nsSignal.create(),
        failed: nsSignal.create()
      },
      list: {
        success: nsSignal.create()
      }
    }
  };

  def.idq = {
    updated: nsSignal.create(),
    success: nsSignal.create(),
    failed: nsSignal.create()
  };

  def.iovation = {
    fingerprint: nsProperty.create()
  };

  def.login = {
    updated: nsSignal.create(),
    loggedIn: nsSignal.create(),
    loggedOut: nsSignal.create(),
    paypal: {
      authUrlResponse: nsSignal.create(),
      saveAuthResponse: nsSignal.create(),
      postLoginRedirect: nsSignal.create(),
      permissionsResponse: nsSignal.create()
    }
  };

  def.routing = {
    logoutDialog: {
      confirm: nsSignal.create(),
      cancel: nsSignal.create()
    },
    customerService: {
      goTo: nsSignal.create()
    }
  };

  def.moveMoney = {
    updated: nsSignal.create(),
    success: nsSignal.create(),
    failed: nsSignal.create(),
    paywalls: {
      passed: nsSignal.create(),
      failed: nsSignal.create()
    }
  };

  def.overdraft = {
    optIn: {
      attempt: nsSignal.create(),
      success: nsSignal.create()
    },
    optOut: {
      attempt: nsSignal.create(),
      success: nsSignal.create()
    }
  };

  def.savingsTransfer = {
    success: nsSignal.create(),
    failed: nsSignal.create()
  };

  def.subaccount = {
    listUpdated: nsSignal.create(),
    selected: nsSignal.create(),
    addRequested: nsSignal.create(),
    addPaywalls: {
      passed: nsSignal.create(),
      failed: nsSignal.create()
    },
    create: {
      success: nsSignal.create(),
      failed: nsSignal.create(),
      close: nsSignal.create()
    },
    deposit: {
      success: nsSignal.create(),
      failed: nsSignal.create()
    },
    withdraw: {
      success: nsSignal.create(),
      failed: nsSignal.create()
    },
    close: {
      requested: nsSignal.create(),
      success: nsSignal.create(),
      updated: nsSignal.create()
    },
    toggle: nsSignal.create(),
    view: nsSignal.create()
  };

  def.esign = {
    accepted: nsSignal.create(),
    declined: nsSignal.create()
  };

  def.contact = {
    addRequested: nsSignal.create(),
    addContactRequested: nsSignal.create(),
    addContactAdded: nsSignal.create(),
    create: {
      success: nsSignal.create(),
      getContactSuccess: nsSignal.create()
    },
    deleteContact: {
      requested: nsSignal.create(),
      success: nsSignal.create()
    },
    requestMoveMoney: nsSignal.create()
  };

  def.sidenav = {
    updateMenuIcon: nsSignal.create(),
    swipeOpened: nsSignal.create(),
    swipeClosed: nsSignal.create()
  };

  def.notificationCenter = {
    swipeOpened: nsSignal.create(),
    swipeClosed: nsSignal.create()
  };

  def.config = {
    changed: nsSignal.create()
  };

  def.toast = {
    show: nsSignal.create()
  };

  def.dialog = {
    show: nsSignal.create(),
    hide: nsSignal.create(),
    cancel: nsSignal.create()
  };

  def.restrictions = {
    updated: nsSignal.create()
  };

  def.profile = {
    user: {
      updated: nsSignal.create(),
      edit: {
        requested: nsSignal.create(),
        submit: nsSignal.create(),
        success: nsSignal.create(),
        error: nsSignal.create()
      },
      paywalls: {
        start: nsSignal.create(),
        passed: nsSignal.create(),
        failed: nsSignal.create()
      }
    },
    accounts: {
      updated: nsSignal.create()
    },
    simpleAccount: {
      updated: nsProperty.create()
    },
    username: {
      updated: nsSignal.create()
    }
  };

  def.history = {
    pending: {
      updated: nsSignal.create()
    },
    posted: {
      updated: nsSignal.create()
    },
    account: {
      selected: nsSignal.create(),
      requested: nsSignal.create()
    },
    csvExport: {
      close: nsSignal.create()
    },
    refresh: {
      requested: nsSignal.create()
    },
    permissions: {
      finished: nsSignal.create()
    }
  };

  def.setPassword = {
    success: nsSignal.create(),
    failed: nsSignal.create()
  };

  def.setSecurityQuestion = {
    success: nsSignal.create(),
    failed: nsSignal.create()
  };

  def.setOoba = {
    success: nsSignal.create(),
    failed: nsSignal.create()
  };

  def.directDeposit = {
    updated: nsSignal.create()
  };

  def.authBlocks = {
    completed: nsSignal.create()
  };

  def.points = {
    programInfo: nsProperty.create(),

    optIn: {
      success: nsSignal.create(),
      failed: nsSignal.create()
    },
    optOut: {
      requested: nsSignal.create(),
      success: nsSignal.create(),
      failed: nsSignal.create()
    },
    externalRedemptionOptIn: {
      success: nsSignal.create(),
      skip: nsSignal.create()
    },
    waysToEarn: {
      requested: nsSignal.create(),
      success: nsSignal.create(),
      close: nsSignal.create()
    },
    redeem: {
      requested: nsSignal.create(),
      success: nsSignal.create(),
      failed: nsSignal.create(),
      close: nsSignal.create()
    },
    terms: {
      success: nsSignal.create(),
      failed: nsSignal.create()
    },
    paywalls: {
      passed: nsSignal.create(),
      failed: nsSignal.create()
    }
  };

  def.terms = {
    accepted: {
      success: nsSignal.create(),
      error: nsSignal.create()
    },
    declined: {
      success: nsSignal.create(),
      error: nsSignal.create()
    },
    updated: nsSignal.create()
  };

  def.alerts = {
    subscribed: {
      success: nsSignal.create(),
      failed: nsSignal.create()
    },
    multiplePhones: nsSignal.create(),
    editPage: {
      backButtonExit: nsSignal.create()
    },
    verifyTextResent: nsSignal.create()
  };

  def.paywalls = {
    passed: nsSignal.create(),
    failed: nsSignal.create(),
    esign: {
      cancelled: nsSignal.create()
    }
  };

  def.paypal = {
    receivePayment: {
      updated: nsSignal.create(),
      success: nsSignal.create(),
      failed: nsSignal.create()
    },
    permissions: {
      changed: nsSignal.create()
    },
    auth: {
      cancelled: nsSignal.create()
    }
  };

  def.westernUnion = {
    loadUrl: {
      success: nsSignal.create()
    },
    sendMoney: {
      requested: nsSignal.create()
    },
    loadMoney: {
      requested: nsSignal.create(),
      paywalls: {
        passed: nsSignal.create()
      }
    },
    mobile: {
      completed: nsSignal.create()
    },
    paywalls: {
      passed: nsSignal.create(),
      failed: nsSignal.create()
    }
  };

  def.payNearMe = {
    loadUrl: {
      success: nsSignal.create()
    }
  };

  def.checkDeposit = {
    enablePermissions: {
      close: nsSignal.create()
    },
    ingoTerms: {
      accepted: nsSignal.create(),
      declined: nsSignal.create()
    },
    ingoBizApprove: {
      accepted: nsSignal.create(),
      declined: nsSignal.create()
    },
    paywalls: {
      passed: nsSignal.create(),
      failed: nsSignal.create()
    }
  };

  def.locations = {
    selected: nsSignal.create(),
    clearSelected: nsSignal.create(),
    mapCenterChange: nsSignal.create(),
    loading: nsProperty.create(),
    locationsChange: nsSignal.create(),
    locationsChangeDone: nsSignal.create(),
    geoLocationChange: nsSignal.create(),
    geoLocationSearch: nsSignal.create(),
    predictionsChange: nsSignal.create(),
    search: nsSignal.create(),
    searchActive: nsSignal.create(),
    resize: nsSignal.create(),
    filter: nsSignal.create(),
    hideFilter: nsSignal.create(),
    merchantLocationsMapped: nsSignal.create(),
    clearType: nsSignal.create(),
    reApplyFilter: nsSignal.create(),
    closeOfferDetailsPopup: nsSignal.create()
  };

  def.virtualCard = {
    virtualCardsUpdate: nsSignal.create(),
    virtualCardNicknameUpdated: nsSignal.create(),
    virtualCardApiFail: nsSignal.create()
  };

  def.billerInfo = {
    billerName: nsSignal.create(),
    receiveCode: nsSignal.create(),
    billerListingData: nsSignal.create(),
    selectedBillerData: nsSignal.create(),
    validationError: nsSignal.create(),
    cleanPaymentDeatils: nsSignal.create()
  };

  def.faqs = {
    permissionsResolved: nsSignal.create()
  };

  def.checks = {
    order: {
      success: nsSignal.create()
    },
    prefund: {
      cancel: nsSignal.create()
    },
    void: {
      requested: nsSignal.create(),
      success: nsSignal.create(),
      failed: nsSignal.create()
    }
  };

  def.offers = {
    list: {
      loaded: nsSignal.create(),
      failed: nsSignal.create()
    },
    hasOffers: nsSignal.create(),
    offer: {
      activated: nsSignal.create(),
      updateState: nsSignal.create(),
      old: {
        activated: nsSignal.create()
      }
    },
    earningsHistory: {
      loadedMore: nsSignal.create()
    },
    optIn: nsSignal.create(),
    optOut: nsSignal.create()
  };

  def.tours = {
    available: nsProperty.create(),
    current: nsProperty.create(),
    cancelled: nsSignal.create(),
    next: nsSignal.create()
  };

  def.inbox = {
    updateUnreadMail: nsSignal.create(),
    inboxEmpty: nsSignal.create(),
    sentItemEmpty: nsSignal.create(),
    isMessageLoading: nsSignal.create()
  };

  def.mail = {
    mailBox: nsSignal.create(),
    cancel: nsSignal.create(),
    newMail: nsSignal.create(),
    newMailWelcome: nsSignal.create(),
    sent: nsSignal.create(),
    sentMail: {
      success: nsSignal.create()
    }
  };

  def.rewards = {
    speedyRewards: {
      success: nsSignal.create()
    }
  };

  def.savings = {
    enroll: {
      start: nsSignal.create(),
      success: nsSignal.create(),
      cancel: nsSignal.create(),
      attempt: nsSignal.create(),
      interrupt: nsSignal.create()
    },
    bank: nsSignal.create(),
    paywalls: {
      reset: nsSignal.create()
    }
  };
  def.expense = {
    confirmLogout: {
      init: nsSignal.create()
    },
    businessOnboarding: {
      success: nsSignal.create()
    }
  };
  def.payBills = {
    paywalls: {
      passed: nsSignal.create(),
      failed: nsSignal.create()
    },
    errors: {
      blocked: nsSignal.create()
    },
    optIn: {
      success: nsSignal.create()
    },
    exitSdk: nsSignal.create()
  };
  def.scrollTo = {
    top: nsSignal.create()
  };

  def.listen = function() {
    nsConfig.changed.onValue(function(val) {
      def.config.changed.emit(val);
    });

    // Permissions should get cleared on any auth update as they are stale.
    def.login.updated.onValue(nsPermissions.clear);
    // Permissions should get cleared on block update as they are stale.
    def.authBlocks.completed.onValue(nsPermissions.clear);
  };

  def.backButton = {
    pressed: nsSignal.create()
  };

  def.pagedSurvey = {
    cancel: nsSignal.create(),
    submit: nsSignal.create(),
    completed: nsSignal.create(),
    error: nsSignal.create(),
    nextPageRequest: nsSignal.create(),
    nextPageResponse: nsSignal.create()
  };

  def.widgets = {
    loaded: nsSignal.create(),
    reload: nsSignal.create()
  };

  def.unauthedLayout = {
    disableBackButton: nsSignal.create()
  };

  def.card = {
    cardsUpdate: nsSignal.create(),
    cardOrderFail: nsSignal.create(),
    carkMarkStolenFail: nsSignal.create(),
    cardsUpdateSuccess: nsSignal.create(),
    cardLock: nsSignal.create(),
    setDigitalWalletPin: nsSignal.create()
  };

  def.secureUpload = {
    fileSelected: nsSignal.create(),
    fileUploaded: nsSignal.create(),
    fileListMaxReached: nsSignal.create()
  };

  def.biometrics = {
    enrollment: {
      intro: {
        completed: nsSignal.create()
      },
      consent: {
        unavailable: nsSignal.create(),
        noStoredCreds: nsSignal.create(),
        consented: nsSignal.create()
      },
      success: {
        acknowledged: nsSignal.create()
      },
      esign: {
        passed: nsSignal.create(),
        failed: nsSignal.create()
      },
      verifyCredentials: {
        completed: nsSignal.create()
      },
      error: nsSignal.create(),
      cancelled: nsSignal.create(),
      wasCancelled: nsProperty.create(), // For analytics purposes
      currentPage: nsProperty.create()
    },
    save: {
      success: nsSignal.create()
    }
  };

  def.debitCardTransfers = {
    payload: nsProperty.create(),
    listUpdated: nsSignal.create()
  };

  def.bankTransfers = {
    payload: nsProperty.create(),
    terms: {
      accepted: nsSignal.create()
    }
  };

  def.feePlan = {
    feePlanSubscribedSuccessful: nsSignal.create()
  };

  def.meToMeTerms = {
    termsUpdated: nsSignal.create()
  };

  return def;
}

export default acpCoreDispatcher;
