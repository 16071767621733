import { AcpAnalytics } from './acp-analytics';
import acpMediaFactory from './acp-media';
import { AcpPreOnboardingConfigurationService } from './acp-pre-onboarding-configuration-service';
import { AcpThirdPartyIdentity } from './acp-third-party-identity';

export * from './acp-analytics';
export * from './acp-media';

export default function(core) {
  core.service('acpAnalytics', AcpAnalytics);
  core.service('acpThirdPartyIdentity', AcpThirdPartyIdentity);
  core.factory('acpMedia', acpMediaFactory);
  core.service(
    'acpPreOnboardingConfigurationService',
    AcpPreOnboardingConfigurationService
  );
  core.run(
    /* @ngInject */ function($rootScope, nsUtil, acpMedia) {
      nsUtil.decorateScope($rootScope, {
        acpMedia: function(size) {
          return acpMedia(size);
        }
      });
    }
  );
}
