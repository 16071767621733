import { AcpProfileClient, ProfileResponse } from 'core';
import { AcpBrandingClient } from './index';
import { AcpBrand, BrandingRootScopeService } from './types';

export class AcpBrandingModel {
  private brand: AcpBrand = this.acpBrandingClient.get();

  constructor(
    private nsUtil: nsUtils.NsUtilService,
    private $rootScope: BrandingRootScopeService,
    private acpBrandingClient: AcpBrandingClient,
    // These should have types added as they're converted to typescript.
    private acpSimpleAccountModel: any,
    private acpCoreDispatcher: any,
    private acpProfileClient: AcpProfileClient,
    private nsConfig: any,
    private content: any
  ) {
    'ngInject';
    this.$rootScope.branding = this.brand;

    this.acpCoreDispatcher.account.branding.onValue((data: AcpBrand) => {
      if (data) {
        this.$rootScope.branding = data;
      }
    });

    this.acpCoreDispatcher.activation.accessTokenReady.onValue(() => {
      this.get();
      this.acpSimpleAccountModel.get();
    });
  }

  // I provide methods for interacting with web-api's /me service.
  // Since I only care about the branding, I get the branding.
  async get(): Promise<void> {
    try {
      const profile: ProfileResponse = await this.acpProfileClient.get();
      this.onBrandingSuccess(profile);
    } catch (error) {
      this.onBrandingError();
    }
  }

  getBranding(): AcpBrand {
    return this.acpCoreDispatcher.account.branding.getValue();
  }

  private onBrandingSuccess(data: ProfileResponse): void {
    const backendBrand: AcpBrand = this.nsUtil.copy(data.program.brand);

    backendBrand.association = this.nsUtil.isDefined(data.program.association)
      ? data.program.association.toLowerCase()
      : null;
    backendBrand.bank = this.nsUtil.isDefined(data.program.bank)
      ? data.program.bank.toLowerCase()
      : null;
    backendBrand.type = data.program.type.toLowerCase();

    if (backendBrand.id !== this.nsConfig.get('brand')) {
      this.content.setBrand(this.nsConfig.get('brand'), backendBrand.id);
    }

    this.content.setBank(backendBrand.bank);
    this.content.setAssociation(backendBrand.association);
    this.acpCoreDispatcher.account.branding.set(
      this.nsUtil.assign(this.brand, backendBrand)
    );
  }

  private onBrandingError(): void {
    this.acpCoreDispatcher.account.branding.set(this.brand);
  }
}
