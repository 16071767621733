import ng from 'angular';
import { IStateService, IUrlRouterService } from 'angular-ui-router';
import { AppsflyerPlugin } from '../plugins/appsflyer';
import {
  DefaultRouteHandler,
  DeferredDeeplinkRouteHandler,
  InitRouteHandler
} from './routes';
import { DeeplinkRouteHandler } from './routes/deeplink-route-handler';

export class AcpRouteInitializationService {
  constructor(
    private $log: ng.ILogService,
    private $state: IStateService,
    private $window: ng.IWindowService,
    private $urlRouter: IUrlRouterService,
    private appsflyer: AppsflyerPlugin
  ) {
    'ngInject';
  }

  async initializeRoute(): Promise<void> {
    await this.initializeFirstRoute();
    this.initializeOpenAppDeeplinking();
  }

  private async initializeFirstRoute(): Promise<void> {
    this.$log.info('Determining first route');
    const routeHandlers: InitRouteHandler[] = [
      this.createDeeplinkRouteHandler(),
      this.createDeferredDeepLinkRouteHandler(),
      this.createDefaultRouteHandler()
    ];

    for (const routerHandler of routeHandlers) {
      this.$log.log(`Running Route Handler: ${routerHandler.name}`);
      try {
        const handled: boolean = await routerHandler.routeIfNecessary();
        this.$log.log(
          `Result of Route Handler: ${routerHandler.name} is ${handled}`
        );

        if (handled) {
          // Route was handled, bail out
          return;
        }
      } catch (e) {
        this.$log.error(`Handler: ${routerHandler.name} threw error`, e);
      }
    }
  }

  private initializeOpenAppDeeplinking(): void {
    this.$log.info('Listening for deeplinks');
    const routeHandler: InitRouteHandler = this.createDeeplinkRouteHandler();
    if (this.$window.IonicDeeplink) {
      this.$window.IonicDeeplink.onDeepLink(async () => {
        try {
          const handled: boolean = await routeHandler.routeIfNecessary();
          this.$log.debug(`Deeplink was handled? ${handled}`);
        } catch (e) {
          this.$log.error('Failed to handle deeplink', e);
        }
      });
    }
  }

  private createDeeplinkRouteHandler(): InitRouteHandler {
    return new DeeplinkRouteHandler(
      this.$log,
      this.$state,
      this.$window,
      this.appsflyer
    );
  }

  private createDeferredDeepLinkRouteHandler(): InitRouteHandler {
    return new DeferredDeeplinkRouteHandler(
      this.$log,
      this.$state,
      this.appsflyer
    );
  }

  private createDefaultRouteHandler(): InitRouteHandler {
    return new DefaultRouteHandler(this.$urlRouter);
  }
}
