import { toCommaSeparatedListString } from 'apps/acp/packages/comma-separated-list-string';

// @ngInject
function acpCordovaLinksClient($log, $window, acpDeviceService) {
  var systemUrls = [
    'www.google.com/maps/dir',
    'twitter.com/intent',
    'facebook.com'
  ];

  function shouldBeHandledBySystem(url) {
    var i;
    for (i = 0; i < systemUrls.length; i++) {
      if (url.indexOf(systemUrls[i]) > -1) {
        return true;
      }
    }

    return false;
  }

  var client = {},
    getConfig = function (url) {
      var config = {};
      if (url.slice(0, 4) === 'http') {
        config.url = url;
        if (
          config.url.slice(-4) === '.pdf' ||
          shouldBeHandledBySystem(config.url)
        ) {
          config.target = '_system';
        } else {
          config.target = '_blank';
        }
      } else if (url.slice(0, 6) === 'mailto') {
        config.url = url;
        config.options = 'hidden=yes';
        config.target = '_system';
      } else if (url.slice(0, 3) === 'tel') {
        config.url = url;
        config.options = 'hidden=yes';
        config.target = '_system';
      } else if (url.slice(0, 6) === 'market' || url.slice(0, 4) === 'itms') {
        config.url = url;
        config.options = 'hidden=yes';
        config.target = '_system';
      } else {
        config.url = url;
        config.target = '_blank';
      }
      return config;
    };

  if (acpDeviceService.isCordova()) {
    client.openExternalLink = function (url, options) {
      var config = getConfig(url);
      $log.debug('open InAppBrowser()', config);
      if (options) {
        var formattedOptions = toCommaSeparatedListString(options);
        config.options = formattedOptions;
      }
      return cordova.InAppBrowser.open(
        config.url,
        config.target,
        config.options
      );
    };
    client.openExternalEmail = function (uri) {
      var config = getConfig(uri);
      config.options = 'hidden=yes';
      $log.debug('open InAppBrowser()', config);
      return cordova.InAppBrowser.open(
        config.url,
        config.target,
        config.options
      );
    };
    client.openExternalTel = function (uri) {
      var config = getConfig(uri);
      config.options = 'hidden=yes';
      $log.debug('open InAppBrowser()', config);
      return cordova.InAppBrowser.open(
        config.url,
        config.target,
        config.options
      );
    };
  } else {
    client.openExternalLink = function (url, options) {
      var config = getConfig(url);
      if (options) {
        var formattedOptions = toCommaSeparatedListString(options);
        config.options = formattedOptions;
      }
      config.target = '';
      return $window.open(config.url, config.target, config.options);
    };
    client.openExternalEmail = function (uri) {
      var config = getConfig(uri);
      config.target = '_blank';
      config.options = 'hidden=yes';
      return $window.open(config.url, config.target, config.options);
    };
    client.openExternalTel = function (uri) {
      var config = getConfig(uri);
      config.target = '_blank';
      config.options = 'hidden=yes';
      return $window.open(config.url, config.target, config.options);
    };
  }

  return client;
}

export default acpCordovaLinksClient;
