import ng from 'angular';

// @ngInject
function permissions(PermPermissionStore, $injector, $q) {
  // Helper function to allow injecting and transforming error response
  function definePermission(name, definition) {
    PermPermissionStore.definePermission(name, function (
      permissionName,
      transitionProperties
    ) {
      var promiseIsh = $injector.invoke(definition, null, {
        permissionName: permissionName,
        transitionProperties: transitionProperties
      });

      return $q.when(promiseIsh).then(
        function (value) {
          // We have to override return value since Angular-Permission expects the resolve value (for `except` permissions)
          // to be the original permission name (for redirectTo). It is a silly API but this is the simplest solution
          // to be compatible with angular permission and still provide additional information.
          if (value === false) {
            // Assume to interpret as a rejection
            return $q.reject(permissionName);
          }
          // Assume the permission evaluator returned a valid permission name redirect
          if (ng.isString(value)) {
            return value;
          }
          // Default to return the permission name to handle redirects, in the case of `except`
          return permissionName;
        },
        function (err) {
          return $q.reject({
            type: 'permissions',
            message: err,
            // We have to override the toString since Angular-Permission expects the reject reason
            // to be the original permission name (for redirectTo). It is a silly API but this is the simplest solution
            // to be compatible with angular permission and still provide additional information.
            toString: function () {
              return permissionName;
            }
          });
        }
      );
    });
  }

  function acpPermissionsDelegate(permission) {
    // @ngInject
    function permissionEnabled(nsPermissions, $q) {
      return nsPermissions
        .requestPermission(permission)
        .then(function (result) {
          if (result === true) {
            return true;
          } else {
            return $q.reject('permission_denied');
          }
        });
    }

    return permissionEnabled;
  }

  function acpGatewayFeatureDelegate(feature) {
    // @ngInject
    function getGatewayFeature(acpFeatureModel) {
      return acpFeatureModel.getGatewayFeature(feature).then(function (result) {
        if (result.features[feature] === true) {
          return true;
        } else {
          return $q.reject(result);
        }
      });
    }

    return getGatewayFeature;
  }

  /////////////////////////////////////////////////////////////////////////
  // Individual perms are described below:  (Please keep alphabetical)

  definePermission(
    'account_has_subaccounts',
    acpPermissionsDelegate('accountHasSubaccounts')
  );
  definePermission(
    'isExternalBankAccountEligible',
    acpPermissionsDelegate('isExternalBankAccountEligible')
  );
  definePermission(
    'allowNSCredentialsForPaypal',
    acpPermissionsDelegate('allowNSCredentialsForPaypal')
  );
  definePermission(
    'isEmbeddedMobile',
    acpPermissionsDelegate('isEmbeddedMobile')
  );
  definePermission(
    'atmFinderEnabled',
    acpPermissionsDelegate('isAtmFinderEnabled')
  );
  definePermission(
    'bdda_order_eligible',
    acpPermissionsDelegate('isBddaUpgradeEligible')
  );
  definePermission(
    'dda_activation_eligible',
    acpPermissionsDelegate('isDdaActivationEligible')
  );
  definePermission(
    'dda_order_eligible',
    acpPermissionsDelegate('isDdaUpgradeEligible')
  );
  definePermission(
    'digital_wallet_enabled',
    acpPermissionsDelegate('isDigitalWalletEnabled')
  );
  definePermission(
    'digital_issuance_account',
    acpPermissionsDelegate('isDigitalIssuanceAccount')
  );
  definePermission(
    'isCipConditionalAlertEligible',
    acpPermissionsDelegate('isCipConditionalAlertEligible')
  );
  definePermission(
    'dynamic_faq_enabled',
    acpPermissionsDelegate('isDynamicFaqEnabled')
  );
  definePermission(
    'external_loyalty_eligible',
    acpPermissionsDelegate('isExternalLoyaltyEligible')
  );
  definePermission(
    'hasBenefitsCenterEligibility',
    acpPermissionsDelegate('isBenefitsCenterEligible')
  );
  definePermission(
    'hasOverdraftFeature',
    acpPermissionsDelegate('hasOverdraft')
  );
  definePermission(
    'isHubRegistrationEnabled',
    acpPermissionsDelegate('isHubRegistrationEnabled')
  );
  definePermission('hasFeedback', acpPermissionsDelegate('hasFeedback'));
  definePermission(
    'isOac4FeedbackEnabled',
    acpPermissionsDelegate('isOac4FeedbackEnabled')
  );
  definePermission(
    'isLocationFinderEnabled',
    acpPermissionsDelegate('isLocationFinderEnabled')
  );

  // This router permission is used as a way to prevent direct navigation to a state tree, rather must be passed
  // a `allow: true` state param in order to enter the state tree
  definePermission(
    'has_valid_allow_parameter',
    /* @ngInject */
    function (transitionProperties, $q) {
      return transitionProperties.toParams.allow ? $q.resolve() : $q.reject();
    }
  );

  definePermission(
    'isBrandingUpdated',
    /*@ngInject*/
    function (acpBrandingModel) {
      return acpBrandingModel.isBrandingUpdated();
    }
  );

  definePermission(
    'mcl_available',
    acpPermissionsDelegate('isMobileCheckAvailable')
  );

  definePermission(
    'isExternalRedemptionLoyaltyProgram',
    /*@ngInject*/
    function (acpPointsModel) {
      return acpPointsModel.isExternalRedemption();
    }
  );

  definePermission('p2p_enabled', acpPermissionsDelegate('p2pEnabled'));

  definePermission(
    'password',
    /*@ngInject*/
    function ($q, acpAuthModel, acpAuthBlocksModel) {
      /*
       *  We need to use acpAuthModel directly instead of acpCorePermissions because
       *  we need to inspect the response for authorisation expired so we can
       *  respond appropriately, otherwise acp-router-hooks thinks it's a regular
       *  unauth error on auth expired.
       */
      return acpAuthModel.getInfo().then(
        function (data) {
          if (data.password === true) {
            if (acpAuthBlocksModel.has()) {
              return $q.reject('has_auth_block');
            }

            return true;
          } else {
            return $q.reject('password');
          }
        },
        function (err) {
          if (err && err.error === 'auth.expired') {
            return $q.reject('session_timeout');
          } else {
            return $q.reject('password');
          }
        }
      );
    }
  );

  definePermission(
    'password_unauthed',
    /*@ngInject*/
    function (nsPermissions, $q, acpAuthBlocksModel) {
      return nsPermissions
        .requestPermission('isPasswordAuthed')
        .then(function (result) {
          if (result === false) {
            return true;
          } else {
            if (acpAuthBlocksModel.has()) {
              return true;
            }

            return $q.reject('password_unauthed');
          }
        });
    }
  );
  definePermission(
    'paybackrewards_eligible',
    acpPermissionsDelegate('isPaybackRewardsEligible')
  );
  definePermission(
    'paypal_transfers',
    acpPermissionsDelegate('hasPaypalPayments')
  );
  definePermission(
    'isPaypalTransfersInfoEnabled',
    acpPermissionsDelegate('isPaypalTransfersInfoEnabled')
  );
  definePermission(
    'send_money_eligible',
    acpPermissionsDelegate('sendMoneyEnabled')
  );
  definePermission(
    'showAnytimeAlertsMasterAccountManagement',
    acpPermissionsDelegate('isMasterAccountAndAcceptedEsignTerms')
  );
  definePermission(
    'product_upgrade_ordered',
    acpPermissionsDelegate('isProductUpgradeOrdered')
  );
  definePermission(
    'product_upgrade_activation_eligible',
    acpGatewayFeatureDelegate('webapi:product_upgrade_activation_eligible')
  );
  definePermission(
    'paybackrewards_eligible',
    acpPermissionsDelegate('isPaybackRewardsEligible')
  );
  definePermission(
    'speedyrewards_eligible',
    acpPermissionsDelegate('isSpeedWay')
  );
  definePermission(
    'is_points_eligible',
    acpPermissionsDelegate('isPointsEligible')
  );
  definePermission(
    'is_points_enrolled',
    acpPermissionsDelegate('isPointsEnrolled')
  );
  definePermission('echeckEnabled', acpPermissionsDelegate('isEcheckEnabled'));
  definePermission(
    'payNearMeEligible',
    acpPermissionsDelegate('isPayNearMeEligible')
  );
  definePermission('hub_user', acpPermissionsDelegate('isHubUser'));
  definePermission(
    'use_dashboard_lite',
    acpPermissionsDelegate('useDashboardLite')
  );
  definePermission(
    'hasSavingsEnrolled',
    acpPermissionsDelegate('hasSavingsEnrolled')
  );
  definePermission(
    'isPaypalLinkedAccount',
    acpPermissionsDelegate('isPaypalLinkedAccount')
  );
  definePermission(
    'isPaypalUnlinkedAccount',
    acpPermissionsDelegate('isPaypalUnlinkedAccount')
  );
  definePermission(
    'isExpenseMgmtOnboardingCompleted',
    /*@ngInject*/
    function (nsPermissions) {
      return nsPermissions
        .requestPermissions([
          'isBusinessOnboardingCompleted',
          'isSmallBusinessSub'
        ])
        .then(function (permissions) {
          if (permissions.isSmallBusinessSub === true) {
            return true;
          } else {
            return permissions.isBusinessOnboardingCompleted;
          }
        });
    }
  );
  definePermission(
    'hasAlertsTermsAccepted',
    acpPermissionsDelegate('hasAlertsTermsAccepted')
  );
  definePermission(
    'oac4AlertsEnabled',
    acpPermissionsDelegate('oac4AlertsEnabled')
  );
  definePermission(
    'oac4ReferAFriendEnabled',
    acpPermissionsDelegate('oac4ReferAFriendEnabled')
  );
  definePermission(
    'oac4PremierEnabled',
    acpPermissionsDelegate('oac4PremierEnabled')
  );
  definePermission(
    'isSideNavCloseAccountEnabled',
    acpPermissionsDelegate('isSideNavCloseAccountEnabled')
  );
  definePermission(
    'isOac4CloseAccountEnabled',
    acpPermissionsDelegate('isOac4CloseAccountEnabled')
  );
  definePermission(
    'rent_track_enabled',
    acpPermissionsDelegate('rentTrackEnabled')
  );
  definePermission(
    'isDirectDepositEnabled',
    acpPermissionsDelegate('isDirectDepositEnabled')
  );
  definePermission(
    'hasGoalCreated',
    /*@ngInject*/
    function (acpSavingsModel, $q) {
      return $q.resolve(acpSavingsModel.getGoalInfo());
    }
  );
  definePermission(
    'isSmallBusinessSub',
    acpPermissionsDelegate('isSmallBusinessSub')
  );
  // Simple permissions that always resolve true / false, useful for hooking into states for redirects
  definePermission('success', function () {
    return true;
  });
  definePermission('failure', function () {
    return false;
  });
  definePermission(
    'isLoyaltyLearnMoreEnabled',
    acpPermissionsDelegate('isLoyaltyLearnMoreEnabled')
  );
  definePermission(
    'isBankTransfersEnabled',
    acpPermissionsDelegate('isBankTransfersEnabled')
  );
  definePermission(
    'subaccount_enabled_account',
    acpPermissionsDelegate('subaccountEnabledAccount')
  );
  definePermission(
    'isCardManagementEnabled',
    acpPermissionsDelegate('isCardManagementEnabled')
  );
  definePermission(
    'hasMeToMeTransferEnabled',
    acpPermissionsDelegate('hasMeToMeTransferEnabled')
  );
  definePermission(
    'isDebitCardTransfersEligible',
    acpPermissionsDelegate('isDebitCardTransfersEligible')
  );
  definePermission(
    'isGprSubaccount',
    acpPermissionsDelegate('isGprSubaccount')
  );
  definePermission(
    'isVariantSunset',
    acpPermissionsDelegate('isVariantSunset')
  );
  definePermission(
    'isSecurityVerified',
    /*@ngInject*/
    function (acpAuthModel) {
      var securityData = acpAuthModel.info.getValue();
      return securityData.questions || securityData.ooba;
    }
  );
  definePermission(
    'isCVCAuthenticated',
    /*@ngInject*/
    function (acpCvcAuthenticationModel) {
      return acpCvcAuthenticationModel.get().then(function (data) {
        return data.cvc;
      });
    }
  );
  definePermission(
    'isTransferNowTermsAndConditions',
    acpPermissionsDelegate('isTransferNowTermsAndConditions')
  );
  definePermission(
    'isTransferNowUsability_CallCSReasons',
    acpPermissionsDelegate('isTransferNowUsability_CallCSReasons')
  );
  definePermission(
    'isElectronicCommunication',
    acpPermissionsDelegate('isElectronicCommunication')
  );
  definePermission(
    'isAlertsEnrolled',
    acpPermissionsDelegate('isAlertsEnrolled')
  );
  definePermission(
    'isMeToMeErrorCondition',
    acpPermissionsDelegate('isMeToMeErrorCondition')
  );
  definePermission(
    'hasFileDisputeEnabled',
    acpPermissionsDelegate('hasFileDisputeEnabled')
  );
  definePermission(
    'hasSecondaryCardHolders',
    acpPermissionsDelegate('hasSecondaryCardHolders')
  );
  definePermission(
    'isCDDAEligibleSecondaryCardHolders',
    acpPermissionsDelegate('isCDDAEligibleSecondaryCardHolders')
  );

  definePermission('isOffersOnMap', acpPermissionsDelegate('isOffersOnMap'));

  definePermission(
    'hasFileDisputeEnabledInLean',
    acpPermissionsDelegate('hasFileDisputeEnabledInLean')
  );

  definePermission(
    'isDashboardRedesignEnabled',
    acpPermissionsDelegate('isDashboardRedesignEnabled')
  );

  definePermission(
    'isNotificationRedesignEnabled',
    acpPermissionsDelegate('isNotificationRedesignEnabled')
  );

  definePermission(
    'isInterstitialsRedesignEnabled',
    acpPermissionsDelegate('isInterstitialsRedesignEnabled')
  );
  definePermission(
    'isConnectedBanksVisible',
    acpPermissionsDelegate('isConnectedBanksVisible')
  );
  definePermission(
    'isPushNotificationEnabled',
    acpPermissionsDelegate('isPushNotificationEnabled')
  );
  definePermission(
    'isCIPConditionalDocumentsNotUploaded',
    acpPermissionsDelegate('isCIPConditionalDocumentsNotUploaded')
  );
  definePermission(
    'isCIPConditionalDocumentsUploaded',
    acpPermissionsDelegate('isCIPConditionalDocumentsUploaded')
  );
  definePermission(
    'isUpdatedAlertTermsAccepted',
    acpPermissionsDelegate('isUpdatedAlertTermsAccepted')
  );
  definePermission(
    'isMasterAccountAndAcceptedUpdatedEsignTerms',
    acpPermissionsDelegate('isMasterAccountAndAcceptedUpdatedEsignTerms')
  );
  definePermission(
    'hasMultipleUpgradeCardBrands',
    acpPermissionsDelegate('hasMultipleUpgradeCardBrands')
  );
  definePermission(
    'isContactMfeEligible',
    acpPermissionsDelegate('isContactMfeEligible')
  );
  definePermission(
    'preauth_dynamic_faq_enabled',
    acpPermissionsDelegate('isPreauthDynamicFaqEnabled')
  );
  definePermission(
    'p2pcontact_enhanced_ui',
    acpPermissionsDelegate('isSendMoneyEnhancedUI')
  );
  definePermission(
    'isLoyaltyMfeEligible',
    acpPermissionsDelegate('isLoyaltyMfeEligible')
  );
}

export default permissions;
