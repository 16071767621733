// @ngInject
function contentDecorator($provide) {
  $provide.decorator(
    'content',
    /* @ngInject */ function($delegate, $q, $log, contentSectionCache) {
      $delegate.loadSection = function(section) {
        var sectionData = contentSectionCache.get(section);

        if (!sectionData) {
          $log.error(
            'content.loadSection(): section is not pre-loaded into cache. ' +
              'Section must explicitly be put in cache using... ' +
              "contentSectionCache.put('" +
              section +
              "', require('content.yml'));"
          );

          return $q.reject('REQUEST_FAILED');
        }

        return $q.resolve(sectionData);
      };

      return $delegate;
    }
  );
}

export default contentDecorator;
