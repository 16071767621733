//@ngInject
function routeAction(
  $window,
  $log,
  content,
  nsUtil,
  $state,
  acpCordovaLinksClient,
  GenericActionDef,
  ACP_GENERIC_ACTION_TYPES
) {
  function action(data) {
    if (nsUtil.isString(data)) {
      if (data.indexOf(':') > -1) {
        data = {
          cmsValue: data
        };
      } else {
        data = {
          route: data
        };
      }
    }

    if (nsUtil.isDefined(data.route)) {
      $state.go(data.route);
      return;
    }

    if (nsUtil.isDefined(data.href)) {
      routeToHref(data.href, data.target);
      return;
    }

    if (nsUtil.isDefined(data.cmsValue)) {
      cmsToRoute(data.cmsValue);
      return;
    }

    $log.warn('No Route or Href:', data);
  }

  function isRelativePath(url) {
    return url.slice(0, 4) !== 'http';
  }

  function routeToHref(href, target) {
    if (
      nsUtil.isDefined(target) &&
      ['_blank', '_parent', '_self', '_top'].indexOf(target) > -1
    ) {
      if (target === '_blank') {
        acpCordovaLinksClient.openExternalLink(
          isRelativePath(href)
            ? new URL(href, $window.location.origin).href
            : href
        );
      } else {
        $window.open(href, target);
      }
      return;
    }
    $window.location.assign(href);
  }

  function cmsToRoute(cms) {
    var splitCMS = cms.split(':');
    var path = splitCMS[0];
    var sectionName = splitCMS[1];
    content.getKey(path, sectionName).then(function(data) {
      action(data.copy);
    });
  }

  return new GenericActionDef(ACP_GENERIC_ACTION_TYPES.ROUTE_ACTION, action);
}

export default routeAction;
