export interface FeatureRequest {
  feature: string;
}

export interface FeaturesRequest {
  features: string[];
}

export interface FeatureSet {
  [featureName: string]: boolean;
}

export interface FeatureResponse {
  features: FeatureSet;
}

export class AcpFeatureClient {
  public featureClearSignals: Array<nsUtils.NsSignal<any>> = [
    this.acpCoreDispatcher.activation.complete,
    this.acpCoreDispatcher.activation.accessTokenReady,
    this.acpCoreDispatcher.terms.accepted.success,
    this.acpCoreDispatcher.terms.declined.success,
    this.acpCoreDispatcher.checks.order.success,
    this.acpCoreDispatcher.paypal.permissions.changed,
    this.acpCoreDispatcher.offers.optIn,
    this.acpCoreDispatcher.offers.optOut,
    this.acpCoreDispatcher.overdraft.optIn.attempt,
    this.acpCoreDispatcher.overdraft.optOut.attempt,
    this.acpCoreDispatcher.savings.enroll.success,
    this.acpCoreDispatcher.expense.businessOnboarding.success,
    this.acpCoreDispatcher.account.upgradeOrdered,
    this.acpCoreDispatcher.payBills.optIn.success,
    this.acpCoreDispatcher.points.optOut.success,
    this.acpCoreDispatcher.login.updated,
    this.acpCoreDispatcher.card.cardsUpdateSuccess,
    this.acpCoreDispatcher.meToMeTerms.termsUpdated
  ];

  public getFeature = this.webapiResource<FeatureRequest, FeatureResponse>({
    method: 'GET',
    path: '/v1/features/:feature',
    clearSignals: this.featureClearSignals,
    cache: true
  });

  public getFeatureNoCache = this.webapiResource<
    FeatureRequest,
    FeatureResponse
  >({
    method: 'GET',
    path: '/v1/features/:feature',
    clearSignals: this.featureClearSignals,
    cache: false
  });

  public getFeatures = this.webapiResource<FeaturesRequest, FeatureResponse>({
    method: 'POST',
    path: '/v2/features',
    format: 'json'
  });

  constructor(
    private webapiResource: nsWebclient.WebapiResourceService,
    private nsPermissions: nsUtils.NsPermissionsService,
    private acpCoreDispatcher: any
  ) {
    'ngInject';

    this.featureClearSignals.forEach((signal: nsUtils.NsSignal<any>) => {
      signal.onValue(this.nsPermissions.clear);
    });
  }
}
