/**
 * Business Category means
 * What does your business do?
 * @type {Array}
 */
export default [
  {
    name: 'Make Things',
    value: 1
  },
  {
    name: 'Sell Things',
    value: 2
  },
  {
    name: 'Provide Services',
    value: 3
  }
];
