// @ngInject
function acpAuthBlocksModel(
  nsStorage,
  nsUtil,
  ACP_STORAGE_KEYS,
  ACP_AUTH_BLOCKS
) {
  var storageKey = ACP_STORAGE_KEYS.ACP_AUTH_BLOCKS_MODEL,
    currentBlocks = [],
    model = {};

  function init() {
    currentBlocks = nsStorage.session(storageKey) || [];
  }

  model.set = function(blocks) {
    // Enforce order of blocks
    currentBlocks = blocks.sort(function(a, b) {
      if (ACP_AUTH_BLOCKS.indexOf(a) < ACP_AUTH_BLOCKS.indexOf(b)) {
        return -1;
      }

      return 1;
    });
    nsStorage.session(storageKey, currentBlocks);
  };

  model.current = function() {
    return nsUtil.copy(currentBlocks);
  };

  model.remove = function(block) {
    currentBlocks = currentBlocks.filter(function(_block) {
      return _block !== block;
    });
    nsStorage.session(storageKey, currentBlocks);
  };

  model.has = function() {
    return currentBlocks.length ? true : false;
  };

  model.hasUnknown = function() {
    return nsUtil.difference(currentBlocks, ACP_AUTH_BLOCKS).length
      ? true
      : false;
  };

  init();

  return model;
}

export default acpAuthBlocksModel;
