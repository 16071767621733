import ng from 'angular';

//@ngInject
function acpWebapiContentFactory($log, $q, contentSectionCache, content) {
  var sectionContentToClear = [];

  function introspectContentSource(contentSource) {
    var KEY_REGEX = /_?key/gi;
    var introspections = [];

    function keyIntrospector(key) {
      if (KEY_REGEX.test(key)) {
        var copyKey = key.replace(KEY_REGEX, '');

        if (ng.isDefined(contentSource[copyKey])) {
          introspections.push({
            key: contentSource[key],
            copy: contentSource[copyKey]
          });
        }
      }
    }

    Object.keys(contentSource).forEach(keyIntrospector);

    return introspections;
  }

  function getExistingContent(sectionName) {
    var contentArray = contentSectionCache.get(sectionName);

    if (!contentArray) {
      debug('There is no default content for ' + sectionName);
      contentArray = [];

      // Store it for now
      contentSectionCache.put(sectionName, contentArray);
    }

    return contentArray;
  }

  function buildContentFromIntrospections(sectionName, introspections) {
    var contentArray = getExistingContent(sectionName);

    var promises = [];

    function performContentMerging(introspection) {
      function onCopyResolved() {
        return contentArray;
      }

      function onCopyNotExist() {
        contentArray.push(introspection);
        return contentArray;
      }

      var promise = content
        .getKeyRaw(introspection.key, sectionName, true)
        .then(onCopyResolved, onCopyNotExist);

      promises.push(promise);
    }

    introspections.forEach(performContentMerging);

    function updateExistingContent() {
      contentSectionCache.put(sectionName, contentArray);
      return content.loadSection(sectionName);
    }

    return $q.all(promises).then(function() {
      return updateExistingContent();
    });
  }

  function debug(message) {
    $log.debug('acpWebapiContentFactory:', message);
  }

  function error(message) {
    $log.error('acpWebapiContentFactory:', message);
  }

  function validate(sectionName, contentSource) {
    if (ng.isUndefined(contentSource)) {
      error('contentSource is not specified');
      return false;
    }

    if (ng.isUndefined(sectionName)) {
      error('sectionName is not specified');
      return false;
    }

    return true;
  }

  return {
    buildContent: function(sectionName, contentSource) {
      if (!validate(sectionName, contentSource)) {
        return $q.all([]);
      }

      if (sectionContentToClear.indexOf(sectionName) === -1) {
        sectionContentToClear.push(sectionName);
      }

      var introspections = introspectContentSource(contentSource);
      return buildContentFromIntrospections(sectionName, introspections);
    }
  };
}

export default acpWebapiContentFactory;
