import { AppsflyerPlugin } from 'core/plugins/appsflyer/appsflyer-plugin';
import { AcpAnalytics } from '../../services/acp-analytics';
import { AppsflyerAttributionData } from './types';

export class AppsflyerWebPlugin implements AppsflyerPlugin {
  private referralCodeSubmitting: Promise<void> = null;

  constructor(private acpAnalytics: AcpAnalytics) {}

  public async getDeeplinkIfExists(): Promise<string> {
    return null;
  }

  public async getDeferredDeeplinkIfExists(): Promise<string> {
    return null;
  }

  // prettier-ignore
  public handleAFEvent(eventName: string, eventValues: { [s: string]: string }): void {
    if(this.referralCodeSubmitting !== null) {
      this.referralCodeSubmitting.then(() => {
        this.handleAFEvent(eventName, eventValues);
      });
    } else {
      this.acpAnalytics.sendCustomEvent('nsAppsFlyer', 'OAC', eventName, '', eventValues);
    }
  }

  public setUserId(referralCode: string): void {
    this.acpAnalytics.sendReferralCode(referralCode);
    this.referralCodeSubmitting = new Promise((resolve) => {
      setTimeout(() => {
        this.referralCodeSubmitting = null;
        resolve();
      }, 3000);
    });
  }

  public async getDeeplinkData(): Promise<AppsflyerAttributionData> {
    return null;
  }
}
