export enum AcpMediaSizes {
  XS = 'xs',
  GT_XS = 'gt-xs',
  SM = 'sm',
  GT_SM = 'gt-sm',
  LT_MD = 'lt-md',
  MD = 'md',
  GT_MD = 'gt-md',

  MOBILE = 'mobile'
}

export type AcpMedia = (size: AcpMediaSizes | string) => boolean;

// @ngInject
function acpMediaFactory($mdMedia: ng.material.IMedia): AcpMedia {
  const sizeToMediaQueryMap: nsUtils.KeyValuePair<string> = {
    [AcpMediaSizes.XS]: '(max-width: 599px)',
    [AcpMediaSizes.GT_XS]: '(min-width: 600px)',
    [AcpMediaSizes.SM]: '(min-width: 600px) and (max-width: 959px)',
    [AcpMediaSizes.GT_SM]: '(min-width: 960px)',
    [AcpMediaSizes.LT_MD]: '(max-width: 1279px)',
    [AcpMediaSizes.MD]: '(min-width: 960px) and (max-width: 1279px)',
    [AcpMediaSizes.GT_MD]: '(min-width: 1280px)'
  };

  // Aliases
  sizeToMediaQueryMap[AcpMediaSizes.MOBILE] =
    sizeToMediaQueryMap[AcpMediaSizes.XS];

  return (sizeOrMediaQuery: AcpMediaSizes | string): boolean => {
    const mediaQuery: string =
      sizeToMediaQueryMap[sizeOrMediaQuery] || sizeOrMediaQuery;
    return $mdMedia(mediaQuery);
  };
}

export default acpMediaFactory;
