import ng from 'angular';

// @ngInject
function acpAuthModel(
  acpAuthClient,
  nsProperty,
  $log,
  $q,
  $window,
  $state,
  nsPermissions,
  webapiResource,
  acpCoreDispatcher,
  acpDeviceService,
  nsUtil,
  nsStorage,
  acpSimpleAccountModel,
  acpAuthBlocksModel,
  acpThirdPartyIdentity,
  ACP_STORAGE_KEYS,
  ACP_AUTH_ERRORS,
  $timeout,
  NS_MFE_COOKIES,
  $cookies
) {
  var defaultInfo = {
      card: false,
      password: false,
      questions: false,
      ooba: false,
      expiresAt: null,
      hasSession: false,
      sessionExpiresIn: null,
      memUsername: null
    },
    model = {},
    // made ends with '.m' extention optional so that i could test MFE route
    notAngularUrlRegex = new RegExp(/^\/account\/[a-zA-Z0-9-_/]+(?:\.m)?$/),
    postLoginRedirectKey = ACP_STORAGE_KEYS.ACP_AUTH_MODEL_POST_LOGIN_REDIRECT,
    rememberUsernameChoice = ACP_STORAGE_KEYS.ACP_AUTH_MODEL_REMEMBER_USERNAME,
    biometricsLoginChoice = ACP_STORAGE_KEYS.ACP_AUTH_MODEL_BIOMETRICS_LOGIN,
    credentialCache = {},
    usernameStorageKey = ACP_STORAGE_KEYS.ACP_AUTH_MODEL_USERNAME;

  model.defaultInfo = defaultInfo;
  model.info = nsProperty.create(defaultInfo);

  /**
   * I test wether a given url is part of Angular app or not
   * I return true for angular states and for MFE/webnew i return false
   */
  model.isAngularState = function (url) {
    return !notAngularUrlRegex.test(url);
  };

  model.getInfo = function () {
    return acpAuthClient.info().then(
      function (info) {
        var myInfo = {
          card: info.cvc,
          password: info.password,
          questions: info.questions,
          ooba: info.ooba,
          expiresAt: info._expires_at_time || null,
          hasSession: info._expires_at_time ? true : false,
          sessionExpiresIn: info._session_expires_in || null
        };

        $log.debug('acpAuthModel.getInfo()', info, myInfo);

        acpAuthBlocksModel.set(info.authorization_blocks || []);

        model.info.set(myInfo);

        return myInfo;
      },
      function (err) {
        return $q.reject(err);
      }
    );
  };

  /**
   * Call this to check if your account is two factor authed.
   * @param includeQuestions
   * If includeQuestions is true, this function will also check to see if you have
   * answered your security questions.
   */

  model.isTwoFactorAuthed = function (includeQuestions) {
    return acpAuthClient.info().then(
      function (info) {
        $log.debug('acpAuthModel.isTwoFactorAuthed()', includeQuestions, info);

        if (includeQuestions && info.questions) {
          return true;
        }
        if (info.ooba || info.cvc) {
          return true;
        }

        return $q.reject();
      },
      function (err) {
        return $q.reject(err);
      }
    );
  };

  model.rememberUsername = function (memUsername) {
    if (memUsername) {
      // Set username in localStorage
      return nsStorage.local(usernameStorageKey, memUsername);
    } else {
      // Get username in localStorage
      return nsStorage.local(usernameStorageKey);
    }
  };

  model.forgetUsername = function () {
    return nsStorage.removeLocalItem(usernameStorageKey);
  };

  model.rememberMeChoice = function (choice) {
    if (choice) {
      // Set 'Remember Me' choice in localStorage
      return nsStorage.local(rememberUsernameChoice, choice);
    } else {
      // Get 'Remember Me' choice in localStorage
      return nsStorage.local(rememberUsernameChoice);
    }
  };

  model.forgetRememberMeChoice = function () {
    return nsStorage.removeLocalItem(rememberUsernameChoice);
  };

  model.setBiometricsLoginChoice = function (choice) {
    return nsStorage.local(biometricsLoginChoice, choice);
  };

  model.getBiometricsLoginChoice = function () {
    return nsStorage.local(biometricsLoginChoice);
  };

  model.unsetBiometricsLogin = function () {
    return nsStorage.removeLocalItem(biometricsLoginChoice);
  };

  model.clearCredentialCache = function () {
    credentialCache = {};
  };

  model.setCredentialCache = function (credential) {
    credentialCache = {
      username: credential.username,
      password: credential.password
    };
  };
  model.getCredentialCache = function () {
    return credentialCache;
  };
  model.hasCredentialCache = function () {
    return !!credentialCache.username && !!credentialCache.password;
  };

  function clearMfeCookies() {
    NS_MFE_COOKIES.map(function (cookie) {
      $cookies.remove(cookie);
    });
  }

  function oac4LogoutCleanup() {
    // We fire this off async and hope it hits WebAPI. We likely won't have the same JS window session when it comes back.
    acpAuthClient.logout();
    // We schedule the local sync cleanup in a timeout to ensure that the logout call was scheduled
    // e.g. the access token was picked up before we clear it from storage
    return $timeout(function () {
      nsStorage.clear();
      webapiResource.setToken(null);
      webapiResource.getCache().removeAll();
      nsProperty.resetAll(); // this method needs to be rewritten for async
      nsPermissions.reset();
      acpThirdPartyIdentity.clearUser();
      clearMfeCookies();
    }, 10);
  }

  model.logout = function (silent) {
    return model.clear().then(function () {
      if (!silent) {
        acpCoreDispatcher.login.loggedOut.emit();
      }
    });
  };

  model.clear = function () {
    return oac4LogoutCleanup().catch(ng.noop);
  };

  model.sessionTimeout = function () {
    var currentRoute = $state.current.name;
    model.clear().then(function () {
      if (currentRoute) {
        /**
         * setting a new key in session when sessionTimeout happens and
         * user redirect to mfe login page and after successful mfe login,
         * will be redirected to page from where sessiontimeout happend.
         */
        nsStorage.session(
          ACP_STORAGE_KEYS.ACP_POST_LOGIN_REDIRECT_URL,
          location.pathname
        );
        model.setPostLoginRedirect(currentRoute);
      }
      acpCoreDispatcher.login.loggedOut.emit({ type: 'session_timeout' });
    });
  };

  // Logout of current domain, and login to the other.
  model.switchServer = function (targetServer, accessToken) {
    var silent = true; // I'm doing this for visibility and consistency. We need
    // acpCoreDispatcher.login.loggedOut.emit() to not fire when passing silent

    return model
      .logout(silent)
      .catch(ng.noop)
      .then(function () {
        if (acpDeviceService.isServedFromHttp()) {
          return crossDomainLoginRedirect(targetServer.serverName, accessToken);
        } else {
          // noop since embedded apps are dead
        }
      });
  };

  /**
   * This listens for the security question updated event,
   * and updates the info model
   */
  acpCoreDispatcher.authenticate.question.answer.success.onValue(function () {
    updateSecurityQuestionModel();
  });

  function updateSecurityQuestionModel() {
    var updatedInfo = nsUtil.assign({}, model.info.getValue(), {
      questions: true
    });
    model.info.set(updatedInfo);
  }

  model.onPasswordSuccess = onPasswordSuccess;

  function onPasswordSuccess(data) {
    webapiResource.setToken(data.access_token);

    if (data.authorization_blocks) {
      acpCoreDispatcher.login.updated.emit({
        type: 'authed.password.blocks',
        blocks: data.authorization_blocks
      });

      return $q.resolve(true);
    } else {
      return handleTypicalLogin(data);
    }
  }

  function handleTypicalLogin() {
    return acpSimpleAccountModel.get().then(function () {
      acpCoreDispatcher.api.state.set('online');

      //TODO: maybe refactor this since all that stuff is now removed.
      // We have to wait to update the `acpCoreDispatcher.login` status until after we are sure we have created
      // appropriate `webnewLogin` promises.

      acpCoreDispatcher.login.updated.emit({
        type: 'authed.password'
      });

      return true;
    });
  }

  function hasUnknownErrors(errors, possible) {
    return !!nsUtil.difference(errors, possible).length;
  }

  function onPasswordFail(err) {
    var notice = {
      error: err
    };

    if (err && nsUtil.isArray(err._server_errors)) {
      notice.serverErrors = err._server_errors;
    } else {
      notice.serverErrors = [];
    }

    if (
      nsUtil.isEmpty(notice.serverErrors) ||
      hasUnknownErrors(err._server_errors, ACP_AUTH_ERRORS)
    ) {
      notice.serverErrors.push('global.unknown_error');
    }

    $log.error('acpAuthClient.authPassword().onFail()', err);

    return $q.reject(notice);
  }

  model.authPassword = function (_params) {
    var params = {
      login: _params.username,
      password: _params.password
    };

    if (!_params.username) {
      $log.warn('acpAuthModel.authPassword(): Username is required');
    }
    if (!_params.password) {
      $log.warn('acpAuthModel.authPassword(): Password is required');
    }

    if (_params.passwordFromDeviceStorage) {
      params.biometrics_login = _params.passwordFromDeviceStorage;
      if (_params.biometric_type) {
        params.biometric_type = _params.biometric_type;
      }
    }

    if (_params.fingerprint) {
      params.fingerprint = _params.fingerprint;
    }

    if (_params.recaptcha) {
      params.recaptcha_response = _params.recaptcha;
    }

    return acpAuthClient
      .authPassword(params)
      .then(onPasswordSuccess, onPasswordFail);
  };

  model.verifyPassword = function (password) {
    return acpAuthClient.verifyPassword({
      password: password
    });
  };

  // If we get a brand mismatch issue upon login, we can
  // use this login to the correct server and then redirect
  // our browser to ACP on the correct domain with the access
  // token as part of the URI. Note; the access token is passed
  // as the hash parameter and will be received by the login-authorize
  // state on the correct domain. If it does not exist for whatever
  // reason, it would likely redirect to the login page.
  model.crossBrandAuthPassword = function (params) {
    var authParams = {
      login: params.username,
      password: params.password
    };

    if (params.fingerprint) {
      authParams.fingerprint = params.fingerprint;
    }

    return acpAuthClient
      .crossDomainAuthPassword(
        params.server,
        params.brand,
        params.variantId,
        authParams
      )
      .then(function (resp) {
        return crossDomainLoginRedirect(params.server, resp.access_token);
      });
  };

  // Given a domain and an access token, login to the correct server
  function crossDomainLoginRedirect(server, accessToken) {
    // We want to pass more info than just the token, so we want to use JSON, but
    // UI-Router is not serializing us safe for the URL, so encode as b64
    var hash = $window.btoa(ng.toJson({ access_token: accessToken }));
    // Build the path for this state on the other server
    var path = $state.href('login-authorize', { '#': hash });
    var url = 'https://' + server + path;
    $window.location.assign(url);
  }

  function onAccessTokenSuccess(accessToken, data) {
    webapiResource.setToken(accessToken);
    acpCoreDispatcher.api.state.set('online');

    if (data.authorization_blocks) {
      acpCoreDispatcher.login.updated.emit({
        type: 'authed.password.blocks',
        blocks: data.authorization_blocks
      });
    } else {
      acpCoreDispatcher.login.updated.emit({
        type: 'authed.password'
      });
    }

    return $q.resolve(true);
  }

  function onAccessTokenFail(err) {
    var notice = {
      error: err
    };

    if (err && nsUtil.isArray(err._server_errors)) {
      notice.serverErrors = err._server_errors;
    } else {
      notice.serverErrors = [];
    }

    if (
      nsUtil.isEmpty(notice.serverErrors) ||
      hasUnknownErrors(err._server_errors, ACP_AUTH_ERRORS)
    ) {
      notice.serverErrors.push('global.unknown_error');
    }

    $log.error('acpAuthClient.authAccessToken().onFail()', err);
    return $q.reject(notice);
  }

  model.authAccessToken = function (accessToken) {
    webapiResource.setToken(accessToken);
    webapiResource.retryOutstandingRequests();

    function onSuccess(data) {
      return onAccessTokenSuccess(accessToken, data || {});
    }

    function onFail(error) {
      return onAccessTokenFail(error);
    }

    return acpAuthClient.info().then(onSuccess, onFail);
  };

  model.getPostLoginRedirect = function () {
    return nsStorage.session(postLoginRedirectKey);
  };

  model.setPostLoginRedirect = function (redirect) {
    if (nsUtil.isDefined(redirect) && redirect !== null) {
      if (!$state.get(redirect)) {
        // If it's not a valid route, check if it's a valid webnew url
        redirect = decodeURIComponent(redirect);
        if (model.isAngularState(redirect)) {
          $log.error(
            'acpAuthModel.setPostLoginRedirect-> Not a valid route or url'
          );
          return;
        }
      }

      return nsStorage.session(postLoginRedirectKey, redirect);
    }
  };

  model.clearPostLoginRedirect = function () {
    return nsStorage.removeSessionItem(postLoginRedirectKey);
  };

  model.listen = function () {
    // $rootScope.$on('webapi.unauthorized', model.sessionTimeout);
    // Whenever a routine involving logout occurs, this signal should not be emitted,
    // otherwise an infinite loop happens with unauthorisation error
    acpCoreDispatcher.login.updated.onValue(function () {
      // Update auth info on all auth changes
      model.getInfo();
    });

    //when the user has successfully logged in we keep track of that, so that we can take action at later times
    acpCoreDispatcher.login.loggedIn.onValue(function () {
      nsStorage.local(
        ACP_STORAGE_KEYS.ACP_HAS_PREVIOUSLY_LOGGED_IN,
        new Date().getTime()
      );

      var cachedProfile = acpCoreDispatcher.profile.simpleAccount.updated.getValue();
      if (cachedProfile) {
        acpThirdPartyIdentity.setUser(cachedProfile);
      } else {
        // Profile is not cached until after the 'login.loggedIn' event in the OOBA workflow
        acpSimpleAccountModel.get().then(function (profile) {
          acpThirdPartyIdentity.setUser(profile);
        });
      }
    });
  };

  return model;
}

export default acpAuthModel;
