export const acpUiRouterStateLookupDecorator = (
  $delegate,
  $document: ng.IDocumentService,
  $location: ng.ILocationService
) => {
  'ngInject';

  // Determine the currently loaded base url. We will use this multiple times to determine the 'angular' part of a url
  const baseUrl = $location.absUrl().replace($location.path(), '');

  /**
   * Given a href, determine the state that would match it.
   * @param href
   * @returns {{state: string; params: any}} | null
   */
  $delegate.stateForHref = (href): { state: string; params: any } | null => {
    const normalizedHref = normalize(href);

    for (const state of $delegate.get()) {
      if (!state.$$permissionState) {
        // Skip the state if we cannot access the internal state definition (e.g. name: '^')
        continue;
      }
      const privateState = state.$$permissionState();
      if (!privateState.url) {
        continue;
      }
      const match = privateState.url.exec(normalizedHref);
      if (match) {
        return {
          state: state.name,
          params: match
        };
      }
    }
    return null;
  };

  function normalize(href): string {
    const anchor: HTMLAnchorElement = $document[0].createElement('a');
    anchor.setAttribute('href', href);
    // Return the absolute href given by the anchor but remove the base portion of the url if present
    return anchor.href.replace(baseUrl, '');
  }

  return $delegate;
};
