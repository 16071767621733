import ng from 'angular';

//@ngInject
function acpTrackingDirective(acpAnalytics) {
  function isNsButton($el) {
    return $el[0].nodeName === 'NS-BUTTON';
  }

  // Click is attached to the inner button or a element
  // make sure we use that instead of the exterior button
  function extractActualNodeFromNsButton($el) {
    var actualNode = ng.element($el[0].querySelector('button'));

    if (!actualNode.length) {
      actualNode = ng.element($el[0].querySelector('a'));
    }

    return actualNode;
  }

  function link($scope, $el, $attrs) {
    if (isNsButton($el)) {
      $el = extractActualNodeFromNsButton($el);
    }

    var type = $attrs.acpTracking || 'click';
    var action = $attrs.acpTrackingAction || 'undefined';
    var label = $attrs.acpTrackingLabel || 'undefined';
    var category = $attrs.acpTrackingCategory || 'undefined';

    $attrs.$observe('acpTracking', function(newType) {
      type = newType;
    });
    $attrs.$observe('acpTrackingAction', function(newAction) {
      action = newAction;
    });
    $attrs.$observe('acpTrackingLabel', function(newLabel) {
      label = newLabel;
    });
    $attrs.$observe('acpTrackingCategory', function(newCategory) {
      category = newCategory;
    });

    function invoker() {
      acpAnalytics.sendEvent(category, action, label);
    }

    function onDestroy() {
      $el.off(type, invoker);
    }

    $el.on(type, invoker);
    $scope.$on('$destroy', onDestroy);
  }

  return {
    restrict: 'A',
    link: link
  };
}

export default acpTrackingDirective;
