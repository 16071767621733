// @ngInject
function acpUserModel(
  nsUtil,
  nsDate,
  acpCoreDispatcher,
  acpProfileClient
  // webApiResource
) {
  var model = {},
    user;

  /**
   * I provide methods for interacting with web-api's /me service.
   * Since I only care about the user, I transform the response.
   */

  model.get = function() {
    return acpProfileClient.user().then(function(data) {
      var transformedUser = data;

      if (
        transformedUser.primary_card_owner &&
        nsUtil.isDefined(transformedUser.primary_card_owner.residential_address)
      ) {
        var raLine1 = transformedUser.primary_card_owner.residential_address
          .line1
          ? transformedUser.primary_card_owner.residential_address.line1
          : '';
        var raLine2 = transformedUser.primary_card_owner.residential_address
          .line2
          ? transformedUser.primary_card_owner.residential_address.line2
          : '';
        transformedUser.primary_card_owner.residential_address._line =
          raLine1 + ' ' + raLine2;
      }

      if (
        transformedUser.primary_card_owner &&
        nsUtil.isDefined(transformedUser.primary_card_owner.ship_to_address)
      ) {
        var saLine1 = transformedUser.primary_card_owner.ship_to_address.line1
          ? transformedUser.primary_card_owner.ship_to_address.line1
          : '';
        var saLine2 = transformedUser.primary_card_owner.ship_to_address.line2
          ? transformedUser.primary_card_owner.ship_to_address.line2
          : '';
        transformedUser.primary_card_owner.ship_to_address._line =
          saLine1 + '' + saLine2;
      }

      if (
        transformedUser.primary_card_owner &&
        nsUtil.isDefined(transformedUser.primary_card_owner.first_name) &&
        nsUtil.isDefined(transformedUser.primary_card_owner.last_name)
      ) {
        transformedUser.primary_card_owner._full_name =
          transformedUser.primary_card_owner.first_name +
          ' ' +
          transformedUser.primary_card_owner.last_name;
        transformedUser.primary_card_owner._display_name =
          transformedUser.primary_card_owner._full_name;
      }

      //transformedUser._creation_time = webApiResource.toTime(data.creation_date);
      transformedUser._creation_time = nsDate.fromApi(data.creation_date);

      user = transformedUser;
      acpCoreDispatcher.profile.user.updated.emit(user);
      return user;
    });
  };

  model.getUser = function() {
    return user;
  };

  model.clear = function() {
    user = null;
  };

  model.shippingAddress = function() {
    if (nsUtil.isDefined(user)) {
      if (nsUtil.isDefined(user.primary_card_owner.ship_to_address)) {
        return user.primary_card_owner.ship_to_address;
      } else if (
        nsUtil.isDefined(user.primary_card_owner.residential_address)
      ) {
        return user.primary_card_owner.residential_address;
      }
    }

    return '';
  };

  return model;
}

export default acpUserModel;
