import ng from 'angular';
import { AppsflyerPlugin } from './appsflyer-plugin';
import {
  Appsflyer,
  AppsflyerAttributionData,
  AppsflyerSdkOptions,
  AppsflyerSuccessResponse
} from './types';
import NsPropertyService = nsUtils.NsPropertyService;
import NsProperty = nsUtils.NsProperty;

interface AcpConfigurationService {
  getIOSAppStoreId(): string;
  getAppsFlyerDevKey(): string;
}

export class AppsflyerCordovaPlugin implements AppsflyerPlugin {
  private _initialized = false;
  private _attributed = false;
  private _initPromise: ng.IPromise<void> = null;

  private _openAttributionData: NsProperty<AppsflyerAttributionData>;
  private _installAttributionData: NsProperty<AppsflyerAttributionData>;

  private referralCodeSubmitting: Promise<void> = null;
  private deeplinkDeferred: ng.IDeferred<string> = null;

  constructor(
    private $q: ng.IQService,
    private $log: ng.ILogService,
    private appsflyer: Appsflyer,
    private acpConfigurationService: AcpConfigurationService,
    nsProperty: NsPropertyService,
    private $timeout: ng.ITimeoutService
  ) {
    this._openAttributionData = nsProperty.create();
    this._installAttributionData = nsProperty.create();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this._openAttributionData.onValue((data) => {
      if (this.deeplinkDeferred != null) {
        this.deeplinkDeferred.resolve();
      }
    });
  }

  async getDeeplinkIfExists(): Promise<string> {
    this.deeplinkDeferred = this.$q.defer();
    const data: AppsflyerAttributionData = await this.getOpenAttributionData();
    this.$log.info('Appsflyer Deeplink Attribution Data', data);
    return data ? this.deeplinkToState(data.af_dp) : null;
  }

  async getDeferredDeeplinkIfExists(): Promise<string> {
    const data: AppsflyerAttributionData = await this.getInstallAttributionData();
    this.$log.info('Appsflyer Deferred Deeplink Attribution Data', data);
    // For some reason data.is_first_launch is a boolean on iOS but a string on Android. Fun!
    const isFirstLaunch =
      data &&
      (data.is_first_launch === 'true' || data.is_first_launch === true);
    return isFirstLaunch ? this.deeplinkToState(data.af_dp) : null;
  }

  handleAFEvent(eventName: string, eventValues: { [s: string]: string }): void {
    if (this.referralCodeSubmitting !== null) {
      this.referralCodeSubmitting.then(() => {
        this.handleAFEvent(eventName, eventValues);
      });
    } else {
      this.appsflyer.logEvent(
        eventName,
        eventValues,
        () => {
          this.$log.info('Appsflyer in-app event logged');
        },
        () => {
          this.$log.error('Appsflyer in-app event logging failed');
        }
      );
    }
  }

  setUserId(referralCode: string): void {
    this.appsflyer.setAppUserId(referralCode);
    this.referralCodeSubmitting = new Promise((resolve) => {
      setTimeout(() => {
        this.referralCodeSubmitting = null;
        resolve();
      }, 3000);
    });
  }

  async getDeeplinkData(): Promise<AppsflyerAttributionData> {
    let data: AppsflyerAttributionData = await this.getOpenAttributionData();
    if (!data) {
      data = await this.getInstallAttributionData();
    }

    if (data.c) {
      data.campaign = data.c;
    }
    return data;
  }

  private deeplinkToState(deeplink: string): string {
    if (deeplink) {
      return deeplink.replace('route://', '');
    }

    return deeplink;
  }

  private initSdk(): void {
    const deferred: ng.IDeferred<void> = this.$q.defer();
    let isOnSuccess = false;

    const sdkOptions: AppsflyerSdkOptions = {
      isDebug: true,
      devKey: this.acpConfigurationService.getAppsFlyerDevKey(),
      appId: this.acpConfigurationService.getIOSAppStoreId(),
      onInstallConversionDataListener: true
    };
    const onSuccess = (json: string): void => {
      isOnSuccess = true;
      this.$log.info('Appsflyer Data Success: ', json);
      const result: AppsflyerSuccessResponse = ng.fromJson(json);
      this.$log.log(`Appsflyer startup ${result.status}-${result.type}`);
      this._installAttributionData.set(result.data);
      this.afterSdkInitialization();
      deferred.resolve();
    };

    const onFailure = (json: string): void => {
      this.$log.info('Appsflyer Data Failed: ', json);
      this.$log.error('Failed configuring Appsflyer', json);
      this.afterSdkInitialization();
      deferred.reject(new Error(json));
    };

    this.$timeout(() => {
      if (!isOnSuccess) {
        onFailure('Timeout for Appsflyer initSdk');
      }
    }, 5000);

    this.$log.debug('Initializing Appsflyer', sdkOptions);
    this._initPromise = deferred.promise;
    this.appsflyer.initSdk(sdkOptions, onSuccess, onFailure);
  }

  private afterSdkInitialization(): void {
    this._initialized = true;
    this._initPromise = null;
  }

  private async initAttribution(): Promise<void> {
    const onOpenSuccess = (json: string): void => {
      const result: AppsflyerSuccessResponse = ng.fromJson(json);
      this._openAttributionData.set(result.data);
      this.afterOnAppOpenAttribution();
    };

    const onOpenFailure = (json: string): void => {
      this.afterOnAppOpenAttribution();
      this.deeplinkDeferred.reject(new Error(json));
    };

    this.appsflyer.registerOnAppOpenAttribution(onOpenSuccess, onOpenFailure);
  }

  private afterOnAppOpenAttribution(): void {
    this._attributed = true;
    this.deeplinkDeferred = null;
  }

  private async getOpenAttributionData(): Promise<AppsflyerAttributionData> {
    try {
      await this.waitForSdkIfNeeded();
      return this._openAttributionData.getValue();
    } catch (e) {
      this.$log.error('Unable to get attribution data', e);
      return null;
    }
  }

  private async getInstallAttributionData(): Promise<AppsflyerAttributionData> {
    try {
      await this.waitForSdkIfNeeded();
      return this._installAttributionData.getValue();
    } catch (e) {
      this.$log.error('Unable to get attribution data', e);
      return null;
    }
  }

  /**
   * Waits for the SDK if it is currently loading.
   */
  private async waitForSdkIfNeeded(): Promise<void> {
    try {
      if (!this._initialized) {
        this.initSdk();
      }

      if (!this._attributed) {
        await this.initAttribution();
      }

      if (this._initPromise != null) {
        await this._initPromise;
      }

      if (this.deeplinkDeferred != null && window.deeplinkData) {
        await this.deeplinkDeferred.promise;
      }
    } catch (e) {
      this.$log.error('Appsflyer failed to initialize', e);
      throw e;
    }
  }
}
