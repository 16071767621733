export interface AppTracking {
  nsApp: string;
  nsVersion: string;
  nsBrand: string;
  nsPlatform: string;
  nsPlatformType: string;
  nsMode: string;
  nsVariant: string;
}

export interface SimpleAccountTracking {
  nsAccountType: string;
  nsReferralId: string;
  nsCardAssociation?: string;
  nsBank: string;
}
export interface PageViewTracking {
  event: string;
  nsPageView_page: string;
  nsPageView_title: string;
  nsPageView_overdraftEnrolled?: boolean;
  nsPageView_feeplan?: string;
}

export interface EventDelegateTracking {
  event: any;
  nsEvent_category: any;
  nsEvent_action: any;
  nsEvent_label: any;
  nsEvent_value?: any;
}

export class AcpAnalytics {
  private lastAdobeTrackedState: string;

  constructor(
    private $location: ng.ILocationService,
    private $window: ng.IWindowService,
    private $rootScope: ng.IRootScopeService,
    private nsUtil: nsUtils.NsUtilService,
    private $log: ng.ILogService,
    private $state: ng.ui.IStateService,
    private nsConfig: any,
    private nsStorage: any,
    private acpCoreDispatcher: any,
    private NS_ROUTER_EVENTS: any
  ) {
    'ngInject';

    this.$window.adobeDataLayer = this.$window.adobeDataLayer || [];
    const appTracking: AppTracking = {
      nsApp: this.nsConfig.get('appName'),
      nsVersion: this.nsConfig.get('appVersion'),
      nsBrand: this.nsConfig.get('brand'),
      nsPlatform: this.nsConfig.get('platform'),
      nsPlatformType: this.nsConfig.get('platformType'),
      nsMode: this.nsConfig.get('mode'),
      nsVariant: this.nsConfig.get('variant')
    };

    this.track(appTracking);
    this.adobeTrack({
      event: 'Application Startup',
      siteData: appTracking
    });
  }

  // This can accept an object with any properties. Typing it is problematic.
  track(data: any): void {
    if (this.$window.dataLayer) {
      this.$window.dataLayer.push(data);
    }
  }

  adobeTrack(data: any): void {
    if (this.$window.adobeDataLayer) {
      this.$window.adobeDataLayer.push(data);
    }
  }

  sendPageView(url?: string, title?: string, additionalData?: any): void {
    let cleanedUrl = '';

    try {
      const pageUrl: string = '/account' + (url || this.$location.url());
      // Stripping numbers protects against leaks such as pan, cvc, ssn, phone,
      // accounts numbers potentially being leaked if put into a URL for some reason.
      const stripHash = pageUrl.replace('#' + this.$location.hash(), '');
      // Stripping hash protects again handoff and auth tokens being transmitted
      cleanedUrl = stripHash.replace(/[0-9]/g, 'X');
    } catch (error) {
      cleanedUrl = '/account/exception-cleaning';
    }

    const pageTitle: string = title || this.$state.current.name;

    this.$log.debug(
      `Reporting pageview for url ${cleanedUrl} title ${pageTitle}`
    );

    const baseTrack: PageViewTracking = {
      event: 'nsPageView',
      nsPageView_page: cleanedUrl,
      nsPageView_title: pageTitle
    };

    // If additionalData is undefined, baseTrack object will not be extended.
    const trackingData: any = this.nsUtil.assign(baseTrack, additionalData);

    this.track(trackingData);

    // Dont send the same page view event twice in a row
    if (this.lastAdobeTrackedState !== this.$state.current.name) {
      const { hostname } = this.$window.location;
      const [section, page] = this.getSectionPageNamesFromState(
        this.$state.current.name
      );
      const accountId = this.nsStorage.local('account_id');
      this.adobeTrack({
        event: 'aaPageView',
        pageData: {
          event: 'pageView',
          pageName: `${hostname}|${section}|${page}`,
          siteSection: `${hostname}|${section}`,
          url: `${this.$location.protocol()}://${hostname}${cleanedUrl}`
        },
        siteData: {
          hostName: hostname
        },
        userData: {
          accountID: accountId || 'anonymous'
        }
      });
      this.lastAdobeTrackedState = this.$state.current.name;
    }
  }

  sendEvent(
    category: string,
    action: string,
    label: string,
    value?: any
  ): void {
    this.sendEventDelegate('nsEvent', category, action, label, value);
  }

  sendCustomEvent(
    event: string,
    category: string,
    action: string,
    label: string,
    value: any
  ): void {
    this.sendEventDelegate(event, category, action, label, value);
  }

  sendExternalId(externalId: string | number): void {
    this.track({ nsExternalId: externalId });
  }

  sendReferralCode(referralCode: string): void {
    this.track({ nsReferralCode: referralCode });
  }

  listen(): void {
    let simpleAccount: SimpleAccountTracking = {
      nsAccountType: '',
      nsReferralId: '',
      nsBank: ''
    };

    this.$rootScope.$on(this.NS_ROUTER_EVENTS.ROUTE_CHANGE_SUCCESS, () => {
      this.sendPageView();
    });

    this.acpCoreDispatcher.profile.simpleAccount.updated.onValue((account) => {
      if (account) {
        simpleAccount = {
          nsAccountType: account.program.type,
          nsReferralId: account.user.referral_code,
          nsCardAssociation: account.program.association,
          nsBank: account.program.bank
        };

        this.track(simpleAccount);
      }
    });

    this.acpCoreDispatcher.login.loggedOut.onValue(() => {
      simpleAccount = {
        nsAccountType: '',
        nsReferralId: '',
        nsCardAssociation: '',
        nsBank: ''
      };

      this.track(simpleAccount);
    });
  }

  installAdobeLaunch(adobeLaunchScriptUrl: string) {
    // Load the Adobe Launch script
    const script = document.createElement('script');
    script.src = adobeLaunchScriptUrl;
    script.async = true;
    document.head.append(script);
  }

  private sendEventDelegate(
    event: string,
    category: string,
    action: string,
    label: string,
    value: any
  ): void {
    // Sending no value will break event tracking...default to 0 to make GTM happy.
    const eventDelegate: EventDelegateTracking = {
      event,
      nsEvent_category: category,
      nsEvent_action: action,
      nsEvent_label: label,
      nsEvent_value: value || 0
    };

    this.track(eventDelegate);
  }

  private getSectionPageNamesFromState(stateName: string) {
    const segments = stateName.split('.');
    const isPublic = segments[0] === 'public';
    const section = isPublic ? `${segments[0]}.${segments[1]}` : segments[0];
    segments.shift();
    isPublic && segments.shift();
    const page = segments.join('.') || 'index';
    return [section, page] as const;
  }
}
