// @ngInject
function acpSessionTimer($log, $timeout, nsUtil, acpAuthModel) {
  var factory = {},
    sessionTimer;

  factory.listen = function() {
    acpAuthModel.info.onValue(onAuthModelValue);
  };

  function sessionTimeout() {
    $log.debug(
      'acpSessionTimer.sessionTimeout() -> user session has timed out.. logging user out'
    );

    acpAuthModel.sessionTimeout();
  }

  function cancelSessionTimer() {
    if (nsUtil.isDefined(sessionTimer)) {
      $timeout.cancel(sessionTimer);
    }
  }

  function startSessionTimer(delay) {
    sessionTimer = $timeout(sessionTimeout, delay);
  }

  function onAuthModelValue(data) {
    var delay;

    cancelSessionTimer();

    if (
      nsUtil.isDefined(data) &&
      data !== null &&
      data.hasSession === true &&
      nsUtil.isDefined(data.sessionExpiresIn) &&
      data.sessionExpiresIn !== null
    ) {
      delay = data.sessionExpiresIn;
      startSessionTimer(delay);
    }
  }

  return factory;
}

export default acpSessionTimer;
