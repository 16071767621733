// @ngInject
function acpRestrictionsClient(webapiResource, $filter, nsUtil) {
  var service = {};

  service.getRestrictionsList = webapiResource({
    method: 'GET',
    transformData: function(response) {
      response.restrictions = response.restrictions.map(function(restriction) {
        for (var i = 0; i < restriction.restriction_parameters.length; i++) {
          if (nsUtil.isString(restriction.restriction_parameters[i].value)) {
            restriction.restriction_parameters[i]._displayValue =
              restriction.restriction_parameters[i].name;
          }
          // TODO: Find out if we will ever use a number other than money here...
          if (nsUtil.isNumber(restriction.restriction_parameters[i].value)) {
            restriction.restriction_parameters[i]._displayValue = $filter(
              'currency'
            )(nsUtil.toDollars(restriction.restriction_parameters[i].value));
          }
        }
        return restriction;
      });
      return response;
    },
    path: '/v1/account/restrictions'
  });

  return service;
}

export default acpRestrictionsClient;
