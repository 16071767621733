import ng from 'angular';
import { AppsflyerPlugin } from '../plugins/appsflyer';
import { AppsflyerAttributionData } from '../plugins/appsflyer/types';
import { AcquisitionOptions } from '../types/acquisition-options';

export class AcpAttributionService {
  constructor(
    private $log: ng.ILogService,
    private acpCoreDispatcher,
    private appsflyer: AppsflyerPlugin,
    private acpSimpleAccountModel
  ) {
    'ngInject';
  }

  public listen(): void {
    this.acpCoreDispatcher.login.loggedIn.onValue(() => {
      this.$log.log('Tracking AF Logged In Event');
      this.acpSimpleAccountModel.get().then((data) => {
        this.appsflyer.setUserId(data.user.referral_code);
        this.appsflyer.handleAFEvent('login', {});
      });
    });
    this.acpCoreDispatcher.activation.registrationComplete.onValue(() => {
      this.$log.log('Tracking AF Registration In Event');
      this.acpSimpleAccountModel.get().then((data) => {
        this.appsflyer.setUserId(data.user.referral_code);
        this.appsflyer.handleAFEvent('registration', {});
      });
    });
  }

  public async getDeeplinkAcquisitionOptions(): Promise<AcquisitionOptions> {
    try {
      const appsflyerDeeplinkData: AppsflyerAttributionData = await this.appsflyer.getDeeplinkData();

      const options: AcquisitionOptions = {
        use_absolute_parameters: true,
        site_id: appsflyerDeeplinkData.af_adset,
        aid: appsflyerDeeplinkData.campaign,
        sub: appsflyerDeeplinkData.af_sub1,
        adid: appsflyerDeeplinkData.af_sub2
      };
      return options;
    } catch (e) {
      this.$log.error(e);
      return null;
    }
  }
}
