export class AcpUriService {
  public updateQueryStringParameter(
    uri: string,
    key: string,
    value: string
  ): string {
    // Checks if your key is present in a querystring
    // e.g. ?[your key]=[some value] or ?[not your key]=[some value]&[your key]=[some value]
    const re: RegExp = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    const separator: string = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
      return uri + separator + key + '=' + value;
    }
  }

  public getQueryStringParameters(uri: string): any {
    const paramMap: any = {};

    if (!uri) {
      return paramMap;
    }

    const queryString: string = uri.split('?')[1];

    if (!queryString) {
      return paramMap;
    }

    const pairs: string[] = queryString.split('&');

    pairs.forEach((pair: string) => {
      const [param, value]: string[] = pair.split('=');
      paramMap[param] = value || true;
    });

    return paramMap;
  }
}
