export class AcpApiBrandingDomainClient {
  public getApiBranding: nsWebclient.WebapiResourceInstance<
    any,
    any
  > = this.webapiResource({
    path: '/v1/branding',
    method: 'GET'
  });

  constructor(private webapiResource: nsWebclient.WebapiResourceService) {
    'ngInject';
  }
}
