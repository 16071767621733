import ng from 'angular';

//@ngInject
function acpGenericActionService($log) {
  var registeredActions = {};

  return {
    runAction: function(type, data) {
      var action = registeredActions[type];

      if (!ng.isDefined(action)) {
        $log.warn('Action has not been registered:', type);
        return;
      }

      $log.debug('Calling action:', type);
      action.perform(data);
    },

    unregisterAction: function(type) {
      if (!ng.isDefined(registeredActions[type])) {
        $log.warn(
          'Unable to unregister action:',
          type,
          'as it is not registered'
        );
        return;
      }

      $log.debug('Unregistering action:', type);
      delete registeredActions[type];
    },

    registerAction: function(def) {
      if (ng.isDefined(registeredActions[def.type])) {
        $log.warn('Action is already registered:', def.type);
        return;
      }

      $log.debug('Registering action: ', def.type);
      registeredActions[def.type] = def;
    }
  };
}

export default acpGenericActionService;
