import ng from 'angular';

// @ngInject
function acpAuthClient(
  $window,
  webapiResource,
  $http,
  nsClientUtils,
  nsStorage,
  ACP_STORAGE_KEYS
) {
  var model = {};

  function headersForBrand(brand, variantId) {
    var altNsClientHeader = webapiResource.defaults.headers.common[
      'X-NS-Client'
    ].replace(/brand=([^;]+);/, 'brand=' + brand + ';');
    return {
      'X-NS-Client': altNsClientHeader,
      'X-NS-Variant': variantId
    };
  }

  model.authPassword = webapiResource({
    method: 'POST',
    ignoreErrors: true,
    path: '/v1/authentication/password',
    withToken: false
  });

  model.verifyPassword = webapiResource({
    method: 'POST',
    ignoreErrors: true,
    path: '/v2/authentication/verifypassword',
    format: 'json'
  });

  // This makes a CORS request to the webapi password
  // endpoint on another server. The typical usage is
  // to automatically login to the correct webapi if
  // we get a brand mismatch issue upon login.
  model.crossDomainAuthPassword = function (server, brand, variantId, params) {
    var data = nsClientUtils.params(params);
    var url = 'https://' + server + '/webapi/v1/authentication/password';
    return $http
      .post(url, data, {
        headers: ng.extend(
          {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          headersForBrand(brand, variantId)
        )
      })
      .then(function (resp) {
        return resp.data;
      });
  };

  model.info = webapiResource({
    method: 'GET',
    path: '/v1/authentication',
    timeout: 5000,
    transformData: function (data, headers) {
      if (data.expires_at) {
        data._expires_at_time = webapiResource.toTime(data.expires_at);
        //added expires_at key and value in milliseconds to session storage
        nsStorage.session(
          ACP_STORAGE_KEYS.ACP_AUTH_EXPIRATION_TIME,
          data._expires_at_time
        );
      }

      if (headers.date) {
        // Take out 1 second to account for any possible delays.
        // This might not really be needed but it's okay for a session
        // to be killed a second early.
        data._session_expires_in =
          data._expires_at_time - $window.Date.parse(headers.date) - 1000;
      }

      return data;
    }
  });

  model.logout = function () {
    return webapiResource({
      method: 'GET',
      path: '/v1/authentication/logout',
      ignoreErrors: true,
      withToken: false,
      headers: {
        // We should explicitly set the token here, because the token might be replaced / deleted
        // before our interceptors get a chance to set the token on the request.
        'X-NS-Access_Token': webapiResource.getToken()
      }
    })();
  };

  return model;
}

export default acpAuthClient;
