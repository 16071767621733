export class AcpLogActionClient {
  logUserAction = this.webApiResource<void, void>({
    path: '/v2/log/action',
    method: 'POST',
    format: 'json'
  });

  constructor(private webApiResource: nsWebclient.WebapiResourceService) {
    'ngInject';
  }
}
