export default [
  'global.auth.invalid',
  'global.auth.locked',
  'global.auth.reset_password',
  'global.validation',
  'global.captcha.required',
  'global.captcha.invalid',
  'captcha_challenge.missing',
  'captcha_response.missing',
  'login.missing',
  'password.missing'
];
