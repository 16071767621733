import ng from 'angular';

// @ngInject
function acpStateRedirectToDecorator($stateProvider) {
  $stateProvider.decorator('data', function(state, parent) {
    var data = parent(state);

    if (
      data &&
      data.hasOwnProperty('permissions') &&
      data.permissions &&
      data.permissions.redirectTo
    ) {
      var oldRedirectTo = data.permissions.redirectTo;

      data.permissions.redirectTo = ng.isString(oldRedirectTo)
        ? { default: oldRedirectTo }
        : ng.extend({ default: 'dashboard' }, oldRedirectTo);

      var currentStateName = state.name;

      // Here, we modify all the redirection rules for this state. If they are simply a string,
      // We replace the string with a function that supports redirecting to URL (not a SPA state).
      // This allows redirecting to a webnew page (among other things).
      ng.forEach(data.permissions.redirectTo, function(
        redirectLocation,
        permissionName,
        redirectTo
      ) {
        if (ng.isString(redirectLocation)) {
          redirectTo[permissionName] = /* @ngInject */ function(
            $window,
            $log,
            $state
          ) {
            // If the state is registered, return it directly. Let Angular-Permission handle the redirect
            if ($state.get(redirectLocation, currentStateName)) {
              return redirectLocation;
            }

            $log.info(
              'Redirecting from state "' +
                currentStateName +
                '" to URL "' +
                redirectLocation +
                '" due to permission "' +
                permissionName +
                '"'
            );
            // We use location.replace here so the current state that we are redirecting from is not saved on the stack. Otherwise, going back would again
            // redirect into an annoying cycle.
            $window.location.replace(redirectLocation);
            return null;
          };
        }
      });
    }

    return data;
  });
}

export default acpStateRedirectToDecorator;
