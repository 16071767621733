// @ngInject
function acpScrollTop($rootScope, $window, acpCoreDispatcher) {
  var def = {};

  var scrollMainSectionToTop = function() {
    var nsArtisanLayoutView = $window.document.getElementById(
      'ns-artisan-layout-view'
    );
    var acpMainSection = $window.document.getElementById('acp-main-section');

    if (nsArtisanLayoutView) {
      nsArtisanLayoutView.scrollTop = 0;
    }
    if (acpMainSection) {
      acpMainSection.scrollTop = 0;
    }
  };

  def.listen = function() {
    $rootScope.$on('$stateChangeSuccess', scrollMainSectionToTop);
    acpCoreDispatcher.scrollTo.top.onValue(scrollMainSectionToTop);
  };

  return def;
}

export default acpScrollTop;
