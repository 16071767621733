import { AcpFeatureModel } from 'core/feature-domain';
import { BIOMETRICS_NOT_AVAILABLE_MESSAGE } from './acp-biometrics-login-domain-constants';
import { IBiometrics } from './types';

export class AcpBiometricsLoginDomainClient {
  public resource: nsWebclient.WebapiResourceInstance<
    {
      biometric_type: string;
      enrolled: boolean;
    },
    void
  > = this.webapiResource({
    path: '/v2/account/biometrics/subscription',
    method: 'POST',
    format: 'json'
  });

  private biometrics: IBiometrics;

  constructor(
    private $q: ng.IQService,
    private $window,
    private nsStorage: any,
    private nsUtil: any,
    private webapiResource: nsWebclient.WebapiResourceService,
    private ACP_STORAGE_KEYS: any,
    private acpFeatureModel: AcpFeatureModel
  ) {
    'ngInject';
    if (this.$window.plugins) {
      this.biometrics = this.$window.plugins.touchid;
    }
  }

  public async enroll(): Promise<void> {
    const biometricType = await this.getBiometricType();
    this.resource({
      biometric_type: biometricType,
      enrolled: true
    });
  }

  public async unenroll(): Promise<void> {
    const biometricType = await this.getBiometricType();
    this.resource({
      biometric_type: biometricType,
      enrolled: false
    });
  }

  public isAvailable(): ng.IPromise<boolean> {
    if (!this.biometrics) {
      return this.$q.reject(false);
    }
    const p: ng.IPromise<boolean> = this.$q((resolve, reject) => {
      this.biometrics.isAvailable(
        async (type) => {
          const isFaceAuthEnabled: boolean = await this.acpFeatureModel.hasFeature(
            'webapi:face_auth_enabled'
          );
          resolve(type === 'touch' || (type === 'face' && isFaceAuthEnabled));
        },
        () => {
          reject(false);
        }
      );
    });
    return p;
  }

  public getBiometricType(): ng.IPromise<string> {
    if (!this.biometrics) {
      return this.$q.reject(BIOMETRICS_NOT_AVAILABLE_MESSAGE);
    }
    const p: ng.IPromise<string> = this.$q((resolve, reject) => {
      this.biometrics.isAvailable((type) => {
        resolve(type);
      }, reject);
    });
    return p;
  }

  public getPassword(key: string): ng.IPromise<string> {
    if (!this.biometrics) {
      return this.$q.reject(BIOMETRICS_NOT_AVAILABLE_MESSAGE);
    }
    const p: ng.IPromise<string> = this.$q((resolve, reject) => {
      this.biometrics.verify(
        key,
        `Log in with username ${key}`,
        resolve,
        reject
      );
    });
    return p;
  }

  public save(key: string, value: string): ng.IPromise<string> {
    if (!this.biometrics) {
      return this.$q.reject(BIOMETRICS_NOT_AVAILABLE_MESSAGE);
    }
    const p: ng.IPromise<string> = this.$q((resolve, reject) => {
      this.biometrics.save(key, value, true, resolve, reject);
    });
    return p;
  }

  public has(key: string): ng.IPromise<boolean> {
    if (!this.biometrics) {
      return this.$q.reject(false);
    }
    const p: ng.IPromise<boolean> = this.$q((resolve, reject) => {
      this.biometrics.has(
        key,
        () => {
          resolve(true);
        },
        () => {
          reject(false);
        }
      );
    });
    return p;
  }

  public clear(key: string): ng.IPromise<void> {
    if (!this.biometrics) {
      return this.$q.reject();
    }
    const p: ng.IPromise<void> = this.$q((resolve, reject) => {
      this.biometrics.delete(key, resolve, () => {
        reject();
      });
    });
    return p;
  }

  public needsEnrollment(val: boolean): boolean {
    if (this.nsUtil.isDefined(val)) {
      this.nsStorage.session(
        this.ACP_STORAGE_KEYS.ACP_AUTH_MODEL_BIOMETRICS_ENROLLMENT,
        val
      );
      return val;
    }
    return !!this.nsStorage.session(
      this.ACP_STORAGE_KEYS.ACP_AUTH_MODEL_BIOMETRICS_ENROLLMENT
    );
  }
}
