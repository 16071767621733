//@ngInject
function acpImageSrcDirective(acpConfigurationService) {
  function link(scope, el, attrs) {
    function render(src) {
      el.attr('src', src);
    }

    attrs.$observe('acpImageSrc', function(src) {
      render(acpConfigurationService.imagePathForImage(src));
    });
  }

  return {
    restrict: 'A',
    link: link
  };
}

export default acpImageSrcDirective;
