// @ngInject
function acpSimpleAccountModel(
  nsUtil,
  nsDate,
  acpProfileClient,
  acpCoreDispatcher,
  content
) {
  var model = {};

  /**
   * I provide methods for interacting with web-api's /me service.
   * Since I only care about a simple account, I mess with the data.
   */

  model.get = function() {
    return acpProfileClient.get().then(formatAccountResponse);
  };

  return model;

  function formatAccountResponse(data) {
    var selectedAccount = null;
    var simpleAccount;

    nsUtil.forEach(data.accounts, function(account) {
      if (account.type === 'debit' || account.type === 'dda') {
        account._available_balance = nsUtil.toDollars(
          account.available_balance
        );
        account._posted_balance = nsUtil.toDollars(account.posted_balance);
        account._creation_date = new Date(
          nsDate.fromApi(data.user.creation_date)
        );

        if (nsUtil.isDefined(data.user.closed_date)) {
          account._closed_date = new Date(
            nsDate.fromApi(data.user.closed_date)
          );
        }

        selectedAccount = account;
      }
    });

    var primaryCardOwner = data.user.primary_card_owner || {};
    primaryCardOwner._display_name =
      (primaryCardOwner.first_name || '') +
      (primaryCardOwner.first_name && primaryCardOwner.last_name ? ' ' : '') +
      (primaryCardOwner.last_name || '');

    if (
      nsUtil.isDefined(primaryCardOwner.phone) &&
      primaryCardOwner.phone !== ''
    ) {
      primaryCardOwner._display_phone = primaryCardOwner.phone.replace(
        /[-,-]/g,
        ''
      );
    } else {
      primaryCardOwner._display_phone = '';
    }

    var business = data.business || {};

    content.setProgramType(data.program.type);

    simpleAccount = {
      business: {
        name: business.name,
        verificationLevel: business.verification_level
      },
      account: selectedAccount,
      primary_card_owner: primaryCardOwner,
      total_available_balance: nsUtil.toDollars(
        data.aggregate_available_balance
      ),
      program: data.program,
      user: data.user,
      notifications: data.notifications
    };

    acpCoreDispatcher.profile.simpleAccount.updated.set(simpleAccount);
    return simpleAccount;
  }
}

export default acpSimpleAccountModel;
