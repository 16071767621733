/**
 * See https://confluence.corp.netspend.com/display/SYSDOC/SysDoc+for+CARE+Driven+Front+End+Configuration
 */

// @ngInject
function acpFrontendConfigModel(acpFrontendConfigClient, nsProperty) {
  var configuration = nsProperty.create();
  var model = {};

  model.load = function () {
    return acpFrontendConfigClient.getConfiguration().then(configuration.set);
  };

  model.get = function (key, defaultValue) {
    var config = configuration.getValue();
    if (config && config[key]) {
      return config[key];
    } else {
      return defaultValue;
    }
  };

  // Keep alphabetized!
  model.keys = {
    ACQUISITION_URL: 'acquisition.url',
    BENEFITS_CENTER_PHONE_PROTECTION_NUMBER: 'benefits.phoneprotection',
    CASHEDGE_BASE_URL: 'cashedge.url',
    DDA_UPGRADE_SURVEY_SPECIFY_OTHER: 'dda.upgrade.survey.specify.other',
    DEBIT_CARD_TRANSFER_LIMITS: 'debitCardTransfer.limits',
    EXTERNAL_HANDOFF_URL: 'external.handoff.url',
    FACEBOOK_SDK_APP_ID: 'facebook.sdk.app-id',
    FACEBOOK_SDK_APP_VERSION: 'facebook.sdk.app-version',
    FACEBOOK_SDK_URL: 'facebook.sdk.url',
    GOOGLE_MAP_API_KEY: 'google.maps.api.key',
    HIDE_APPBAR_LOGO: 'hide.appbar.logo',
    ID_MONITORING_URL: 'idMonitoring.url',
    ID_CHECK_TIMEOUT_SECONDS: 'id.check.timeout.seconds',
    IS_EULA_ENABLED: 'eula.enabled',
    OOBA_CODE_PATTERN: 'ooba.code.pattern',
    PICK_YOUR_PLASTIC_IMAGE_PATH: 'pickyourplastic.imagepath',
    RAF_AQUISITION_URL: 'raf.acquisition-url',
    RAF_EMAIL_SITE_ID: 'raf.email.site-id',
    RAF_FACEBOOK_SITE_ID: 'raf.facebook.site-id',
    RAF_SHARE_URL_SITE_ID: 'raf.share-url.site-id',
    RAF_TWITTER_SITE_ID: 'raf.twitter.site-id',
    RENT_TRACK_ENROLL_URL: 'rent.track.enrollment.url',
    RENT_TRACK_LOGIN_URL: 'rent.track.login.url',
    STOREFINDER_DISPLAY_WU_PROMO_CODE: 'storefinder.display.wu.promocode',
    STOREFINDER_DISPLAY_WU_PROMO_CODE_LOAD_METHOD_WU:
      'storefinder.display.wu.promocode.load_method.wu',
    SUNSET_TENANT_START_DATE: 'sunset-tenant.start-date',
    TWITTER_SDK_URL: 'twitter.sdk.url',
    WU_MIN_SEND_LIMIT: 'loadLimit.wu.out.minSingleLoad'
  };

  return model;
}

export default acpFrontendConfigModel;
