// Need a reference of all notifications/interrupts?
// https://confluence.corp.netspend.com/display/proddev/Netspend-PayPal+Comparison+-+Notifications-Interrupts
// Adding a new triggered widget?
// - If it's a notification, make sure to update
//   /components/notification-center/acp-notification-center-item-ctrl.js
//   This is so webpack can do proper code splitting and lazy loading
//   of the appropriate widget templates.
// 'TriggeredWidgetId': {
//   type: 'notification|widget',
//   notificationTemplatePath: '',
//   hoverCardTemplatePath: '',
//   interstitialTemplatePath: '',
//   priority: '',
//   component: 'acp.widget.TriggeredWidgetId',
// }

type Widget =
  | {
      type: 'notification';
      notificationTemplatePath: string;
      component: string;
      theme?: string;
    }
  | {
      type: 'interstitial';
      interstitialTemplatePath: string;
      component: string;
      theme?: string;
    };

const acpWidgetDefinitions: { [name: string]: Widget } = {
  CardlyticsInfoNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/CardlyticsInfoNotification/templates/acp-cardlytics-info-notification.ng.html',
    component: 'acp.widget.CardlyticsInfoNotification'
  },
  PayPalPaymentsNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/PayPalPaymentsNotification/templates/acp-pay-pal-payments-notification.ng.html',
    component: 'acp.widget.PayPalPaymentsNotification'
  },
  PayBackRewardsNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/PayBackRewardsNotification/templates/acp-pay-back-rewards-notification.ng.html',
    component: 'acp.widget.PayBackRewardsNotification'
  },
  DDInfoNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/DDInfoNotification/templates/acp-dd-info-notification.ng.html',
    component: 'acp.widget.DDInfoNotification'
  },
  DDInfoInterstitialNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/DDInfoNotification/templates/acp-dd-info-notification.ng.html',
    component: 'acp.widget.DDInfoNotification'
  },
  CustomCardNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/CustomCardNotification/templates/acp-custom-card-notification.ng.html',
    component: 'acp.widget.CustomCardNotification'
  },
  ReferAFriendNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/ReferAFriendNotification/templates/acp-refer-a-friend-notification.ng.html',
    component: 'acp.widget.ReferAFriendNotification'
  },
  BillPayPromoNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/BillPayPromoNotification/templates/acp-bill-pay-promo-notification.ng.html',
    component: 'acp.widget.BillPayPromoNotification'
  },
  BankTransferInfoNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/BankTransferInfoNotification/templates/acp-bank-transfer-info-notification.ng.html',
    component: 'acp.widget.BankTransferInfoNotification'
  },
  AnytimeAlertsGeneralNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/AnytimeAlertsGeneralNotification/templates/acp-anytime-alerts-general-notification.ng.html',
    component: 'acp.widget.AnytimeAlertsGeneralNotification'
  },
  AdditionalCardPromoNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/AdditionalCardPromoNotification/templates/acp-additional-card-promo-notification.ng.html',
    component: 'acp.widget.AdditionalCardPromoNotification'
  },
  SevenElevenReloadNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/SevenElevenReloadNotification/templates/acp-seven-eleven-reload-notification.ng.html',
    component: 'acp.widget.SevenElevenReloadNotification'
  },
  VirtualCardsSecurityNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/VirtualCardsSecurityNotification/templates/acp-virtual-cards-security-notification.ng.html',
    component: 'acp.widget.VirtualCardsSecurityNotification'
  },
  MoneyGramExpressPayNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/MoneyGramExpressPayNotification/templates/acp-money-gram-express-pay-notification.ng.html',
    component: 'acp.widget.MoneyGramExpressPayNotification'
  },
  PostLoginSurveyNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/PostLoginSurveyNotification/templates/acp-post-login-survey-notification.ng.html',
    component: 'acp.widget.PostLoginSurveyNotification'
  },
  NetspendMCLGANotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/NetspendMCLGANotification/templates/acp-netspend-mclga-notification.ng.html',
    component: 'acp.widget.NetspendMCLGANotification'
  },
  NetSpendOnlineRAFNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/NetSpendOnlineRAFNotification/templates/acp-net-spend-online-raf-notification.ng.html',
    component: 'acp.widget.NetSpendOnlineRAFNotification'
  },
  MobileAppNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/MobileAppNotification/templates/acp-mobile-app-notification.ng.html',
    component: 'acp.widget.MobileAppNotification'
  },
  SecurityNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/SecurityNotification/templates/acp-security-notification.ng.html',
    component: 'acp.widget.SecurityNotification'
  },
  UpdateContactInfoReminderNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/UpdateContactInfoReminderNotification/templates/acp-update-contact-info-reminder-notification.ng.html',
    component: 'acp.widget.UpdateContactInfoReminderNotification'
  },
  SendMoneyNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/SendMoneyNotification/templates/acp-send-money-notification.ng.html',
    component: 'acp.widget.SendMoneyNotification'
  },
  CDDAActivateReminderNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/CDDAActivateReminderNotification/templates/acp-cdda-activate-reminder-notification.ng.html',
    component: 'acp.widget.CDDAActivateReminderNotification'
  },
  Transact711ReloadNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/Transact711ReloadNotification/templates/acp-transact711-reload-notification.ng.html',
    component: 'acp.widget.Transact711ReloadNotification'
  },
  FNAActivationReminderNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/FNAActivationReminderNotification/templates/acp-fna-activation-reminder-notification.ng.html',
    component: 'acp.widget.FNAActivationReminderNotification'
  },
  NetspendSmallBizMastercardEasySavingsNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/NetspendSmallBizMastercardEasySavingsNotification/templates/acp-netspend-small-biz-mc-easy-savings-notification.ng.html',
    component: 'acp.widget.NetspendSmallBizMastercardEasySavingsNotification'
  },
  AcpAlbertsonsRewardsLearnMoreNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/AcpAlbertsonsPointsLearnMoreNotification/templates/acp-albertsons-points-learn-more-notification.ng.html',
    component: 'acp.widget.AcpAlbertsonsRewardsLearnMoreNotification'
  },
  NSTax2021LegalParentNetspendSweepsNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/NSTax2021LegalParentNetspendSweepsNotification/templates/ns-tax-2021-legal-parent-netspend-sweeps-notification.ng.html',
    component: 'acp.widget.NSTax2021LegalParentNetspendSweepsNotification'
  },
  DD100DiningNotificationMay2019Brinks: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/DD100DiningNotificationMay2019Brinks/templates/acp-dd-100-dining-notification-may-2019-brinks.ng.html',
    component: 'acp.widget.DD100DiningNotificationMay2019Brinks'
  },
  AceFAPSwipe995MsgNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/AceFAPSwipe995MsgNotification/templates/acp-ace-fap-swipe-995-msg-notification.ng.html',
    component: 'acp.widget.AceFAPSwipe995MsgNotification'
  },
  AceFAPSwipe500MsgNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/AceFAPSwipe500MsgNotification/templates/acp-ace-fap-swipe-500-msg-notification.ng.html',
    component: 'acp.widget.AceFAPSwipe500MsgNotification'
  },
  SkylightPayrollFirstNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/SkylightPayrollFirstNotification/templates/acp-skylight-payroll-first-notification.ng.html',
    component: 'acp.widget.SkylightPayrollFirstNotification'
  },
  SkylightPayrollFirstConditionalNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/SkylightPayrollFirstConditionalNotification/templates/acp-skylight-payroll-first-conditional-notification.ng.html',
    component: 'acp.widget.SkylightPayrollFirstConditionalNotification'
  },
  BrinksPayrollFirstUnverifiedNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/BrinksPayrollFirstUnverifiedNotification/templates/acp-brinks-payroll-first-unverified-notification.ng.html',
    component: 'acp.widget.BrinksPayrollFirstUnverifiedNotification'
  },
  BrinksPayrollFirstConditionalNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/BrinksPayrollFirstConditionalNotification/templates/acp-brinks-payroll-first-conditional-notification.ng.html',
    component: 'acp.widget.BrinksPayrollFirstConditionalNotification'
  },
  MonthlyHighlightsNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/MonthlyHighlightsNotification/templates/acp-monthly-highlights-notification.ng.html',
    component: 'acp.widget.MonthlyHighlightsNotification'
  },
  'AccountCenterWelcomeInterstitial4.0': {
    type: 'interstitial',
    interstitialTemplatePath:
      'widgets/interstitials/AccountCenterRedesignInterstitial/templates/acp-account-center-redesign-interstitial.ng.html',
    component: 'acp.widget.AccountCenterRedesignInterstitial'
  },
  'PayPalPaymentsInterstitial4.0': {
    type: 'interstitial',
    interstitialTemplatePath:
      'widgets/interstitials/PayPalPaymentsInterstitial/templates/acp-pay-pal-payments-interstitial.ng.html',
    component: 'acp.widget.PayPalPaymentsInterstitial'
  },
  'DDInfoInterstitial4.0': {
    type: 'interstitial',
    interstitialTemplatePath:
      'widgets/interstitials/DDInfoInterstitial/templates/acp-dd-info-interstitial.ng.html',
    component: 'acp.widget.DDInfoInterstitial'
  },
  'AnytimeAlertsGeneralInterstitial4.0': {
    type: 'interstitial',
    interstitialTemplatePath:
      'widgets/interstitials/AnytimeAlertsGeneralInterstitial/templates/acp-anytime-alerts-general-interstitial.ng.html',
    component: 'acp.widget.AnytimeAlertsGeneralInterstitial'
  },
  'ReferAFriendInterstitial4.0': {
    type: 'interstitial',
    interstitialTemplatePath:
      'widgets/interstitials/ReferAFriendInterstitial/templates/acp-refer-a-friend-interstitial.ng.html',
    component: 'acp.widget.ReferAFriendInterstitial'
  },
  'ElectronicDisclosureAgreementInterstitial4.0': {
    type: 'interstitial',
    interstitialTemplatePath:
      'widgets/interstitials/ElectronicDisclosureAgreementInterstitial/templates/acp-electronic-disclosure-agreement-interstitial.ng.html',
    component: 'acp.widget.ElectronicDisclosureAgreementInterstitial'
  },
  'DemandDepositAccountUpgradeInterstitial4.0': {
    type: 'interstitial',
    theme: 'light-theme',
    interstitialTemplatePath:
      'widgets/interstitials/DemandDepositAccountUpgradeInterstitial/templates/acp-demand-deposit-account-upgrade-interstitial.ng.html',
    component: 'acp.widget.DemandDepositAccountUpgradeInterstitial'
  },
  PaybackPointsHebInterstitial: {
    type: 'interstitial',
    interstitialTemplatePath:
      'widgets/interstitials/PaybackPointsHebInterstitial/templates/payback-points-heb-interstitial.ng.html',
    component: 'acp.widget.PaybackPointsHebInterstitial'
  },
  SkylightPayroll1stReasonablyVerifiedInterstitial: {
    type: 'interstitial',
    interstitialTemplatePath:
      'widgets/interstitials/SkylightPayroll1stReasonablyVerifiedInterstitial/templates/skylight-payroll-1st-reasonably-verified-interstitial.ng.html',
    component: 'acp.widget.SkylightPayroll1stReasonablyVerifiedInterstitial'
  },
  SkylightPayroll1stConditionalInterstitial: {
    type: 'interstitial',
    interstitialTemplatePath:
      'widgets/interstitials/SkylightPayroll1stConditionalInterstitial/templates/skylight-payroll-1st-conditional-interstitial.ng.html',
    component: 'acp.widget.SkylightPayroll1stConditionalInterstitial'
  },
  RentTrackNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/RentTrackNotification/templates/acp-renttrack-notification.ng.html',
    component: 'acp.widget.RentTrackNotification'
  },
  GPRSpeedyRewardsLoyaltyEnrollNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/GPRSpeedyRewardsLoyaltyEnrollNotification/templates/acp-gpr-speedy-rewards-loyalty-enroll-notification.ng.html',
    component: 'acp.widget.GPRSpeedyRewardsLoyaltyEnrollNotification'
  },
  GPRSpeedyRewardsLoyaltyConvertNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/GPRSpeedyRewardsLoyaltyConvertNotification/templates/acp-gpr-speedy-rewards-loyalty-convert-notification.ng.html',
    component: 'acp.widget.GPRSpeedyRewardsLoyaltyConvertNotification'
  },
  BrinksPayroll1stConditionalInterstitial: {
    type: 'interstitial',
    interstitialTemplatePath:
      'widgets/interstitials/BrinksPayroll1stConditionalInterstitial/templates/brinks-payroll-1st-conditional-interstitial.ng.html',
    component: 'acp.widget.BrinksPayroll1stConditionalInterstitial'
  },
  BrinksPayroll1stUnverifiedInterstitial: {
    type: 'interstitial',
    interstitialTemplatePath:
      'widgets/interstitials/BrinksPayroll1stUnverifiedInterstitial/templates/brinks-payroll-1st-unverified-interstitial.ng.html',
    component: 'acp.widget.BrinksPayroll1stUnverifiedInterstitial'
  },
  AcpDemandDepositAccountUpgradeNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/AcpDemandDepositAccountUpgradeNotification/templates/demand-deposit-account-upgrade-notification.ng.html',
    component: 'acp.widget.AcpDemandDepositAccountUpgradeNotification'
  },
  DigitalWalletNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/DigitalWalletNotification/templates/acp-digital-wallet-notification.ng.html',
    component: 'acp.widget.DigitalWalletNotification'
  },
  AcpDemandDepositAccountUpgradeACENotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/AcpDemandDepositAccountUpgradeACENotification/templates/demand-deposit-account-upgrade-ace-notification.ng.html',
    component: 'acp.widget.AcpDemandDepositAccountUpgradeACENotification'
  },
  DD100DiningJune2019BrinksNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/DD100DiningJune2019BrinksNotification/templates/acp-dd-june-2019-brinks-notification.ng.html',
    component: 'acp.widget.DD100DiningJune2019BrinksNotification'
  },
  SkylightPortabilityNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/SkylightPortabilityNotification/templates/acp-skylight-portability-notification.ng.html',
    component: 'acp.widget.SkylightPortabilityNotification'
  },
  SkylightUpdateContactInfoNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/SkylightUpdateContactInfoNotification/templates/acp-skylight-update-contact-info-notification.ng.html',
    component: 'acp.widget.SkylightUpdateContactInfoNotification'
  },
  NSAllAccessLifecycleDirectDepositNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/NSAllAccessLifecycleDirectDepositNotification/templates/ns-all-access-lifecycle-direct-deposit-notification.ng.html',
    component: 'acp.widget.NSAllAccessLifecycleDirectDepositNotification'
  },
  NSAllAccessLifecycleMobileAppNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/NSAllAccessLifecycleMobileAppNotification/templates/acp-ns-all-access-lifecycle-mobile-app-notification.ng.html',
    component: 'acp.widget.NSAllAccessLifecycleMobileAppNotification'
  },
  AcpN10MclMccOfferNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/AcpN10MclMccOfferNotification/templates/acp-n10-mcl-mcc-offer-notification.ng.html',
    component: 'acp.widget.AcpN10MclMccOfferNotification'
  },
  NSAllAccessLifecyclePaybackRewardsNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/NSAllAccessLifecyclePaybackRewardsNotification/templates/ns-all-access-lifecycle-payback-rewards-notification.ng.html',
    component: 'acp.widget.NSAllAccessLifecyclePaybackRewardsNotification'
  },
  AcpSkylightoneSddaUpgradeNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/AcpSkylightoneSddaUpgradeNotification/templates/acp-skylightone-sdda-upgrade-notification.ng.html',
    component: 'acp.widget.AcpSkylightoneSddaUpgradeNotification'
  },
  SpendTrackerNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/SpendTrackerNotification/templates/acp-spend-tracker-notification.ng.html',
    component: 'acp.widget.SpendTrackerNotification'
  },
  PaycardGetPaidFasterNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/PaycardGetPaidFasterNotification/templates/paycard-get-paid-faster-notification.ng.html',
    component: 'acp.widget.PaycardGetPaidFasterNotification'
  },
  Spend400Get20For2021Notification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/Spend400Get20For2021Notification/templates/acp-spend-400-get-20-for-2021-notification.ng.html',
    component: 'acp.widget.Spend400Get20For2021Notification'
  },
  MultibrandTaxAnnual2023Notification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/MultibrandTaxAnnualNotification/templates/acp-multibrand-tax-annual-notification.ng.html',
    component: 'acp.widget.MultibrandTaxAnnualNotification'
  },
  DirectDepositTrackerInterstitial: {
    type: 'interstitial',
    interstitialTemplatePath:
      'widgets/interstitials/DirectDepositTrackerInterstitial/templates/direct-deposit-tracker-interstitial.ng.html',
    component: 'acp.widget.DirectDepositTrackerInterstitial'
  },
  NetspendGPROACVehicleTestFundInterstitial: {
    type: 'interstitial',
    interstitialTemplatePath:
      'widgets/interstitials/NetspendGPROACVehicleTestFundInterstitial/templates/netspend-gpr-oac-vehicle-test-fund-interstitial.ng.html',
    component: 'acp.widget.NetspendGPROACVehicleTestFundInterstitial'
  },
  StimulusComm2ndRoundNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/StimulusComm2ndRoundNotification/templates/stimulus-comm-2nd-round-notification.ng.html',
    component: 'acp.widget.StimulusComm2ndRoundNotification'
  },
  TurnOffSmsAlertInterstitial: {
    type: 'interstitial',
    interstitialTemplatePath:
      'widgets/interstitials/TurnOffSmsAlertInterstitial/templates/turn_off_sms_alert_interstitial.ng.html',
    component: 'acp.widget.TurnOffSmsAlertInterstitial'
  },
  UpgradeAnytimeAlertsInterstitial: {
    type: 'interstitial',
    interstitialTemplatePath:
      'widgets/interstitials/UpgradeAnytimeAlertsInterstitial/templates/upgrade-anytime-alerts-interstitial.ng.html',
    component: 'acp.widget.UpgradeAnytimeAlertsInterstitial'
  },
  EnrollIntoAnytimeAlertInterstitial: {
    type: 'interstitial',
    interstitialTemplatePath:
      'widgets/interstitials/EnrollIntoAnytimeAlertInterstitial/templates/enroll-into-anytime-alert-interstitial.ng.html',
    component: 'acp.widget.EnrollIntoAnytimeAlertInterstitial'
  },
  'AnytimeAlertsGeneralInterstitialRedesign4.0': {
    type: 'interstitial',
    interstitialTemplatePath:
      'widgets/interstitials/AnytimeAlertsRedesignInterstitial/templates/acp-anytime-alerts-redesign-interstitial.ng.html',
    component: 'acp.widget.AnytimeAlertsRedesignInterstitial'
  },
  DebitCardTransferInterstitial: {
    type: 'interstitial',
    interstitialTemplatePath:
      'widgets/interstitials/DebitCardTransferInterstitial/templates/debit-card-transfer-interstitial.ng.html',
    component: 'acp.widget.DebitCardTransferInterstitial'
  },
  DebitCardTransferNotification: {
    type: 'notification',
    notificationTemplatePath:
      'widgets/notifications/DebitCardTransferNotification/templates/debit-card-transfer-notification.ng.html',
    component: 'acp.widget.DebitCardTransferNotification'
  },
  DeepBlueDDAOfferInterstitial: {
    type: 'interstitial',
    interstitialTemplatePath:
      'widgets/interstitials/DeepBlueDDAOfferInterstitial/templates/deepblue-dda-offer-interstitial.ng.html',
    component: 'acp.widget.DeepBlueDDAOfferInterstitial'
  }
};

export default acpWidgetDefinitions;
