// Need to store token to session
// @ngInject
function webapiResourceDecorator($provide) {
  // Decorate $rootScope's prototype so all $scope instances have these methods.
  $provide.decorator(
    'webapiResource',
    /*@ngInject*/ function($delegate, nsStorage, ACP_STORAGE_KEYS) {
      var accessToken = ACP_STORAGE_KEYS.WEB_API_RESOURCE;
      var webapiResource = $delegate;

      webapiResource.setToken = function(token) {
        nsStorage.session(accessToken, token);
      };

      webapiResource.getToken = function() {
        return nsStorage.session(accessToken);
      };

      return webapiResource;
    }
  );
}

export default webapiResourceDecorator;
