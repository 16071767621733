export default {
  EXPENSE_MGMT: [
    {
      name: 'Sole Proprietorship',
      value: '1'
    },
    {
      name: 'Limited Liability Company',
      value: '2'
    },
    {
      name: 'Partnership',
      value: '3'
    },
    {
      name: 'Limited Liability Partnership',
      value: '4'
    },
    {
      name: 'Limited Partnership',
      value: '5'
    },
    {
      name: 'C Corporation',
      value: '6'
    },
    {
      name: 'S Corporation',
      value: '7'
    },
    {
      name: 'Non-Profit',
      value: '8'
    }
  ]
};
