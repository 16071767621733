import ng from 'angular';
import NsPropertyService = nsUtils.NsPropertyService;
import { AcpAnalytics } from '../../services/acp-analytics';
import { AppsflyerCordovaPlugin } from './appsflyer-cordova-plugin';
import { AppsflyerPlugin } from './appsflyer-plugin';
import { AppsflyerWebPlugin } from './appsflyer-web-plugin';
import { NoOpAppsflyerPlugin } from './no-op-appsflyer-plugin';
import { Appsflyer } from './types';

export function appsflyerPluginFactory(
  $q: ng.IQService,
  $log: ng.ILogService,
  $window: ng.IWindowService,
  nsProperty: NsPropertyService,
  acpConfigurationService: any,
  acpAnalytics: AcpAnalytics,
  $timeout: ng.ITimeoutService
): AppsflyerPlugin {
  'ngInject';

  if (!acpConfigurationService.isAppsFlyerEnabled()) {
    $log.debug(
      'Constructing NoOpAppsflyerPlugin since Appsflyer is not enabled!'
    );
    return new NoOpAppsflyerPlugin();
  }

  if ($window.plugins && $window.plugins.appsFlyer) {
    $log.debug('Constructing Appsflyer Cordova Plugin as it is available');

    return new AppsflyerCordovaPlugin(
      $q,
      $log,
      $window.plugins.appsFlyer as Appsflyer,
      acpConfigurationService,
      nsProperty,
      $timeout
    );
  } else {
    $log.debug('Constructing Appsflyer Web Plugin');
    return new AppsflyerWebPlugin(acpAnalytics);
  }
}
