// @ngInject
function acpCvcAuthenticationModel(
  acpCoreDispatcher,
  webapiResource,
  acpAuthClient,
  $q
) {
  var model = {},
    requestCheckCvc,
    isCvcAuthed = false;

  requestCheckCvc = webapiResource({
    path: '/v1/authentication/cvc',
    ignoreErrors: true,
    method: 'POST'
  });

  model.isCvcAuthed = function() {
    return isCvcAuthed;
  };

  model.get = function() {
    var promise;

    promise = acpAuthClient.info().then(function(data) {
      isCvcAuthed = data.cvc;

      // This lets signal listeners get the updated cvc auth status
      acpCoreDispatcher.authenticate.isCvcUpdated.emit(data);

      // This returns the result to the .then call
      return data;
    });

    return promise;
  };

  model.authenticate = function(params) {
    var dfd = $q.defer();

    if (model.isCvcAuthed() !== true) {
      requestCheckCvc(params).then(
        function() {
          isCvcAuthed = true;
          acpCoreDispatcher.authenticate.isCvcUpdated.emit({ cvc: true });
          if (params && params.finally) params.finally();
          dfd.resolve();
        },
        function(error) {
          acpCoreDispatcher.authenticate.cvcAuth.failed.emit(error);
          if (params && params.finally) params.finally();
          dfd.reject(error);
        }
      );
    } else {
      dfd.resolve();
    }

    return dfd.promise;
  };

  return model;
}

export default acpCvcAuthenticationModel;
