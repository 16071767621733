// @ngInject
function acpCordovaEventsClient($window, $log, acpDeviceService, acpAuthModel) {
  function onResume() {
    $log.debug('RESUME');
    acpAuthModel.getInfo().then(angular.noop, onTimeout);
  }

  function onTimeout(response) {
    if (response && response.error === 'auth.expired') {
      $log.debug('SESSION TIMED OUT');
      acpAuthModel.sessionTimeout();
    }
  }

  function onDeviceReady() {
    $log.debug('DEVICE READY');
  }

  return {
    listen: function() {
      if (acpDeviceService.isCordova()) {
        $window.document.addEventListener('resume', onResume);
        $window.document.addEventListener('deviceready', onDeviceReady);
      }
    }
  };
}

export default acpCordovaEventsClient;
