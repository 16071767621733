// @ngInject
function qDecorator($provide) {
  $provide.decorator(
    '$q',
    /*@ngInject*/ function($delegate) {
      var $q = $delegate;
      $q.resolve = function(val) {
        var dfd = $q.defer();
        dfd.resolve(val);
        return dfd.promise;
      };

      return $q;
    }
  );
}

export default qDecorator;
