function genericAction() {
  /**
   * Definition Object that should be used as the interface
   * between the AcpGenericActionService and the different
   * generic action implementations.
   *
   * @param type
   * @param action
   * @constructor
   * @interface
   */
  function GenericActionDef(type, action) {
    /**
     * The type of action
     *
     * @see ACP_GENERIC_ACTION_TYPES
     */
    this.type = type;

    /**
     * Proxies the call to the action
     * implementation
     *
     * @param data - any, the action should know
     * how to handle the expected data
     */
    this.perform = function(data) {
      action(data);
    };
  }

  return GenericActionDef;
}

export default genericAction;
