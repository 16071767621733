//@ngInject
function acpInAppBrowserModel($log, nsSignal, acpCordovaLinksClient) {
  function AcpInAppBrowser(targetUrl, options) {
    var browserRef = acpCordovaLinksClient.openExternalLink(targetUrl, options);

    // These used to be surrounded by a with(browserRef) { ... } statement, but
    // as of the latest iOS 9 and iOS 10, the issues could not be reproduced.
    // The were then removed due to lack of support inside of 'use strict' mode
    // required within ES6.
    browserRef.addEventListener('loadstart', onLoadStart);
    browserRef.addEventListener('loadstop', onLoaded);
    browserRef.addEventListener('loaderror', onFailed);
    browserRef.addEventListener('exit', onFailed);

    var loadedSignal = nsSignal.create();
    var failedSignal = nsSignal.create();
    var exitSignal = nsSignal.create();

    var loaded = false;
    var eventQueue = [];

    function onLoadStart() {
      $log.info('InAppBrowser Loading');
      loaded = false;
    }

    function onLoaded() {
      $log.info('InAppBrowser Loaded');

      loaded = true;
      processEvents();
      loadedSignal.emit();
    }

    function onFailed(err) {
      loaded = false;

      if (err.type === 'exit') {
        onExit();
      } else {
        $log.error(
          'InAppBrowser Failed to open:',
          targetUrl,
          'error is:',
          err.message
        );
        failedSignal.emit(err);
      }
    }

    function onExit() {
      $log.info('InAppBrowser exit');

      // Wipe the queue
      eventQueue = [];

      if (browserRef) {
        browserRef.removeEventListener('loadstart', onLoadStart);
        browserRef.removeEventListener('loadstop', onLoaded);
        browserRef.removeEventListener('loaderror', onFailed);
        browserRef.removeEventListener('exit', onExit);

        browserRef = null;
      }
      exitSignal.emit();
    }

    function processEvents() {
      if (eventQueue.length === 0 || !browserRef) {
        return;
      }

      var event = eventQueue.shift();
      while (event) {
        event();
        event = eventQueue.length === 0 ? false : eventQueue.shift();
      }
    }

    function invokeLater(event) {
      if (loaded) {
        event();
      } else {
        eventQueue.push(event);
      }
    }

    this.onLoaded = function(callback) {
      loadedSignal.onValue(callback);
    };

    this.onFailed = function(callback) {
      failedSignal.onValue(callback);
    };

    this.onExit = function(callback) {
      exitSignal.onValue(callback);
    };

    this.attachFunction = function(functionName, callback) {
      invokeLater(function() {
        browserRef.attachFunction(functionName, callback);
      });
    };

    this.executeScript = function(js, callback) {
      invokeLater(function() {
        browserRef.executeScript({ code: js }, callback);
      });
    };

    this.close = function() {
      if (browserRef) {
        browserRef.removeEventListener('loadstart', onLoadStart);
        browserRef.removeEventListener('loadstop', onLoaded);
        browserRef.removeEventListener('loaderror', onFailed);
        browserRef.removeEventListener('exit', onExit);

        browserRef.close();
        browserRef = null;
      }
    };

    this.isLoaded = function() {
      return loaded;
    };
  }

  return {
    create: function(targetUrl, options) {
      return new AcpInAppBrowser(targetUrl, options);
    }
  };
}

export default acpInAppBrowserModel;
