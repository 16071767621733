// @ngInject
function acpWidgetService($log, $q, nsUtil, AcpWidgetDefinitions) {
  var service = {};

  function hasTemplatePath(widgetConfig) {
    return (
      nsUtil.isDefined(widgetConfig.notificationTemplatePath) ||
      nsUtil.isDefined(widgetConfig.hoverCardTemplatePath) ||
      nsUtil.isDefined(widgetConfig.interstitialTemplatePath)
    );
  }

  function isAPIDrivenWidget(widget) {
    return nsUtil.isDefined(widget.data) && nsUtil.isDefined(widget.data.type);
  }

  service.scrubWidgets = function(widgets) {
    var scrubbedWidgets;

    scrubbedWidgets = widgets.filter(function(widget) {
      if (isAPIDrivenWidget(widget)) {
        return true;
      } else {
        var widgetConfig = AcpWidgetDefinitions[widget.id];
        if (nsUtil.isUndefined(widgetConfig)) {
          // Missing configuration for returned widget
          $log.error(
            'AcpWidgetService.scrubWidgets -> Missing config for returned widget',
            widget.id
          );
          return false;
        } else if (
          !hasTemplatePath(widgetConfig) ||
          nsUtil.isUndefined(widgetConfig.component)
        ) {
          // Bad configuration.. missing a property
          $log.error(
            'AcpWidgetService.scrubWidgets -> Missing property in widget configuration',
            widget.id
          );
          return false;
        }

        return true;
      }
    });

    // Sort by internal priority override if they exist.
    scrubbedWidgets.sort(function(a, b) {
      if (
        nsUtil.isDefined(AcpWidgetDefinitions[a.id]) &&
        nsUtil.isDefined(AcpWidgetDefinitions[b.id])
      ) {
        var aPriority = nsUtil.isDefined(AcpWidgetDefinitions[a.id].priority)
          ? AcpWidgetDefinitions[a.id].priority
          : 0;
        var bPriority = nsUtil.isDefined(AcpWidgetDefinitions[b.id].priority)
          ? AcpWidgetDefinitions[b.id].priority
          : 0;

        return bPriority - aPriority;
      }
    });

    scrubbedWidgets.forEach(function(widget) {
      if (!widget.data) {
        widget.configData = AcpWidgetDefinitions[widget.id];
      }
    });

    return scrubbedWidgets;
  };

  service.suppress = function(widgetClient, widget) {
    if (nsUtil.isDefined(widget.suppressible) && widget.suppressible === true) {
      return widgetClient.suppress({
        ':id': widget.id
      });
    } else {
      return $q.reject();
    }
  };

  service.view = function(widgetClient, widget, trigger) {
    return widgetClient.view({
      ':id': widget.id,
      trigger: nsUtil.isDefined(trigger) ? trigger : 'dashboard'
    });
  };

  service.respond = function(widgetClient, widget, response) {
    return widgetClient.respond({
      ':id': widget.id,
      response: nsUtil.isDefined(response) ? response : 'interacted'
    });
  };

  return service;
}

export default acpWidgetService;
