import ng from 'angular';
import { AcpDeviceFingerprintService } from './types/acp-device-fingerprint-service';
import { IovationMobileSdk } from './types/iovation-mobile-sdk';

export class AcpIovationMobileService implements AcpDeviceFingerprintService {
  private iovation: IovationMobileSdk;
  constructor(private $q: ng.IQService, private $window: ng.IWindowService) {
    'ngInject';
    if (this.$window.plugins) {
      this.iovation = this.$window.plugins.iovation;
    }
  }
  public getFingerprint(): ng.IPromise<string> {
    if (!this.iovation) {
      return this.$q.reject('plugin.not_available');
    }
    const p: ng.IPromise<string> = this.$q((resolve, reject) => {
      this.iovation.blackbox(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
    return p;
  }
}
