export class AcpPreOnboardingConfigurationService {
  constructor(
    private nsConfig: any,
    private nsPermissions: nsUtils.NsPermissionsService,
    private acpDeviceService: any
  ) {
    'ngInject';
  }

  /*
    Valid 'preOnboardingEnabled' config values in tokens.js:
      -   true: Always show pre-onboarding.
      - 'CARE': Check 'webapi:show_pre_onboarding' CARE rule to determine whether or not to show. (if preonboarding is in beta period)
      - 'mobile': Show the pre-onboarding if displayed in a mobile environment only. Do not show on web. (this is useful for
         hosted-mobile apps where they are using the web config, but do not want to display preonboarding for web user, only mobile.
      -  false: Definitely do not show.
  */
  public async isPreOnboardingEnabled(): Promise<boolean> {
    const preOnboardingEnabledConfigValue = this.nsConfig.get(
      'preOnboardingEnabled'
    );

    switch (preOnboardingEnabledConfigValue) {
      case true:
        return true;

      case false:
        return false;

      case 'mobile':
        return this.acpDeviceService.isCordova();

      case 'CARE':
        return await this.nsPermissions.requestPermission('showPreOnboarding');

      default:
        throw new Error(
          `Invalid 'preOnboardingEnabled' config value for current tenant.`
        );
    }
  }
}
