// @ngInject
function acpRouterHistoryService(acpDeviceService, $window) {
  var _back = acpDeviceService.isCordova()
    ? $window.navigator.app.backHistory
    : $window.history.back;

  return {
    back: _back
  };
}

export default acpRouterHistoryService;
