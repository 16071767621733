// @ngInject
function acpStatusClient(webapiResource) {
  var def = {},
    checkStatus;

  checkStatus = webapiResource({
    method: 'GET',
    withToken: false,
    path: '/v1/status',
    timeout: 5000
  });

  def.check = function() {
    return checkStatus().then(
      function(data) {
        return data.success;
      },
      function() {
        return false;
      }
    );
  };

  return def;
}

export default acpStatusClient;
