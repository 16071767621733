import { Account, User } from 'core';

import {
  BusinessProfile,
  ProductUpgradeBrandingResponse,
  ProfileResponse
} from './types';

export class AcpProfileClient {
  getProfile: nsWebclient.WebapiResourceInstance<void, ProfileResponse>;

  private getProductupgradeBranding: nsWebclient.WebapiResourceInstance<
    void,
    ProductUpgradeBrandingResponse
  >;

  private readonly PRODUCTUPGRADE_FEATURE: string =
    'webapi:product_upgrade_eligible';

  constructor(
    private webapiResource: nsWebclient.WebapiResourceService,
    private acpCoreDispatcher: any,
    private nsStorage: any,
    private $log: ng.ILogService,
    private $filter: ng.IFilterService,
    private $q: ng.IQService,
    private content: any,
  ) {
    'ngInject';

    this.getProfile = this.webapiResource<void, ProfileResponse>({
      method: 'GET',
      path: '/v1/me',
      clearSignals: [
        this.acpCoreDispatcher.activation.persoInfoUpdated,
        this.acpCoreDispatcher.checkDeposit.ingoTerms.accepted,
        this.acpCoreDispatcher.expense.businessOnboarding.success,
        this.acpCoreDispatcher.history.refresh.requested,
        this.acpCoreDispatcher.inbox.updateUnreadMail,
        this.acpCoreDispatcher.moveMoney.success,
        this.acpCoreDispatcher.overdraft.optOut.success,
        this.acpCoreDispatcher.payBills.exitSdk,
        this.acpCoreDispatcher.paypal.receivePayment.success,
        this.acpCoreDispatcher.points.optIn.success,
        this.acpCoreDispatcher.points.optOut.success,
        this.acpCoreDispatcher.points.redeem.success,
        this.acpCoreDispatcher.profile.user.edit.success,
        this.acpCoreDispatcher.profile.user.updated,
        this.acpCoreDispatcher.profile.username.updated,
        this.acpCoreDispatcher.savings.enroll.success,
        this.acpCoreDispatcher.savingsTransfer.success,
        this.acpCoreDispatcher.subaccount.close.success,
        this.acpCoreDispatcher.subaccount.deposit.success,
        this.acpCoreDispatcher.subaccount.withdraw.success,
        this.acpCoreDispatcher.terms.accepted.success,
        this.acpCoreDispatcher.terms.declined.success
      ],
      cache: true,
      transformData: (response: ProfileResponse) => {
        this.applyBrandingTransform(response);
        return response;
      }
    });

    this.getProductupgradeBranding = this.webapiResource<
      void,
      ProductUpgradeBrandingResponse
    >({
      method: 'GET',
      path: '/v1/branding/productupgrade',
      cache: true,
      ignoreErrors: true,
      transformErrorResponse: (errorResponse: nsWebclient.ErrorResponse) => {
        if (errorResponse.status === 403) {
          return null;
        }
        return this.$q.reject(errorResponse);
      }
    });
  }

  // TODO: We should attempt a refactor here so that the user's profile call is not coupled to the product upgrade branding call
  async get(): Promise<ProfileResponse> {
    const profile: ProfileResponse = await this.getProfile();

    if (profile && profile.program && profile.program.type) {
      this.acpCoreDispatcher.account.programType.set(profile.program.type);
    }

    const accountId = profile.accounts[0]?.id;
    if (accountId) {
      this.nsStorage.local('account_id', accountId);
    }

    await this.checkProductUpgrade(profile);
    return profile;
  }

  async accounts(): Promise<Account[]> {
    const profile: ProfileResponse = await this.get();
    return profile.accounts;
  }

  async user(): Promise<User> {
    const profile: ProfileResponse = await this.get();
    return profile.user;
  }

  async business(): Promise<BusinessProfile> {
    const profile: ProfileResponse = await this.get();
    return profile.business;
  }

  private async checkProductUpgrade(profile: ProfileResponse): Promise<void> {
    if (profile.features && profile.features[this.PRODUCTUPGRADE_FEATURE]) {
      try {
        const upgrade: ProductUpgradeBrandingResponse = await this.getProductupgradeBranding();
        if (upgrade) {
          profile.program.productUpgrade = upgrade.brand;
          this.content.setUpgradeBrand(upgrade.brand.id);
        }
      } catch (error) {
        // This will throw a failure, profile information from getProfile still needs to load and we shouldn't block that call
        this.$log.error(error);
      }
    }
  }

  private applyBrandingTransform(data: ProfileResponse): void {
    if (data.program && data.program.brand) {
      if (
        !data.program.brand.phone_formatted ||
        data.program.brand.phone_formatted === data.program.brand.phone
      ) {
        data.program.brand.phone_formatted = this.$filter<
          nsUtils.NsPhoneFormatFilter
        >('nsPhoneFormat')(data.program.brand.phone);
      }
      if (
        !data.program.brand.fax_formatted ||
        data.program.brand.fax_formatted === data.program.brand.fax
      ) {
        data.program.brand.fax_formatted = this.$filter<
          nsUtils.NsPhoneFormatFilter
        >('nsPhoneFormat')(data.program.brand.fax);
      }
    }
  }
}
