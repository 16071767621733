export class AcpDeviceDetectionService {
  constructor(private $window: ng.IWindowService) {
    'ngInject';
  }

  public getDevice(): string {
    return this.$window.navigator.userAgent;
  }

  public isMobile(): boolean {
    return (
      this.isAndroid() ||
      this.isBlackberry() ||
      this.isOperaMobile() ||
      this.isIEMobile() ||
      this.isiOS()
    );
  }

  public isiOS(): boolean {
    return this.getDevice().match(/iPhone|iPad|iPod/i) !== null;
  }

  public isiOSVersion(version: string | number): boolean {
    return (
      this.isiOS() && this.getDevice().indexOf('Version/' + version) !== -1
    );
  }

  public isAndroid(): boolean {
    return this.getDevice().match(/Android/i) !== null;
  }

  public isIE(): boolean {
    const device: string = this.getDevice();
    return device.match(/MSIE/i) !== null || device.match(/Trident/i) !== null;
  }

  private isBlackberry(): boolean {
    const device: string = this.getDevice();
    return (
      device.match(/BlackBerry/i) !== null || device.match(/BB10/i) !== null
    );
  }

  private isOperaMobile(): boolean {
    return this.getDevice().match(/Opera Mini/i) !== null;
  }

  private isIEMobile(): boolean {
    return this.getDevice().match(/IEMobile/i) !== null;
  }
}
