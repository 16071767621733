export default {
  ACP_AUTH_BLOCKS_MODEL: 'auth_blocks',
  ACP_PAYPAL_DOMAIN_SERVICE: 'paypal_auth_token',
  ACP_AUTH_MODEL_USERNAME: 'username_token',
  ACP_AUTH_MODEL_REMEMBER_USERNAME: 'remember_username_choice',
  ACP_AUTH_MODEL_BIOMETRICS_LOGIN: 'biometrics_login_choice',
  ACP_AUTH_MODEL_BIOMETRICS_ENROLLMENT: 'biometrics_enrollment',
  ACP_AUTH_MODEL_BIOMETRICS_DISABLE_AUTOLOGIN: 'biometrics_disable_autologin',
  ACP_AUTH_MODEL_POST_LOGIN_REDIRECT: 'post_login_redirect',
  ACP_AUTH_MODEL_POST_LOGIN_REDIRECT_PARAMETERS:
    'post_login_redirect_parameters',
  WEB_API_RESOURCE: 'access_token',
  ACP_HAS_PREVIOUSLY_LOGGED_IN: 'has_previously_logged_in',
  // The actual local storage key ('acp_tenant') is used on the index page in swig
  ACP_TENANT_SWITCH: 'tenant_switch',
  ACP_TENANT_SWITCH_HASH: 'tenant_switch_hash',
  ACTIVATION_CARD_ID: 'card_id',
  ACTIVATION_STATE: 'activation_state',
  ACP_AUTH_EXPIRATION_TIME: 'expiration_time',
  /**
   * setting a new key when sessionTimeout happens on angular page and
   * user redirect to mfe login page and after successful mfe login,
   * will be redirected to angular page from where sessionTimeout happend.
   */
  ACP_POST_LOGIN_REDIRECT_URL: 'post_login_redirect_url',
  /**
   * setting a new key when user logged in from login mfe only if interstials api
   * gives widgets and widgets.length > 0 then user redirect to interstitials.landing
   * and we will be setting mutex to true and removing this key from session so that
   * it can display interstitials.
   */
  ACP_LOGIN_MFE_TO_INTERSTITIALS: 'login_mfe_to_interstitials',
  ACP_PRE_ONBOARDING_HAS_LOADED: 'pre_onboarding_loaded',
  ACP_CARD_ORDER_TOAST: 'toast_message',
  ACP_PAYPAL_TRANSFER_LINKING: 'paypal_transfer_linking',
  /**
   * Setting this new in session to get its value on Secure Upload MFE.
   */
  ACP_HANDOFF_PURPOSE: 'handoff_purpose'
};
