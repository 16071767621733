export class AcpDisclosuresModel {
  private DISCLOSURE_DICTIONARY_KEY: string = 'components/disclosure-dictionary';

  constructor(private $q: ng.IQService, private contentSectionCache: any) {
    'ngInject';
  }

  public getDisclosures(): Promise<any> {
    const disclosures: any = this.contentSectionCache.get(
      this.DISCLOSURE_DICTIONARY_KEY
    ).disclosures;
    return this.$q.resolve(disclosures);
  }
}
