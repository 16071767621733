import {
  TermsCardBrandRequest,
  TermsResponse,
  TermsTypeRequest
} from './types';

export class AcpTermsClient {
  public getTermsUrl = this.webApiResource<void, TermsResponse>({
    path: '/v1/terms/account',
    method: 'GET'
  });

  public getPrivacyPolicyUrl = this.webApiResource<void, TermsResponse>({
    path: '/v1/terms/privacy_policy',
    method: 'GET'
  });

  public getPrefundedChecksTermsUrl = this.webApiResource<void, TermsResponse>({
    path: '/v1/terms/prefunded_checks_terms',
    method: 'GET'
  });

  public getPrefundedChecksEsignUrl = this.webApiResource<void, TermsResponse>({
    path: '/v1/terms/prefunded_checks_esign',
    method: 'GET'
  });

  public getPrefundedChecksPrivacyPolicyUrl = this.webApiResource<
    void,
    TermsResponse
  >({
    path: '/v1/terms/prefunded_checks_privacy_policy',
    method: 'GET'
  });

  public getBenefitCenterTermsUrl = this.webApiResource<void, TermsResponse>({
    path: '/v1/terms/benefits',
    method: 'GET'
  });

  public getSavingsTermsUrl = this.webApiResource<void, TermsResponse>({
    path: '/v1/terms/savings',
    method: 'GET'
  });

  public getEsign = this.webApiResource<void, TermsResponse>({
    path: '/v1/terms/esign',
    method: 'GET'
  });

  public getAnytimeAlertsTermsUrl = this.webApiResource<void, TermsResponse>({
    path: '/v1/terms/alerts',
    method: 'GET'
  });

  public getAnytimeAlertsTermsUrlV2 = this.webApiResource<void, TermsResponse>({
    path: '/v1/terms/alerts_v2',
    method: 'GET'
  });

  public getLoyaltyPoints = this.webApiResource<void, TermsResponse>({
    path: '/v1/terms/loyalty_points',
    method: 'GET'
  });

  public getDebitCardTransfer = this.webApiResource<void, TermsResponse>({
    path: '/v1/terms/debit_card_transfer',
    method: 'GET'
  });

  public getBankTransfer = this.webApiResource<void, TermsResponse>({
    path: '/v1/terms/ach_transfer',
    method: 'GET'
  });

  public getChoicepayDisbursement = this.webApiResource<void, TermsResponse>({
    path: '/v1/terms/choicepay_disbursement',
    method: 'GET'
  });

  public getCardBrandTermsUrl = this.webApiResource<
    TermsCardBrandRequest,
    TermsResponse
  >({
    path: '/v1/terms/card_brand',
    method: 'GET'
  });

  public getUpgradeTermsUrl = this.webApiResource<
    TermsTypeRequest,
    TermsResponse
  >({
    path: '/v1/productupgrade/terms/:type',
    method: 'GET'
  });

  public getBiometricsEnrollmentTermsUrl: nsWebclient.WebapiResourceInstance<
    TermsTypeRequest,
    TermsResponse
  > = this.webApiResource<TermsTypeRequest, TermsResponse>({
    path: '/v1/terms/biometrics_login',
    method: 'GET'
  });

  public getAutoDirectDepositTermsUrl: nsWebclient.WebapiResourceInstance<
    TermsTypeRequest,
    TermsResponse
  > = this.webApiResource<TermsTypeRequest, TermsResponse>({
    path: '/v1/terms/dd_enrollment_terms',
    method: 'GET'
  });

  public getAutoDirectDepositPrivacyPolicy: nsWebclient.WebapiResourceInstance<
    void,
    TermsResponse
  > = this.webApiResource<void, TermsResponse>({
    path: '/v1/terms/dd_enrollment_privacy',
    method: 'GET'
  });

  public acceptTerms = this.webApiResource<TermsTypeRequest, TermsResponse>({
    path: '/v1/terms/:type',
    method: 'POST'
  });

  public declineTerms = this.webApiResource<TermsTypeRequest, TermsResponse>({
    path: '/v1/terms/:type',
    method: 'DELETE'
  });

  public getFeeScheduleTermsUrl = this.webApiResource<void, TermsResponse>({
    path: '/v1/terms/fee_schedule',
    method: 'GET'
  });

  public getEulaTermsUrl = this.webApiResource<void, TermsResponse>({
    path: '/v1/terms/eula',
    method: 'GET'
  });

  constructor(private webApiResource: nsWebclient.WebapiResourceService) {
    'ngInject';
  }
}
