export class AcpUpgradeOrderCardClient {
  getCardBrands = this.webapiResource<any, any[]>({
    path: '/v1/cards/brands/productupgrade',
    method: 'GET',
    transformData: (response: any) => {
      if (this.nsUtil.isDefined(response.brands)) {
        return response.brands;
      } else {
        return response;
      }
    },
    cache: true
  });

  constructor(
    private nsUtil: nsUtils.NsUtilService,
    private webapiResource: nsWebclient.WebapiResourceService
  ) {
    'ngInject';
  }
}
