// @ngInject
function acpStateService($state, $location) {
  var def = {};

  // Need to use $location.url() here because during app initialization
  // $state.current returns a dummy route
  def.isOnUnauthedState = function() {
    var unauthedStateUrls = $state
      .get()
      .filter(function(state) {
        if (
          state.data &&
          state.data.permissions &&
          state.data.permissions.only
        ) {
          return (
            state.data.permissions.only.indexOf('password_unauthed') !== -1
          );
        }
      })
      .map(function(state) {
        return state.url;
      });

    return unauthedStateUrls.indexOf($location.path()) !== -1;
  };

  return def;
}

export default acpStateService;
