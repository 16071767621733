/* globals __acpBuildGlobal__, window */

// @ngInject
function exceptionHandler($log) {
  function handler(exception, cause) {
    // Log error to console.
    $log.error.apply($log, arguments);

    // Throw exceptions in unit tests
    if (__acpBuildGlobal__.isTests) {
      if (exception) {
        // not using $window, to avoid errors from unit tests mocking $window without console.log
        window.console.log(exception.stack);
        window.console.error(exception);
      }
      throw exception;
    }

    handler.__listeners.forEach(function(listener) {
      listener(exception, cause);
    });
  }

  handler.__listeners = [];
  handler.onException = function(listener) {
    handler.__listeners.push(listener);
  };

  return handler;
}

export default exceptionHandler;
