import acpOpenAppstoreAction from './actions/acp-open-appstore-action';
import acpRouteAction from './actions/acp-route-action';
import acpGenericActionService from './acp-generic-action-service';
import genericActionDef from './actions/generic-action-def';
import acpGenericActionTypes from './acp-generic-action-types';
/**
 * Generic Action Functionality
 *
 * This module houses shared code that runs
 * and defines generic actions.
 *
 * I did not put it into core because that module
 * is bloated.
 */
//@ngInject
function setup(acpGenericActionService, acpRouteAction, acpOpenAppStoreAction) {
  acpGenericActionService.registerAction(acpRouteAction);
  acpGenericActionService.registerAction(acpOpenAppStoreAction);
}

export default function(core) {
  core.constant('ACP_GENERIC_ACTION_TYPES', acpGenericActionTypes);
  core.factory('GenericActionDef', genericActionDef);
  core.factory('acpGenericActionService', acpGenericActionService);
  core.factory('acpRouteAction', acpRouteAction);
  core.factory('acpOpenAppStoreAction', acpOpenAppstoreAction);
  core.run(setup);
}
