// @ngInject

// I manage the transition between dialogs and bottom sheets depending on
// screen size
function acpDialogBottomSheet(
  acpCoreDispatcher,
  acpMedia,
  nsUtil,
  $q,
  $ocLazyLoad,
  $mdDialog,
  $mdBottomSheet,
  $rootScope
) {
  var def = {},
    currentDialogBottomSheet,
    removeAcpMediaListener,
    blockMediaListener = false;

  function dialogBottomSheetManager(isMobile) {
    /*
      Unless I block this callback from executing the first time, it will fire
      immediately after I attach the listener, causing the element in question
      to close immediately and then re-open
    */
    if (blockMediaListener) {
      blockMediaListener = false;
      return;
    }

    if (isMobile) {
      $mdDialog.hide();
      showBottomSheet();
    } else {
      $mdBottomSheet.hide();
      showDialog();
    }
  }

  function attachListener() {
    // See above note in dialogBottomSheetManager
    blockMediaListener = true;
    removeAcpMediaListener = $rootScope.$watch(function() {
      return acpMedia('mobile');
    }, dialogBottomSheetManager);
  }

  function showDialog() {
    attachListener();

    $q
      .when(currentDialogBottomSheet.preload)
      .then($ocLazyLoad.loadModule)
      .then(function() {
        var dialogObj = nsUtil.assign(
          {
            name: currentDialogBottomSheet.name,
            preload: currentDialogBottomSheet.preload
          },
          currentDialogBottomSheet.dialog
        );

        $mdDialog
          .show(dialogObj)
          .then(removeAcpMediaListener, removeAcpMediaListener);
      });
  }

  function showBottomSheet() {
    attachListener();

    $q
      .when(currentDialogBottomSheet.preload)
      .then($ocLazyLoad.loadModule)
      .then(function() {
        var bottomSheetObj = nsUtil.assign(
          {
            name: currentDialogBottomSheet.name,
            preload: currentDialogBottomSheet.preload
          },
          currentDialogBottomSheet.bottomSheet
        );

        $mdBottomSheet
          .show(bottomSheetObj)
          .then(removeAcpMediaListener, removeAcpMediaListener);
      });
  }

  /*
    Takes an object of the format:
    {
      preload: require('acp.component.some-component'),
      name: someName,
      dialog: {
        controller: 'acpSomeDialogCtrl',
        template: require('../some-component/templates/some-dialog-template.html'),
        targetEvent: $someEvent,
        ...
      },
      bottomSheet: {
        controller: 'acpSomeBottomSheetCtrl',
        template: require('../some-component/templates/some-dialog-template.html'),
        ...
      }
    }
  */
  def.summon = function(dialogBottomSheetObj) {
    currentDialogBottomSheet = dialogBottomSheetObj;

    if (acpMedia('mobile')) {
      showBottomSheet();
    } else {
      showDialog();
    }
  };

  def.dismiss = function() {
    currentDialogBottomSheet = null;

    $mdDialog.hide();
    $mdBottomSheet.hide();
  };

  return def;
}

export default acpDialogBottomSheet;
