export class AcpInPageFlowService {
  private states: string[] = [];
  private promise: any;

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $timeout: ng.ITimeoutService,
    private NS_ROUTER_EVENTS: any,
    private nsInPageFlow: any
  ) {
    'ngInject';
  }

  public listen(): void {
    this.$rootScope.$on(
      this.NS_ROUTER_EVENTS.ROUTE_CHANGE_START,
      this.onRouteChange.bind(this) // Need to bind, otherwise Angular invokes this with a null context
    );
  }

  private onRouteChange(event: any, toState: any, toParams: any): void {
    if (this.promise || this.states.indexOf(toState.name) > -1) {
      this.$timeout(() => {
        this.states = [];
        this.promise = null;
      }, 1000);
      return;
    }

    // This event is being fired up to 3 times at once, so need to return if it's duplicated
    this.states.push(toState.name);

    if (this.nsInPageFlow.isOpen() && !toParams.keepInPageFlowOpen) {
      // This prevent the "inPageFlow is already closing" issue
      this.promise = this.nsInPageFlow.close();
    }
  }
}
