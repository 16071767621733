import ng from 'angular';
import { IStateService } from 'angular-ui-router';
import { AppsflyerPlugin } from '../../plugins/appsflyer';
import { DeeplinkData } from '../../plugins/ionic-plugin-deeplinks';
import { InitRouteHandler } from './init-router-handler';

export class DeeplinkRouteHandler implements InitRouteHandler {
  readonly name: string = 'DeeplinkRouteHandler';

  constructor(
    private $log: ng.ILogService,
    private $state: IStateService,
    private $window: ng.IWindowService,
    private appsflyer: AppsflyerPlugin
  ) {}

  async routeIfNecessary(): Promise<boolean> {
    const deepLinkData: DeeplinkData = this.$window.deeplinkData;
    if (deepLinkData == null) {
      this.$log.error('No Deeplink from AppsFlyer');
      return false;
    }

    try {
      this.$log.log(`Sending Link ${deepLinkData.url} to AppsFlyer`);
      const state: string = await this.appsflyer.getDeeplinkIfExists();
      if (state == null) {
        this.$log.warn(
          `Appsflyer Link not setup correctly: ${deepLinkData.url}`
        );
        return false;
      }

      if (!this.$state.get(state)) {
        this.$log.warn(`Resolved deeplink state does not exist: ${state}`);
        return false;
      }

      this.$log.log(`Routing to resolved deeplink state: ${state}`);
      this.$state.go(state);
      return true;
    } catch (e) {
      this.$log.error('Unable to handle deeplink', e);
      return false;
    }
  }
}
