export default {
  GPR: 'gpr',
  GPR_SUB: 'gpr_sub',
  DDA: 'dda', // Both program types BAW_DDA and DDA can only get "dda" account types.
  DEBIT: 'debit',
  LOYALTY: 'loyalty', // TODO: This should be "loyalty_points", not "loyalty". See account-switcher component and change hard-coded type.
  SAVINGS: 'savings',
  CLOSED_DEBIT: 'closedDebit',
  CLOSED_SAVINGS: 'closedSavings'
};
