/**
 * Given a async func and a time, throttle the function calls and return a promise
 * that resolves with the functions resolve. Won't be called more than every
 * `throttleTime` ms.
 */
export const throttleAsync = <T>(
  cb: () => Promise<T>,
  throttleTime: number
) => {
  let promise: Promise<T>;
  const start = async () => {
    await new Promise((resolve) => setTimeout(resolve, throttleTime));
    promise = null;
    return await cb();
  };

  return () => (promise = promise || start());
};
