import ng from 'angular';
import { IStateService } from 'angular-ui-router';
import { AppsflyerPlugin } from '../../plugins/appsflyer';
import { InitRouteHandler } from './init-router-handler';

export class DeferredDeeplinkRouteHandler implements InitRouteHandler {
  public readonly name: string = 'DeferredDeeplinkRouteHandler';

  constructor(
    private $log: ng.ILogService,
    private $state: IStateService,
    private appsflyer: AppsflyerPlugin
  ) {}
  public async routeIfNecessary(): Promise<boolean> {
    try {
      const state: string = await this.appsflyer.getDeferredDeeplinkIfExists();
      if (state == null) {
        this.$log.debug('No deferred deeplink...');
        return false;
      }

      if (!this.$state.get(state)) {
        this.$log.warn(`Resolved deeplink state does not exist: ${state}`);
        return false;
      }

      this.$log.log(`Routing to resovled deeplink state: ${state}`);
      this.$state.go(state);
      return true;
    } catch (e) {
      this.$log.error('Unable to process deferred deeplink', e);
      return false;
    }
  }
}
