//@ngInject
function acpOpenAppStoreAction(
  GenericActionDef,
  acpBrandingModel,
  acpInAppBrowserModel,
  acpDeviceService,
  ACP_GENERIC_ACTION_TYPES
) {
  function launchAppStore() {
    var branding = acpBrandingModel.getBranding();

    if (acpDeviceService.isAndroid() && branding.android_app_url) {
      acpInAppBrowserModel.create(branding.android_app_url);
    } else if (acpDeviceService.isIos() && branding.ios_app_url) {
      acpInAppBrowserModel.create(branding.ios_app_url);
    }
  }

  return new GenericActionDef(
    ACP_GENERIC_ACTION_TYPES.OPEN_APP_STORE,
    launchAppStore
  );
}

export default acpOpenAppStoreAction;
