// This file override the PermStatePermissionMap service from
// angular-permission.ui completely! It is simpler than forking the project.
// The below contents are taken from the following file at its commit
// https://github.com/Narzerus/angular-permission/blob/cb2ad852e7ef36c660e01ee1fdedf58e67e141f8/src/permission-ui/authorization/StatePermissionMap.js
//
// The changes are:
//  - If a parent has a redirectTo but a child does not, use the parents redirectTo instead of none
//  - Only evaluate the state tree that is changing. i.e. if a parent has two children, going from
//    child1 to child2 should *not* re-evaulate the parent permissions. To bypass this optimization
//    use `$state.go(to, params, {reload: true})`
//
// @ngInject
function acpPermStatePermissionMapDecorator(
  PermPermissionMap,
  PermTransitionProperties,
  $injector
) {
  StatePermissionMap.prototype = new PermPermissionMap();

  /**
   * Constructs map instructing authorization service how to handle authorizing
   * @constructor permission.ui.StatePermissionMap
   * @extends permission.PermPermissionMap
   */
  function StatePermissionMap(state) {
    var toStatePath = getPathChanges(state);

    angular.forEach(
      toStatePath,
      function(state) {
        if (areSetStatePermissions(state)) {
          var permissionMap = new PermPermissionMap(state.data.permissions);
          this.extendPermissionMap(permissionMap);
        }
      },
      this
    );
  }

  /**
   * Extends permission map by pushing to it state's permissions
   * @methodOf permission.ui.StatePermissionMap
   *
   * @param permissionMap {permission.PermPermissionMap} Compensated permission map
   */
  StatePermissionMap.prototype.extendPermissionMap = function(permissionMap) {
    if (permissionMap.only.length) {
      this.only = this.only.concat([permissionMap.only]);
    }
    if (permissionMap.except.length) {
      this.except = this.except.concat([permissionMap.except]);
    }

    if (angular.isDefined(permissionMap.redirectTo)) {
      this.redirectTo = angular.extend(
        {},
        this.redirectTo,
        permissionMap.redirectTo
      );
    }
  };

  /**
   * Checks if state has set permissions
   * We check for hasOwnProperty, because ui-router lets the `data` property inherit from its parent
   * @methodOf permission.ui.StatePermissionMap
   * @private
   *
   * @returns {boolean}
   */
  function areSetStatePermissions(state) {
    try {
      return Object.prototype.hasOwnProperty.call(state.data, 'permissions');
    } catch (e) {
      return false;
    }
  }

  function getPathChanges(state) {
    var toStatePath = state.$$permissionState().path;

    // Only find changing parents if `reload: true` was not specified.
    if (
      !(
        PermTransitionProperties.options &&
        PermTransitionProperties.options.reload
      )
    ) {
      var fromStatePath = $injector.get('$state').$current.path;

      toStatePath = toStatePath.filter(function(toStatePart) {
        return fromStatePath.indexOf(toStatePart) === -1;
      });
    }

    return toStatePath;
  }

  return StatePermissionMap;
}

export default acpPermStatePermissionMapDecorator;
