import ng from 'angular';
import { AcpFeatureClient, AcpProfileClient, ProfileResponse } from '..';
import { FeatureResponse, FeatureSet } from './acp-feature-client';
import { throttleAsync } from './throttle-async';

// Call on a feature name to get proper format as a string
const transformFeature = (feature: string) => (feature || '').toLowerCase();
// Simple shallow object copy
const copyFeatureSet = (featureSet: FeatureSet): FeatureSet =>
  Object.keys(featureSet).reduce((acc, feature) => {
    acc[feature] = featureSet[feature];
    return acc;
  }, {});

export class AcpFeatureModel {
  public static readonly BATCH_THROTTLE_TIME = 50;

  private batchFeatureCache = {};
  private batchFeaturesToRequest: string[] = [];
  // A throttled function to fetch only 50ms
  private getBatchFeaturesPromise = throttleAsync(
    () => this.requestBatchFeatures(),
    AcpFeatureModel.BATCH_THROTTLE_TIME
  );

  constructor(
    private acpFeatureClient: AcpFeatureClient,
    private acpProfileClient: AcpProfileClient,
    private acpAuthModel: any
  ) {
    'ngInject';

    this.acpFeatureClient.featureClearSignals.forEach((signal) =>
      signal.onValue(() => (this.batchFeatureCache = {}))
    );
  }

  /**
   * Get one or more features by name. Performant feature fetching that batches requests for saving HTTP round trips
   * @param {...string} features
   * @returns {Promise<FeatureSet>}
   */
  public async getFeatures(...features: string[]): Promise<FeatureSet> {
    // Format and filter out the ones we have in the cache
    const featuresToFetch = features
      .map(transformFeature)
      .filter((feature) => !this.batchFeatureCache.hasOwnProperty(feature));

    // If we don't have any that we need to fetch, just return our current cache
    if (!featuresToFetch.length) {
      return copyFeatureSet(this.batchFeatureCache);
    }

    // If any of the features reqeusted were already going to be requested from another source, filter them out to prevent duplicates
    this.batchFeaturesToRequest = featuresToFetch
      .filter((feature) => this.batchFeaturesToRequest.indexOf(feature) === -1)
      .concat(this.batchFeaturesToRequest);

    // Return a promise to get the newly requested features and the cache
    return this.getBatchFeaturesPromise();
  }

  /**
   * Same as `getFeature`, but return the boolean value directly for a single feature. Convenience method
   * @param {string} feature
   * @returns {Promise<boolean>}
   */
  public async hasFeature(feature: string): Promise<boolean> {
    const features = await this.getFeatures(feature);
    return features[transformFeature(feature)];
  }

  /**
   * Legacy. Get a single gateway feature by name from V1 system. Useful when needing
   * features API to return 1 or more features when requesting only a single feature.
   * Example, Feature Gateway, as in Paywalls
   * @param {string} feature Feature name
   * @returns {Promise<FeatureResponse>}
   */
  public async getGatewayFeature(feature: string): Promise<FeatureResponse> {
    feature = transformFeature(feature);
    const authInfo: any = this.acpAuthModel.info.getValue();

    // AIR-668: Can now use /feature endpoint without auth
    if (authInfo && authInfo.password) {
      const profile: ProfileResponse = await this.acpProfileClient.get();
      if (profile.features && profile.features.hasOwnProperty(feature)) {
        return {
          features: {
            [feature]: profile.features[feature]
          }
        };
      }
    }

    return this.acpFeatureClient.getFeature({ feature });
  }

  // Do the actual batch feature request
  private async requestBatchFeatures(): Promise<FeatureSet> {
    // Pick off the member props now, in case they are reset in flight
    const { batchFeaturesToRequest, batchFeatureCache } = this;
    // Reset the to-request features to so more can be queued
    this.batchFeaturesToRequest = [];
    const { features } = await this.acpFeatureClient.getFeatures({
      features: batchFeaturesToRequest
    });
    return copyFeatureSet(ng.extend(batchFeatureCache, features));
  }
}
