//@ngInject
function acpBlockSensitiveDirective() {
  function compile(el) {
    el.attr('data-analytics-block-sensitive', '');
  }

  return {
    restrict: 'A',
    // link: link,
    compile: compile
  };
}

export default acpBlockSensitiveDirective;
