export default {
  /**
   * This action handles routing to a given url/route
   * when called.
   */
  ROUTE_ACTION: 'route.action',
  /**
   * This action handles starting a guided tour with
   * the given tour name when called.
   */
  START_TOUR_ACTION: 'start-tour.action',
  /**
   * This action handles manipulating the side-nav menu
   * GPR only....
   */
  SIDE_NAV_MENU_ACTION: 'side-nav-menu.action',
  /**
   * This action handles opening the app store to upgrade
   * to the latest version
   */
  OPEN_APP_STORE: 'open-appstore.action',
  /**
   * This action handles opening the onboarding expanded
   * view to see your tasks
   */
  OPEN_ONBOARDING: 'open-onboarding.action'
};
